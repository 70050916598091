import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
    const isLogged = localStorage.getItem('loginToken') ? true : false

    return (
        isLogged ?
            <React.Suspense fallback={''}>
                <Outlet />
            </React.Suspense>
            : <Navigate to='/login' />
    )
}


export default PrivateRoute;