import React, { useState } from "react";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";




export default function DateRangePickerWrapper({ onSelect, valueDate }) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className="">
            <div onClick={() => setIsOpen(!isOpen)}>
                Date Range Picker
                <div style={{ marginTop: 5, border: '1px solid #ddd', padding: 10, width: '40%' }}>

                    {valueDate.start?.format("DD-MM-YYYY")}
                    {" - "}
                    {valueDate.end?.format("DD-MM-YYYY")}
                </div>
            </div>
            {
                isOpen ?
                    <div className="daterange" >
                        <DateRangePicker
                            value={valueDate}
                            onSelect={onSelect}
                            singleDateRange={true}
                        />
                    </div>
                    : null
            }

        </div>
    )
}
