import { GET_NOTIFICATIONS, GET_NEXT_NOTIFICATIONS, NOTIFICATION_MARKED_VIEWED } from "../Types/types";

let intialState = {
    isLoading: true,
    notifications: []
}

export default function notificationReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_NOTIFICATIONS:
            if (action.payload.length > 0) {
                stateCopy.notifications = action.payload;
                stateCopy.isLoading = false;
            }
            else {
                stateCopy.isLoading = false;
            }
            return stateCopy;
        case GET_NEXT_NOTIFICATIONS:
            if (action.payload.length > 0) {
                stateCopy.notifications = stateCopy.notifications.concat(action.payload);
                stateCopy.isLoading = false;
            }
            else {
                stateCopy.isLoading = false;
            }
            return stateCopy;
        case NOTIFICATION_MARKED_VIEWED:
            window.location.href = action.payload;
            return stateCopy;
        default:
            return stateCopy;

    }
}