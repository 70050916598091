/* eslint-disable no-unused-vars */
import { getApiCall, postApiCall } from '../../utils/request';
import { GET_NOTES_LIST, GET_fILTERS, SUBJECT_FILTER_CLICK, DATE_FILTER_CLICK, CLEAR_FILTER, GET_NOTES, SET_NOTE, SAVE_NOTES, GET_LIKED } from '../Types/types';
import { returnErrors } from './errorAction';

export const getNotesList = (page, query, subjectFilter, from, to) => async (dispatch) => {
  try {
    const response = await getApiCall(`student/notes/list?page=${page}&query=${query}&subject=${encodeURIComponent(JSON.stringify(subjectFilter))}&from=${from}&to=${to}`);
    dispatch({ type: GET_NOTES_LIST, payload: response.data });

  } catch (error) {
    returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
  }
}

export const getFilters = () => async (dispatch) => {

  try {
    const response = await getApiCall(`student/notes/list/filter`);
    dispatch({ type: GET_fILTERS, payload: response.data });

  } catch (error) {
    returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
  }
}



export const onSubjectFilterClick = (id, value) => async (dispatch) => {
  return dispatch({
    type: SUBJECT_FILTER_CLICK,
    payload: { id, value }
  });
}

export const onDateFilterClick = (code, value) => async (dispatch) => {
  return dispatch({
    type: DATE_FILTER_CLICK,
    payload: { code, value }
  });
}


export function clearFilter() {
  return {
    type: CLEAR_FILTER
  };
}


export const getNotes = (el) => async (dispatch) => {
  try {
    const response = await getApiCall(`student/learning/resource?resource_id=${el._id}&type=${el.type}`);
    const res = response.data.data
    const obj = { ...el, ...res }
    dispatch({ type: GET_NOTES, payload: obj });
  } catch (error) {
    returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
  }
}

export function setNote(val) {
  return {
    type: SET_NOTE,
    payload: val
  };
}

export const savedNote = (save) => async (dispatch) => {
  try {
    await postApiCall('student/learning/resource/note', save);
    dispatch({ type: SAVE_NOTES, payload: save });
  } catch (error) {
    returnErrors(dispatch, error.response.data.message, error.response.status);
  }
}

export function closePopup() {
  return {
    type: "Reset_Popup"
  };
}




export const onLikeClick = (id, type, liked) => async (dispatch) => {
  let likedObj = {
    "resource_id": id,
    "type": type,
    "like": !liked
  }
  try {
    const response = await postApiCall('student/learning/resource/like', likedObj);
    dispatch({ type: GET_LIKED, payload: { id, type, liked } });
  } catch (error) {
    returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
  }
}


