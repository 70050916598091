/* eslint-disable no-unused-vars */
import { getApiCall, postApiCall } from '../../utils/request';
import { returnErrors } from './errorAction';


export const getRewardPoints = () => async (dispatch) => {
    try {
        const response = await getApiCall(`student/rewards/user/score`);
        console.log(response.data)
        dispatch({ type: "GET_REWARDS_POINTS", payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getRewardList = () => async (dispatch) => {
    try {
        const response = await getApiCall(`student/rewards/list`);
        dispatch({ type: "GET_REWARDS", payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const openRedeemModal = (details) => async (dispatch) => {
    return dispatch({
        type: 'OPEN_REDEEM_MODAL', payload: details
    });
};

export const closeRedeemModal = () => async (dispatch) => {
    return dispatch({
        type: 'CLOSE_REDEEM_MODAL',
    });
};

export const checkRedeem = (reward_id, contest_id) => async (dispatch) => {
    const obj = {
        reward_id, contest_id
    }
    try {
        const response = await postApiCall(`student/rewards/check`, obj);
        console.log(response.data)
        if (response.data.success) {
            // returnErrors(dispatch, response?.data?.message, response?.status);
            return dispatch({ type: 'CHECK_REDEEM', payload: true })
        } else {
            // returnErrors(dispatch, response?.data?.message, response?.status);
            return dispatch({ type: 'CHECK_REDEEM', payload: false })
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const redeemCoupon = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`student/rewards/create/redeem`, obj);
        console.log(response.data)
        if (response.data.success) {
            dispatch({ type: 'COUPON_REDEEMED', payload: response.data });
        }
        else {
            returnErrors(dispatch, response?.data?.message, response.status)
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const closeGreetModal = () => async (dispatch) => {
    return dispatch({
        type: 'CLOSE_GREET_MODAL',
    });
};

export const getRewardHistory = () => async (dispatch) => {
    try {
        const response = await getApiCall(`student/rewards/redeem/history`);
        dispatch({ type: "REWARD_HISTORY", payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}
