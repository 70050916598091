import { replaceObjectInArray } from "../../utils/miscelleneous";
import {
    RESET_TABS, GET_ACTIVE_BTN, GET_AFFLIATIONS, HIDE_SIDEBAR, SHOW_SIDEBAR, OPEN_ADD_CLASSROOM, BACK_TO_CLASSROOM, RESET_PROGRAM_LOADING,
    GET_COURSE_LIST, ADD_COURSE, UPDATE_COURSE, UPDATE_COURSE_STATUS, DELETE_COURSE,
    OPEN_ADD_TO_COURSE, CLOSE_ADD_TO_COURSE, GET_SUBJECT_FROM_COURSE, GET_CHECKED, SELECT_ONE, ADD_SUB_TO_COURSE,
    OPEN_ADD_SUBJECT, CLOSE_ADD_SUBJECT, GET_SUBJECT_LIST, GET_NEXT_SUBJECT_LIST, ADD_SUBJECT, UPDATE_SUBJECT, UPDATE_SUBJECT_STATUS, DELETE_SUBJECT, GET_ALL_SUBJECT, REORDER_CHAPTER,
    GET_CHAPTERS_LIST, ADD_CHAPTER, UPDATE_CHAPTER, UPDATE_CHAPTER_STATUS, DELETE_CHAPTER, OPEN_ADD_INFO, BACK_TO_CHAPTER, ADD_SUBJECT_BRIEF,
    GET_TOPICS_LIST, ADD_TOPIC, UPDATE_TOPIC, UPDATE_TOPIC_STATUS, DELETE_TOPIC, REORDER_TOPIC,
    GET_PRACTICE_TESTS, SEARCH_TEST, ADD_PRACTICE_TEST, UPDATE_TEST_STATUS,
    ADD_PARAMS, GET_TOPICS_SUBTOPICS, RESET_RESOURCE_LOADING, GET_RESOURCE_LIST, UPDATE_RESOURCE_STATUS, REORDER_TOPIC_RESOURCES, ADD_RESOURCE, UPDATE_RESOURCE, GET_DOCUMENT_STATE, GET_DOCUMENT_PERCENT,
    GET_PREVIEW, NULL_PREVIEW, GET_QUIZ_QUESTION,
    GET_CLUBBED_QUESTION_LIST, GET_QUESTION_FROM_SUBJECT, UPDATE_QUESTION_STATUS, GET_FILTER_SUBJECT, GET_FILTER_QUESTION, GET_QUESTION_DETAILS, EDIT_QUESTION,
    SET_QUES_TITLE, SET_CHOICE1, SET_CHOICE2, SET_CHOICE3, SET_CHOICE4, SET_EXPLANTION, GET_SUBJECT_CHAPTER, ADD_QUESTION,
    ADD_QUESTION_IN_QUIZ, SELECT_OR_DELETE_QUESTION_QUIZ, ADD_NEW_QUIZ, Delete_All_Question_Quiz, RESET_PERCENTAGE,
} from "../Types/types";


let intialState = {
    activeButton: 'course',
    isClassLoading: true,
    isSubjectLoading: true,
    isQuestionLoading: true,
    isChapterLoading: true,
    isTopicLoading: false,
    isResourceLoading: true,
    courseList: [],
    subjectList: [],
    subjectFromClassroom: [],
    chapterList: [],
    topicsList: [],
    isShow: false,
    isAddSubject: false,
    addSubject: false,
    programId: "",
    isHide: false,
    isAddInfo: false,
    questionList: [],
    practiceList: [],
    clubQuestionList: [],
    isViewed: false,

    edit: false,
    subject_id: "",
    failed: false,
    isSchedule: false,
    allQuizList: [],
    questionListForQuiz: [],
    previewData: "",
    documentDataState: false,
    documentData: "",
    resourceList: [],
    percent: 0,
    qTitle: '',
    qExplanation: '',
    choice1: '',
    choice2: '',
    choice3: '',
    choice4: '',
    resourceLoading: true,
    topic_id: '',
    subtopic: '',
    topic: ''
}


export default function manageContentReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case RESET_TABS:
            stateCopy.isShow = false;
            return stateCopy;
        case GET_ACTIVE_BTN:
            stateCopy.activeButton = action.payload;
            return stateCopy;
        case GET_AFFLIATIONS:
            stateCopy.affliations = action.payload;
            return stateCopy;
        case HIDE_SIDEBAR:
            stateCopy.isHide = true;
            return stateCopy;
        case SHOW_SIDEBAR:
            stateCopy.isHide = false;
            return stateCopy;
        case OPEN_ADD_CLASSROOM:
            stateCopy.isShow = true;
            stateCopy.addSubject = false;
            return stateCopy;
        case BACK_TO_CLASSROOM:
            stateCopy.isShow = false;
            stateCopy.addSubject = false;
            return stateCopy;
        case RESET_PROGRAM_LOADING:
            stateCopy.isClassLoading = true;
            stateCopy.isTopicLoading = true;
            stateCopy.isViewed = false;
            return stateCopy;
        //Course    
        case GET_COURSE_LIST:
            stateCopy.courseList = action.payload;
            stateCopy.subjectFromProgram = [];
            stateCopy.isClassLoading = false;
            stateCopy.isSubjectLoading = true;
            return stateCopy;
        case ADD_COURSE:
            stateCopy.courseList = [...stateCopy.courseList, action.payload];
            stateCopy.isShow = false;
            return stateCopy;
        case UPDATE_COURSE:
            stateCopy.courseList = replaceObjectInArray(stateCopy.courseList, action.payload._id, action.payload);
            stateCopy.isShow = false;
            return stateCopy;
        case UPDATE_COURSE_STATUS:
            stateCopy.courseList.forEach(el => {
                if (el._id === action.payload.course_id) {
                    el.active = action.payload.active
                }
            })
            return stateCopy;
        case DELETE_COURSE:
            stateCopy.courseList = stateCopy.courseList.filter(el => el._id !== action.payload);
            return stateCopy;
        case OPEN_ADD_TO_COURSE:
            stateCopy.addSubject = true;
            return stateCopy;
        case CLOSE_ADD_TO_COURSE:
            stateCopy.addSubject = false;
            return stateCopy;
        //subject 
        case GET_SUBJECT_FROM_COURSE:
            stateCopy.subjectFromClassroom = action.payload.data.length > 0 ? action.payload.data : [];
            stateCopy.isSubjectLoading = false;
            stateCopy.classroomId = action.payload.id;
            stateCopy.isTopicLoading = true;
            return stateCopy;
        case SELECT_ONE:
            if (action.payload.checked) {
                stateCopy.subjectList.forEach(el => {
                    if (el._id === action.payload.id) {
                        el.isChecked = true
                    }
                })
            }
            else {
                stateCopy.subjectList.forEach(el => {
                    if (el._id === action.payload.id) {
                        el.isChecked = false
                    }
                })
            }
            return stateCopy;
        case GET_CHECKED:
            if (action.payload !== null) {
                stateCopy.subjectList.forEach(item => {
                    const foundIndex = action.payload.find(i => i._id === item._id);
                    if (foundIndex) {
                        item.isChecked = true;
                    } else {
                        item.isChecked = false;
                    }
                })
            } else {
                stateCopy.subjectList = stateCopy.subjectListCopy;
            }
            return stateCopy;
        case ADD_SUB_TO_COURSE:
            stateCopy.addSubject = false;
            return stateCopy;
        case OPEN_ADD_SUBJECT:
            if (action.payload.sub !== null) {
                stateCopy.editObj = action.payload.sub;
                stateCopy.editSubject = action.payload.val;
            } else {
                stateCopy.editObj = null;
                stateCopy.editSubject = action.payload.val;
            }
            stateCopy.isAddSubject = true;
            return stateCopy;
        case CLOSE_ADD_SUBJECT:
            stateCopy.isAddSubject = false;
            stateCopy.editObj = null;
            stateCopy.edit = false;
            return stateCopy;
        case GET_SUBJECT_LIST:
            action.payload.forEach(item => {
                const foundIndex = stateCopy.subjectFromClassroom.find(i => i._id === item._id);
                if (foundIndex) {
                    item.isChecked = true;
                } else {
                    item.isChecked = false;
                }
            })
            stateCopy.subjectList = action.payload;
            stateCopy.subjectListCopy = action.payload;
            stateCopy.isClassLoading = false;
            return stateCopy;
        case GET_NEXT_SUBJECT_LIST:
            stateCopy.subjectList = stateCopy.subjectList.concat(action.payload.map(item => ({ ...item, isChecked: false })));
            stateCopy.subjectListCopy = stateCopy.subjectListCopy.concat(action.payload.map(item => ({ ...item, isChecked: false })));
            // stateCopy.selectFromSubject = stateCopy.selectFromSubject.concat(action.payload.filter(item => item.active).map(item => [{ label: item.name, value: item._id }][0]));
            stateCopy.isClassLoading = false;
            return stateCopy;
        case ADD_SUBJECT:
            stateCopy.subjectList = [...stateCopy.subjectList, action.payload];
            stateCopy.isAddSubject = false;
            return stateCopy;
        case UPDATE_SUBJECT:
            stateCopy.subjectList = replaceObjectInArray(stateCopy.subjectList, action.payload._id, action.payload);
            stateCopy.subjectFromClassroom = replaceObjectInArray(stateCopy.subjectFromClassroom, action.payload._id, action.payload);
            stateCopy.isAddSubject = false;
            return stateCopy;
        case UPDATE_SUBJECT_STATUS:
            stateCopy.subjectList.forEach(el => {
                if (el._id === action.payload.subject_id) {
                    el.active = action.payload.active
                }
            })
            stateCopy.subjectFromClassroom.forEach(el => {
                if (el._id === action.payload.subject_id) {
                    el.active = action.payload.active
                }
            })
            return stateCopy;
        case DELETE_SUBJECT:
            stateCopy.subjectList = stateCopy.subjectList.filter(el => el._id !== action.payload);
            return stateCopy;
        case GET_ALL_SUBJECT:
            stateCopy.selectFromSubject = action.payload.map(item => [{ ...item, label: item.name, value: item._id }][0]);
            return stateCopy;
        //chapters
        case GET_CHAPTERS_LIST:
            stateCopy.chapterList = action.payload.data.sort((a, b) => a.index - b.index);
            stateCopy.subject_id = action.payload.id;
            stateCopy.isChapterLoading = false;
            stateCopy.isTopicLoading = true;
            return stateCopy;
        case ADD_CHAPTER:
            stateCopy.chapterList = [...stateCopy.chapterList, action.payload.data];
            stateCopy.subjectList.forEach((product) => {
                if (product._id === action.payload.id) {
                    product.topics = product.topics + 1;
                }
            });
            return stateCopy;
        case UPDATE_CHAPTER:
            stateCopy.chapterList = replaceObjectInArray(stateCopy.chapterList, action.payload._id, action.payload);
            return stateCopy;
        case UPDATE_CHAPTER_STATUS:
            stateCopy.chapterList.forEach(el => {
                if (el._id === action.payload.chapter_id) {
                    el.active = action.payload.active
                }
            })
            return stateCopy;
        case DELETE_CHAPTER:
            stateCopy.chapterList = stateCopy.chapterList.filter(el => el._id !== action.payload);
            return stateCopy;
        case REORDER_CHAPTER:
            const chapters = Array.from(stateCopy.chapterList);
            const [reorderItem] = chapters.splice(action.payload.source.index, 1);
            chapters.splice(action.payload.destination.index, 0, reorderItem);
            chapters.forEach((el, i) => {
                if (el.index !== i + 1) {
                    el.index = i + 1;
                }
            });
            stateCopy.chapterList = chapters;
            return stateCopy;

        case OPEN_ADD_INFO:
            stateCopy.subjectBrief = action.payload;
            stateCopy.isAddInfo = true;
            return stateCopy;
        case BACK_TO_CHAPTER:
            stateCopy.isAddInfo = false;
            return stateCopy;
        case ADD_SUBJECT_BRIEF:
            stateCopy.subjectBrief = action.payload;
            stateCopy.isAddInfo = false;
            return stateCopy;

        //topics
        case GET_TOPICS_LIST:
            stateCopy.topicsList = action.payload.data.sort((a, b) => a.index - b.index);
            stateCopy.chapter_id = action.payload.id
            stateCopy.isTopicLoading = false;
            stateCopy.isSubTopicLoading = window.location.pathname === '/manage-content/subtopic' ? false : true;
            return stateCopy;
        case ADD_TOPIC:
            stateCopy.topicsList = [action.payload.data, ...stateCopy.topicsList];
            stateCopy.chapterList.forEach((product) => {
                if (product._id === action.payload.id) {
                    product.topics = product.topics + 1;
                }
            });
            return stateCopy;
        case UPDATE_TOPIC:
            stateCopy.topicsList = replaceObjectInArray(stateCopy.topicsList, action.payload._id, action.payload);
            return stateCopy;
        case UPDATE_TOPIC_STATUS:
            stateCopy.topicsList.forEach(el => {
                if (el._id === action.payload.topic_id) {
                    el.active = action.payload.active
                }
            })
            return stateCopy;
        case DELETE_TOPIC:
            stateCopy.topicsList = stateCopy.topicsList.filter(el => el._id !== action.payload);
            return stateCopy;
        case REORDER_TOPIC:
            const contents = Array.from(stateCopy.topicsList);
            const [reorderedItem] = contents.splice(action.payload.source.index, 1);
            contents.splice(action.payload.destination.index, 0, reorderedItem);
            contents.forEach((el, i) => {
                if (el.index !== i + 1) {
                    el.index = i + 1;
                }
            });
            stateCopy.topicsList = contents;
            return stateCopy;

        //resources
        case ADD_PARAMS:
            stateCopy.chapter_id = action.payload.chapterId;
            stateCopy.topic = action.payload.topic;
            stateCopy.title = action.payload.chapter;
            stateCopy.topic_id = action.payload.topicId;
            localStorage.setItem('chapterId', action.payload.chapterId);
            localStorage.setItem('topic', action.payload.topic);
            localStorage.setItem('topicId', action.payload.topicId);
            localStorage.setItem('title', action.payload.chapter);
            return stateCopy;
        case GET_TOPICS_SUBTOPICS:
            stateCopy.topicsListOfSubject = action.payload.data.sort((a, b) => a.index - b.index);
            stateCopy.subject_id = action.payload.id;
            return stateCopy;
        case RESET_RESOURCE_LOADING:
            stateCopy.isResourceLoading = true;
            return stateCopy;
        case GET_RESOURCE_LIST:
            stateCopy.resourceList = action.payload.sort((a, b) => a.index - b.index);
            stateCopy.isResourceLoading = false;
            return stateCopy;
        case UPDATE_RESOURCE_STATUS:
            stateCopy.resourceList.forEach(el => {
                if (el._id === action.payload.r_id) {
                    el.active = action.payload.checked
                }
            })
            return stateCopy;
        case REORDER_TOPIC_RESOURCES:
            const content = Array.from(stateCopy.resourceList);
            const [reorderItems] = content.splice(action.payload.source.index, 1);
            content.splice(action.payload.destination.index, 0, reorderItems);
            content.forEach((el, i) => {
                if (el.index !== i + 1) {
                    el.index = i + 1;
                }
            });
            stateCopy.resourceList = content;
            return stateCopy;
        case ADD_RESOURCE:
            stateCopy.resourceList = [...stateCopy.resourceList, action.payload];
            return stateCopy;
        case UPDATE_RESOURCE:
            stateCopy.resourceList = replaceObjectInArray(stateCopy.resourceList, action.payload._id, action.payload);
            return stateCopy;
        case GET_DOCUMENT_STATE:
            stateCopy.documentDataState = action.payload;
            stateCopy.progressLoader = action.payload;
            return stateCopy;
        case GET_DOCUMENT_PERCENT:
            stateCopy.percent = action.payload;
            return stateCopy;
        //practice-test

        case GET_PRACTICE_TESTS:
            stateCopy.practiceList = action.payload;
            stateCopy.practiceListCopy = action.payload;
            stateCopy.isClassLoading = false;
            return stateCopy;
        case SEARCH_TEST:
            if (action.payload === '') {
                stateCopy.practiceList = stateCopy.practiceListCopy;
            } else {
                const filtered = stateCopy.practiceListCopy?.filter(el => { return el.title.toLowerCase().includes(action.payload.toLowerCase()) });
                stateCopy.practiceList = filtered;
            }
            return stateCopy
        case UPDATE_TEST_STATUS:
            stateCopy.practiceList.forEach(el => {
                if (el._id === action.payload.quiz_id) {
                    el.active = action.payload.active
                }
            })
            return stateCopy;

        case ADD_PRACTICE_TEST:
            stateCopy.practiceList = [...stateCopy.practiceList, action.payload];
            return stateCopy;


        //question bank
        case GET_CLUBBED_QUESTION_LIST:
            stateCopy.clubQuestionList = action.payload.sort((a, b) => a.name.localeCompare(b.name));
            stateCopy.clubQuestionListCopy = action.payload.sort((a, b) => a.name.localeCompare(b.name));
            stateCopy.isViewed = false;
            stateCopy.subjectFromProgram = [];
            stateCopy.isClassLoading = false;
            stateCopy.isSubjectLoading = true;
            stateCopy.isQuestionLoading = false;
            return stateCopy;
        case GET_FILTER_SUBJECT:
            if (action.payload === '') {
                stateCopy.clubQuestionList = stateCopy.clubQuestionListCopy;
            }
            else {
                const filterResult = stateCopy.clubQuestionListCopy.filter(el => { return el.name.toLowerCase().includes(action.payload.toLowerCase()) })
                stateCopy.clubQuestionList = filterResult;
            }
            return stateCopy;
        case GET_FILTER_QUESTION:
            if (action.payload === '') {
                stateCopy.questionList = stateCopy.questionListCopy;
            }
            else {
                const filterResult = stateCopy.questionListCopy.filter(el => { return el.description.toLowerCase().includes(action.payload.toLowerCase()) })
                stateCopy.questionList = filterResult;
            }
            return stateCopy;
        case GET_QUESTION_FROM_SUBJECT:
            stateCopy.questionList = action.payload;
            stateCopy.questionListCopy = action.payload;
            stateCopy.isViewed = true;
            stateCopy.subjectFromProgram = [];
            stateCopy.isClassLoading = false;
            stateCopy.isSubjectLoading = true;
            return stateCopy;

        case GET_QUESTION_DETAILS:
            stateCopy.editQuestionData = action.payload;
            stateCopy.questionData = action.payload;
            stateCopy.qTitle = action.payload.description;
            stateCopy.qExplanation = action.payload.explanation;
            return stateCopy;

        case UPDATE_QUESTION_STATUS:
            stateCopy.questionList.forEach(el => {
                if (el._id === action.payload.question_id) {
                    el.active = action.payload.active
                }
            })
            return stateCopy;

        case SET_QUES_TITLE:
            stateCopy.qTitle = action.payload;
            return stateCopy;
        case SET_EXPLANTION:
            stateCopy.qExplanation = action.payload;
            return stateCopy;
        case SET_CHOICE1:
            stateCopy.choice1 = action.payload;
            return stateCopy;
        case SET_CHOICE2:
            stateCopy.choice2 = action.payload;
            return stateCopy;
        case SET_CHOICE3:
            stateCopy.choice3 = action.payload;
            return stateCopy;
        case SET_CHOICE4:
            stateCopy.choice4 = action.payload;
            return stateCopy;
        case GET_SUBJECT_CHAPTER:
            stateCopy.subjectChapters = action.payload;
            return stateCopy;
        case ADD_QUESTION:
            stateCopy.questionList = [...stateCopy.questionList, action.payload];
            stateCopy.isViewed = false;
            stateCopy.isShow = false;
            return stateCopy;
        case EDIT_QUESTION:
            stateCopy.questionList = [...stateCopy.questionList, action.payload];
            stateCopy.qTitle = '';
            stateCopy.choice1 = '';
            stateCopy.choice2 = '';
            stateCopy.choice3 = '';
            stateCopy.choice4 = '';
            stateCopy.qExplanation = '';
            stateCopy.isShow = false;
            return stateCopy;

        //quiz
        case ADD_QUESTION_IN_QUIZ:
            const updatedQuestionList = stateCopy.questionList.map((question) => {
                if (question._id === action.payload._id) {
                    return { ...question, checked: true };
                }
                return question;
            });
            let newData = {
                ...stateCopy,
                questionList: [...updatedQuestionList],
                questionListForQuiz: [...stateCopy.questionListForQuiz, action.payload],
            };
            return newData;
        case GET_QUIZ_QUESTION:
            stateCopy.questionListForQuiz = action.payload.questions.sort((a, b) => a.index - b.index);
            stateCopy.quizType = action.payload.type;
            return stateCopy;
        case SELECT_OR_DELETE_QUESTION_QUIZ:
            const dataSource = [...stateCopy.questionListForQuiz];
            const filteredQuestions = dataSource.filter(
                (allQuestions) => allQuestions._id !== action.payload
            );
            return {
                ...stateCopy,
                questionList: [
                    ...stateCopy.questionList.map((question) => {
                        if (question._id === action.payload) {
                            return { ...question, checked: false };
                        }
                        return question;
                    }),
                ],
                questionListForQuiz: filteredQuestions,
            };
        case "Reorder_Table_Data":
            const x = stateCopy.questionListForQuiz
            const [removed] = x.splice(action.payload.source.index, 1);
            x.splice(action.payload.destination.index, 0, removed);
            x.forEach((el, i) => {
                if (el.index !== i) {
                    el.index = i;
                }
            });
            stateCopy.questionListForQuiz = x;
            return stateCopy;
        case ADD_NEW_QUIZ:
            stateCopy.allQuizList = [...stateCopy.allQuizList, action.payload];
            return stateCopy;
        case Delete_All_Question_Quiz:
            stateCopy.questionListForQuiz = [];
            return stateCopy;

        // Link preview
        case GET_PREVIEW:
            stateCopy.previewData = action.payload;
            return stateCopy;
        case NULL_PREVIEW:
            stateCopy.previewData = action.payload;
            return stateCopy;
        case RESET_PERCENTAGE:
            stateCopy.percent = 0;
            stateCopy.progressLoader = false;
            // stateCopy.qTitle = '';
            stateCopy.choice1 = '';
            stateCopy.choice2 = '';
            stateCopy.choice3 = '';
            stateCopy.choice4 = '';
            stateCopy.qExplanation = '';
            return stateCopy;






















        case "Get_Resource_List_Error":
            stateCopy.resourceList = [];
            stateCopy.isResourceLoading = false;
            return stateCopy;


        case "Update_Resource":
            return stateCopy;


        case "Get_Search_Subject":
            stateCopy.subjectList = action.payload;
            return stateCopy;

        case "Remove_Subject":
            stateCopy.semesters = stateCopy.semesters.map((el) => {
                el.subjects = el.subjects.filter(
                    (sub) => sub._id !== action.payload._id
                );
                return el;
            });
            stateCopy.subjectList = [...stateCopy.subjectList, action.payload];
            return stateCopy;
        case "Edit_Programs":
            stateCopy.addSubject = true;
            return stateCopy;

        case 'Change_Question_Status':
            stateCopy.questionList.forEach(answer => {
                if (answer._id === action.payload.id) {
                    answer.active = !action.payload.checked;
                }
            })
            return stateCopy
        case 'Change_Practice_Status':
            stateCopy.practiceList.forEach(answer => {
                if (answer._id === action.payload.id) {
                    answer.active = !action.payload.checked;
                }
            })
            return stateCopy

        case "Get_ALL_Quiz_List":
            stateCopy.allQuizList = action.payload;
            return stateCopy;

        case 'Get_Subject_Topics_List':
            stateCopy.subjectTopicList = action.payload.sort((a, b) => (a.index > b.index) ? 1 : -1).map(el => [{ value: el._id, label: el.title }][0]);
            return stateCopy;
        case 'Get_Resources_List_For_Type':
            stateCopy.resourceListForType = action.payload.data.map(item => ({ ...item, type: action.payload.type, duration: item.duration ? item.duration : null }));
            stateCopy.resourceLoading = false;
            return stateCopy;
        case 'Get_Gallery_Images':
            let uniqueChars = [...new Set(action.payload)];
            stateCopy.images = uniqueChars;
            return stateCopy;
        default:
            return stateCopy;
    }
}


