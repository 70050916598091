import { GET_SCHOOL_ACHIEVERS, GET_CLASS_ACHIEVERS, GET_SECTION_ACHIEVERS, APPERCIATION_SENT, LOADER_ACTIVE, GET_MONTHLY_DATA, GET_SUBJECT_DATA } from '../Types/types';

const intialState = {
    sectionAchievers: [],
    classAchievers: [],
    monthly: [],
    subjectWise: [],
    isListLoading: true,
    isCardLoading: true,
    // complete: true
};

export default function achieverReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_SCHOOL_ACHIEVERS:
            stateCopy.schoolAchievers = action.payload;
            stateCopy.isListLoading = false;
            stateCopy.isCardLoading = false;
            return stateCopy;
        case GET_CLASS_ACHIEVERS:
            stateCopy.classAchievers = action.payload;
            stateCopy.isListLoading = false;
            stateCopy.isCardLoading = false;
            return stateCopy;
        case GET_SECTION_ACHIEVERS:
            stateCopy.sectionAchievers = action.payload;
            stateCopy.isListLoading = false;
            stateCopy.isCardLoading = false;
            return stateCopy;
        case APPERCIATION_SENT:
            if (action.payload.obj.type === 'section') {
                stateCopy.sectionAchievers.forEach(el => {
                    if (el.user_id === action.payload.obj.to) {
                        el.appreciation = true
                    }
                })
            }
            else if (action.payload.obj.type === 'class') {
                stateCopy.classAchievers.forEach(el => {
                    if (el.user_id === action.payload.obj.to) {
                        el.appreciation = true
                    }
                })
            }
            else if (action.payload.obj.type === 'school') {
                stateCopy.schoolAchievers.forEach(el => {
                    if (el.user_id === action.payload.obj.to) {
                        el.appreciation = true
                    }
                })
            }
            return stateCopy;
        case LOADER_ACTIVE:
            stateCopy.isListLoading = true;
            stateCopy.isCardLoading = true;
            return stateCopy;

        case GET_MONTHLY_DATA:
            stateCopy.months = action.payload.months;
            stateCopy.consumed = action.payload.data;
            return stateCopy;
        case GET_SUBJECT_DATA:
            stateCopy.subject = action.payload.subjects;
            stateCopy.contentCounts = action.payload.data;
            return stateCopy;
        default:
            return stateCopy
    }
}