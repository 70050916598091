/* eslint-disable no-unused-vars */
import {
    OPEN_ADD_NOTICE, CLOSE_ADD_NOTICE, GET_ADMIN_NOTICE, GET_NEXT_ADMIN_NOTICE, GET_SEARCH_RESULT1, GET_USER_SUGGESTION, DEACTIVATE_NOTICE, GET_VIEW_DETAIL, EMPTY_VIEWED, CLEAR_DETAILS, NOTICE_CREATED, GET_FILES, GET_STUDENTS, GET_STAFF,
    SELECT_ALL, SELECT_ONE_CHECKED, SELECT_SELECTED, RESET_USERLIST, RESET_EMAIL_USER,
    GET_STUDENT_NOTICE_LIST, GET_STUDENT_NEXT_NOTICE_LIST, GET_SEARCH_RESULT, GET_ATTACHMENT_RESOURCE, GET_EXTRA_DETAILS
} from '../Types/types';
import { getApiCall, postApiCall } from '../../utils/request';
import { returnErrors } from './errorAction';


export const openNotice = () => async (dispatch) => {
    dispatch({
        type: OPEN_ADD_NOTICE
    })
}

export const backToMain = () => async (dispatch) => {
    dispatch({
        type: CLOSE_ADD_NOTICE
    })
}

export const getAdminNotice = (page) => async (dispatch) => {

    try {
        const response = await getApiCall(`admin/noticeboard?page=${page}`);
        dispatch({ type: GET_ADMIN_NOTICE, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error.response !== undefined && error.response.data.message, error.response !== undefined && error.response.status);
    }
}


export const getNextAdminNotice = (page) => async (dispatch) => {

    try {
        const response = await getApiCall(`admin/noticeboard?page=${page}`);
        dispatch({ type: GET_NEXT_ADMIN_NOTICE, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error.response !== undefined && error.response.data.message, error.response !== undefined && error.response.status);
    }
}

export const getUser = (val) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/noticeboard/search/user?query=${val}`);
        dispatch({ type: GET_USER_SUGGESTION, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error.response !== undefined && error.response.data.message, error.response !== undefined && error.response.status);
    }
}

export const deactivateNotice = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/noticeboard/deactivate?_id=${id}`);
        return dispatch({
            type: DEACTIVATE_NOTICE,
            payload: id
        })

    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status);
    }
}


export const getViews = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/noticeboard/user/details?_id=${id}`);
        dispatch({ type: GET_VIEW_DETAIL, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error.response !== undefined && error.response.data.message, error.response !== undefined && error.response.status);
    }
}


export const emptyDetails = (type) => async (dispatch) => {
    return dispatch({
        type: EMPTY_VIEWED, payload: type
    })
}

export const clearDetails = () => async (dispatch) => {
    return dispatch({
        type: CLEAR_DETAILS
    })
}

export const createdNotice = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`admin/noticeboard`, obj);
        dispatch({ type: NOTICE_CREATED, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error.response !== undefined && error.response.data.message, error.response !== undefined && error.response.status);
    }
}

export const uploadFile = (obj, title) => async (dispatch) => {
    try {
        const response = await postApiCall(`admin/notification/file/upload`, obj);
        if (response.data.success) {
            const url = response.data.url;
            return dispatch({ type: GET_FILES, payload: { url, title } });
        } else {
            dispatch({ type: "GET_FILE_STATE", payload: false });
            returnErrors(
                dispatch, response?.data?.message, response?.status
            );
        }
    } catch (error) {
        returnErrors(dispatch, error.response.data.message, error.response.status);
    }
}

export const getListofUsers = (type, cls, section, role) => async (dispatch) => {
    console.log(type, cls, section)
    try {
        var response
        if (type === 'student') {
            response = await getApiCall(`admin/notification/list/users?class=${cls}&section=${section}`);
            console.log(response.data.data)
            dispatch({ type: GET_STUDENTS, payload: response.data.data });
        }
        else {
            response = await getApiCall(`admin/notification/list/users?role=${role}`);
            dispatch({ type: GET_STAFF, payload: response.data.data });
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}



export const getStudentNotices = (page) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/noticeboard/list/notice?page=${page}`);
        dispatch({ type: GET_STUDENT_NOTICE_LIST, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error.response !== undefined && error.response.data.message, error.response !== undefined && error.response.status);
    }
}

export const getNextStudentNotices = (page) => async (dispatch) => {

    try {
        const response = await getApiCall(`student/noticeboard/list/notice?page=${page}`);
        dispatch({ type: GET_STUDENT_NEXT_NOTICE_LIST, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error.response !== undefined && error.response.data.message, error.response !== undefined && error.response.status);
    }
}

export const getSearch = (val) => async (dispatch) => {
    try {
        if (localStorage.getItem('role') === 'student') {
            if (val.length > 1) {
                const response = await getApiCall(`student/noticeboard/search/notice?query=${val}`);
                dispatch({ type: GET_SEARCH_RESULT, payload: response.data.data });
            }
            else {
                const response = await getApiCall(`student/noticeboard/list/notice?page=1`);
                dispatch({ type: GET_STUDENT_NOTICE_LIST, payload: response.data.data });
            }
        }
        else {
            if (val.length > 1) {
                const response = await getApiCall(`student/noticeboard/search/notice?query=${val}`);
                dispatch({ type: GET_SEARCH_RESULT1, payload: response.data.data });
            }
            else {
                const response = await getApiCall(`admin/noticeboard?page=1`);
                dispatch({ type: GET_ADMIN_NOTICE, payload: response.data.data });
            }
        }
    }
    catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}
export const getAttachmentResource = (id) => async (dispatch) => {

    try {
        const response = await getApiCall(`student/noticeboard/list/resources?_id=${id}`);
        dispatch({ type: GET_ATTACHMENT_RESOURCE, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error.response !== undefined && error.response.data.message, error.response !== undefined && error.response.status);
    }
}
export const markedRead = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`notification/mark?_id=${id}`);
        dispatch({ type: "Notification_Marked" });

    } catch (error) {
        returnErrors(dispatch, error.response !== undefined && error.response.data.message, error.response !== undefined && error.response.status);
    }
}




export const selectAll = (type, checked) => async (dispatch) => {
    return dispatch({ type: SELECT_ALL, payload: { type, checked } })
}

export const checkOne = (type, id, checked) => async (dispatch) => {
    return dispatch({ type: SELECT_ONE_CHECKED, payload: { type, id, checked } })
}
export const selected = (type, key) => async (dispatch) => {
    return dispatch({ type: SELECT_SELECTED, payload: { type, key } })
}

export const resetUserList = () => async (dispatch) => {
    return dispatch({ type: RESET_USERLIST })
}

export const resetUser = () => async (dispatch) => {
    return dispatch({ type: RESET_EMAIL_USER })
}







export const getExtraDetails = (id) => async (dispatch) => {

    try {
        const response = await getApiCall(`auth/extra/details?_id=${id}`);
        console.log(response.data.data)
        dispatch({ type: GET_EXTRA_DETAILS, payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error.response !== undefined && error.response.data.message, error.response !== undefined && error.response.status);
    }
}






export const resetPopup = (type) => async (dispatch) => {
    return dispatch({
        type: 'Reset_Popups', payload: type
    })
}





