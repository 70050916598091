import React, { Fragment, useRef, useEffect, useState } from 'react';
import '../Counselling/counselling.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { TopicLoader } from '../../Loader/loader';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Table } from 'react-bootstrap';
import { getAbilities, getAbilityQuestions, changeValue, submitTest, getAbilityResponses, resetAbilityQuestions } from '../../Redux/ActionCreators/counsellingAction';
import { convertMillisecondsToTime, msTominutes } from '../../utils/miscelleneous';

export default function Ability() {
    const [isOpen, setIsOpen] = useState(false);
    const [details, setDetails] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);

    const [time, setTime] = useState(null);
    const [timerRunning, setTimerRunning] = useState(false);

    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);

    useEffect(() => {
        let interval
        if (isOpen) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => {
                    if (prevSeconds === 59) {
                        setMinutes((prevMinutes) => prevMinutes + 1);
                        return 0;
                    } else {
                        return prevSeconds + 1;
                    }
                });
            }, 1000);
        } else {
            setSeconds(0);
            setMinutes(0);
            clearInterval(interval);
        }

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [isOpen]);





    useEffect(() => {
        let timer;

        if (timerRunning && time > 0) {
            timer = setInterval(() => {
                setTime((prevTime) => prevTime - 1);
            }, 1000);
        }
        return () => {
            clearInterval(timer);
        };
    }, [timerRunning, time]);

    const startTimer = () => {
        setTimerRunning(true);
    };

    const resetTimer = async () => {
        setTime(0); // Reset time to 5 minutes
        setTimerRunning(false);
        setIsOpen(false);
        await confirmSubmit()
        await dispatch(getAbilities(location?.state?.id));
    };

    useEffect(() => {
        if (time === 0) {
            resetTimer();
        }
    }, [time]);

    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        if (location?.state?.id) {
            dispatch(getAbilities(location?.state?.id))
        }
    }, [])

    const { loadingAbility, abilities, loadingAbilityQues, questions } = useSelector(state => state.counselling)


    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }

    const getQuestions = (el) => {
        setIsOpen(true);
        setDetails(el);
        setTime(msTominutes(el.duration) * 60);
        el.question_count !== 0 && startTimer();
        dispatch(resetAbilityQuestions());
        dispatch(getAbilityQuestions(el._id));
    }


    const getResponses = (el) => {
        setIsOpen(true);
        setDetails(el);
        setTime(null);
        dispatch(resetAbilityQuestions());
        dispatch(getAbilityResponses(el._id, el.test_id));
    }

    const confirmSubmit = async () => {
        const obj = {
            ability_id: details?._id,
            duration: (minutes * 60 + seconds) * 1000,
            data: questions?.map(el => [{ q_id: el._id, a_id: el.answer ? el.answer : null }][0])
        }
        const res = await dispatch(submitTest(obj));
        if (res?.type === 'SAVE_TEST') {
            setIsOpen(false);
            setIsConfirm(false);
            setTime(null);
            setSeconds(0);
            setMinutes(0);
            setTimerRunning(false);
            dispatch(getAbilities(location?.state?.id));
        }
    }

    const submit = async () => {
        setIsConfirm(true);

    }
    const formatTime = (time) => (time < 10 ? `0${time}` : time);
    console.log(questions)
    return (
        <div className='ability-holder'>
            <div className='ability-header'>
                <img src='/images/back1.svg' onClick={goBack} alt='' />
                <p>Career Abilities</p>
            </div>

            <div className="table-admin" >
                <Table responsive >
                    <thead>
                        <tr className="header-table">
                            <th>Ability </th>
                            <th className='text-center'>Questions</th>
                            <th className='text-center'>Duration</th>
                            <th className='text-center'>Action</th>
                        </tr>
                        <hr />
                    </thead>
                    <tbody className="table-body" >
                        {loadingAbility ?
                            <TopicLoader /> :
                            (abilities !== undefined && abilities?.length > 0 ?
                                abilities?.map((el, i) => {
                                    return (
                                        <tr key={i} className='cursor-pointer'
                                        >

                                            <td style={{ width: '30%' }}  >
                                                {el.title}
                                            </td>
                                            <td className='text-center'>{el.title === 'Psychomotor ability (PM)' ? 70 : el.question_count}</td>
                                            <td className='text-center'>{((el.title === 'Spatial ability (SA)') || (el.title === 'Clerical ability (CL)')) ? 'Not to be disclosed' : convertMillisecondsToTime(el.duration)}</td>
                                            <td className='text-center cursor-pointer' style={{ color: '#f5821f' }}
                                                onClick={() => el.completed ? getResponses(el) : getQuestions(el)}
                                            >{el.completed ? 'View Responses ' : 'Take Test'}</td>
                                        </tr>
                                    )
                                })

                                :
                                <tr>
                                    <div className='empty-list'>
                                        <img src='/images/empty.png' alt='' />
                                    </div>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </div>

            {/* ########## submit answer  modal ############### */}
            <Modal
                show={isOpen}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // onHide={() => questions?.length ? '' : setIsOpen(false)}
                dialogClassName={'question-modal'}
            >
                <Fragment>

                    <div className='questions-planning' style={{ minHeight: 490 }}>
                        <div className='add-header p-2' style={{ width: '100%' }}>
                            <div className='d-flex align-items-center'>
                                {details?.completed ? <img src='/images/back.svg' alt='' onClick={() => setIsOpen(false)} width={20} height={20} /> : null}
                                <div style={{ marginLeft: 20 }}>
                                    <h2>Ability : {details?.title}</h2>
                                    {/* {formatTime(minutes)}:{formatTime(seconds)} */}
                                    {/* <p className='text-left'>{convertDate(details?.date)} | {details?.start} - {details?.end} </p> */}
                                </div>
                            </div>

                            <div className='d-flex align-items-center'>
                                <div className='d-flex align-items-center  timer' style={{ visibility: ((details?.title === 'Spatial ability (SA)') || (details?.title === 'Clerical ability (CL)')) ? 'hidden' : 'visible' }}>
                                    <img src='/images/timer.svg' width={15} alt='' style={{ verticalAlign: 'middle' }} />&nbsp;&nbsp; <p>{Math.floor(time / 60)}:{(time % 60).toString().padStart(2, '0')}</p>
                                </div>

                                &nbsp;&nbsp;
                                {(questions?.length && !details?.completed) ?
                                    <button className='save-btn mt-0 ms-4' style={{ color: "#fff", border: 0 }} onClick={submit}>Submit</button> :
                                    <img src='/images/close1.svg' alt='' className='close-icon' onClick={() => setIsOpen(false)} />
                                }
                            </div>
                        </div>
                        <div>
                            {loadingAbilityQues ?
                                <TopicLoader /> :

                                questions !== undefined && questions?.length ?

                                    (details?.title === 'Verbal Ability (VA)' ?
                                        <ul className='schedule-list ' >
                                            <Fragment>
                                                <h3 className='my-2 mx-2'>Verbal Ability(VA) Part-1</h3>
                                                <div className='d-flex flex-wrap'>
                                                    {questions !== undefined && questions.slice(0, 15)?.map((el, index) => {
                                                        return (
                                                            <li key={index} className='d-flex' style={{ width: '32%', pointerEvents: details?.completed ? 'none' : 'auto', marginLeft: 5, marginTop: 5 }} >
                                                                <div className='marks-box d-flex align-items-center w-100' >
                                                                    <p style={{ width: '50%' }}>{el.title}</p>
                                                                    <div className='d-flex align-items-center justify-content-end' style={{ width: '45%', marginRight: 12 }} >
                                                                        {
                                                                            el.options?.map((el1, i) => {
                                                                                return (
                                                                                    <div class="form-check form-check-inline">
                                                                                        <label class="form-check-label" htmlFor={`inlineRadio${index}-${i}`}>{el1.title}</label>
                                                                                        <input class="form-check-input" type="radio" name={`inlineRadioOptions${index}`} id={`inlineRadio${index}-${i}`} checked={el.answer === el1._id ? true : false} onChange={(e) => { dispatch(changeValue(el1?._id, el._id)) }} value={el.answer} />
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                    }
                                                </div>
                                                <br />
                                                <h3 className='my-2 mx-2'>Verbal Ability(VA) Part-2</h3>
                                                <div className='d-flex flex-wrap'>
                                                    {questions !== undefined && questions.slice(15, 24)?.map((el, index) => {
                                                        return (
                                                            <li key={index} className='d-flex' style={{ width: '32%', pointerEvents: details?.completed ? 'none' : 'auto', marginLeft: 5, marginTop: 5 }} >
                                                                <div className='marks-box d-flex align-items-center w-100' >
                                                                    <p style={{ width: '50%' }}>{el.title}</p>
                                                                    <div className='d-flex align-items-center justify-content-end' style={{ width: '45%', marginRight: 12 }} >
                                                                        {
                                                                            el.options?.map((el1, i) => {
                                                                                return (
                                                                                    <div class="form-check form-check-inline">
                                                                                        <label class="form-check-label" htmlFor={`inlineRadio${index + 16}-${i}`}>{el1.title}</label>
                                                                                        <input class="form-check-input" type="radio" name={`inlineRadioOptions${index + 16}`} id={`inlineRadio${index + 16}-${i}`} checked={el.answer === el1._id ? true : false} onChange={(e) => { dispatch(changeValue(el1?._id, el._id)) }} value={el.answer} />
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </Fragment>
                                        </ul>
                                        :
                                        <ul className='schedule-list d-flex flex-wrap' >
                                            {questions !== undefined && questions?.map((el, index) => {
                                                return (
                                                    <li key={index} className={((details?.title === 'Spatial ability (SA)') || (details?.title === 'Clerical ability (CL)')) ? 'extra d-flex' : 'no-extra d-flex'} style={{ pointerEvents: details?.completed ? 'none' : 'auto', width: ((details?.title === 'Spatial ability (SA)') || (details?.title === 'Clerical ability (CL)')) ? '23.8%' : '32%', marginLeft: 5, marginTop: 5 }} >
                                                        <div className='marks-box d-flex align-items-center w-100' >
                                                            <p style={{ width: '50%' }}>{el.title}</p>
                                                            <div className='d-flex align-items-center justify-content-end' style={{ width: '45%', marginRight: 12 }} >
                                                                {
                                                                    el.options?.map((el1, i) => {
                                                                        return (
                                                                            <div class="form-check form-check-inline">
                                                                                <label class="form-check-label" htmlFor={`inlineRadio${index}-${i}`}>{el1.title}</label>
                                                                                <input class="form-check-input" type="radio" name={`inlineRadioOptions${index}`} id={`inlineRadio${index}-${i}`} checked={(el.answer === el1._id ? true : false)} onChange={(e) => { dispatch(changeValue(el1?._id, el._id)) }} value={el.answer} />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                            }

                                        </ul>)
                                    :
                                    <div className='empty-list'>
                                        <img src='/images/empty.png' alt='' />
                                        {
                                            <p>Please take this test offline</p>
                                        }
                                    </div>
                            }

                        </div>
                    </div>
                </Fragment>
            </Modal>

            {/* ########## Confirm submission ############### */}
            <Modal
                show={isConfirm}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"confirm-modal"}
            >
                <div className="p-4 text-center">
                    <h1 className='mb-2'>{questions?.some(el => el.answer == null) ? `Some questions are unanswered. Are you sure you want to submit now? You won't be able to answer them later` : `Are you sure you want to submit now? You can't change your answers later.`}</h1>
                    <div className="save-btn mt-4">
                        <button onClick={confirmSubmit}>Yes</button> &nbsp;&nbsp;
                        <button onClick={() => setIsConfirm(false)}>No</button>
                    </div>
                </div>
            </Modal>
        </div >
    )
}
