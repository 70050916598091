import { OPEN_SHARE, CLOSE_SHARE, SHARE_CONTENT } from "../Types/types";


let intialState = {
    isShare: false
}


export default function shareReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case OPEN_SHARE:
            stateCopy.isShare = true;
            stateCopy.shareDetails = action.payload;
            return stateCopy;
        case CLOSE_SHARE:
            stateCopy.isShare = false;
            stateCopy.shareDetails = null;
            return stateCopy;
        case SHARE_CONTENT:
            stateCopy.isShare = false;
            return stateCopy;
        default:
            return stateCopy;
    }
}

