import {
    GET_REWARDS, REWARD_STATUS, Select_One, GET_ONE_CHECKED,
    GET_REDEEM_LIST, GET_NEXT_REDEEM_LIST, GET_COUNTRIES, REFRESH_LIST, VOUCHER_SENT,
    GET_PROVIDERS, PROVIDER_STATUS
} from '../Types/types';

let intialState = {
    rewardList: [],
    isLoading: true,
    rewards: []
}

export default function adminRewardReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_REWARDS:
            stateCopy.rewards = action.payload.map(item => ({ ...item, isChecked: true }));
            stateCopy.rewardsCopy = action.payload.map(item => ({ ...item, isChecked: true }));
            return stateCopy;
        case REWARD_STATUS:
            stateCopy.rewards.forEach(element => {
                if (element._id === action.payload.id) {
                    element.active = action.payload.active ? false : true
                }
            });
            return stateCopy;
        case Select_One:
            console.log(action.payload)
            if (action.payload.checked) {
                stateCopy.rewards.forEach(el => {
                    if (el._id === action.payload.id) {
                        el.isChecked = true
                    }
                })
            }
            else {
                stateCopy.rewards.forEach(el => {
                    if (el._id === action.payload.id) {
                        el.isChecked = false
                    }
                })
            }
            return stateCopy;
        case GET_ONE_CHECKED:
            if (action.payload !== null) {
                stateCopy.rewards.forEach(item => {
                    const foundIndex = action.payload.find(i => i._id === item._id);
                    console.log(foundIndex)
                    if (foundIndex) {
                        item.isChecked = true;
                    } else {
                        item.isChecked = false;
                    }
                })
            } else {
                stateCopy.rewards = stateCopy.rewardsCopy;
            }
            return stateCopy;
        case GET_COUNTRIES:
            stateCopy.countries = action.payload;
            return stateCopy;
        case GET_REDEEM_LIST:
            stateCopy.redeemList = action.payload;
            stateCopy.isLoading = false;
            return stateCopy;
        case GET_NEXT_REDEEM_LIST:
            stateCopy.redeemList = stateCopy.redeemList.concat(action.payload);
            stateCopy.isLoading = false;
            return stateCopy;
        case REFRESH_LIST:
            stateCopy.isLoading = true;
            return stateCopy;
        case VOUCHER_SENT:
            if (action.payload.type === 'default') {
                const answersns = stateCopy.rewardList.map(answer => {
                    if (answer._id === action.payload?.obj._id) return {
                        user_name: answer.user_name,
                        email: answer.email,
                        mobile: answer.mobile,
                        date: answer.date,
                        company_name: answer.company_name,
                        complete: !answer.complete,
                        provider: answer.provider,
                        value: answer.value,
                        _id: answer._id,
                        reward_id: answer.reward_id,
                        image: answer.image,
                        bgcolor: answer.bgcolor,
                        validity: answer.validity,
                        voucher: action.payload?.obj?.voucher
                    }
                    return answer
                })
                stateCopy.rewardList = answersns;
            }
            else {
                const answersns = stateCopy.redeemList.map(answer => {
                    if (answer._id === action.payload?.obj._id) return {
                        user_name: answer.user_name,
                        email: answer.email,
                        mobile: answer.mobile,
                        date: answer.date,
                        company_name: answer.company_name,
                        complete: !answer.complete,
                        provider: answer.provider,
                        value: answer.value,
                        _id: answer._id,
                        reward_id: answer.reward_id,
                        image: answer.image,
                        bgcolor: answer.bgcolor,
                        validity: answer.validity,
                        voucher: action.payload?.obj?.voucher
                    }
                    return answer
                })
                stateCopy.redeemList = answersns;
            }

        case GET_PROVIDERS:
            stateCopy.providers = action.payload;
            return stateCopy;
        case PROVIDER_STATUS:
            stateCopy.providers.forEach(element => {
                if (element._id === action.payload.id) {
                    element.active = action.payload.active ? false : true
                }
            });
            return stateCopy;
        default:
            return stateCopy;
    }
}
