import React from 'react';
import './careerPath.scss';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import { SampleNextArrow, SamplePrevArrow } from '../../utils/miscelleneous';
import { Link, useNavigate } from 'react-router-dom';
import careers from './mostPopular.json';

export default function CareerPath() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const goToCareers = (cls) => {
        navigate(`/career-path/${cls === '10' ? 'after10th' : 'after12th'}`, {
            state: {
                class: cls,
                path: false
            }
        })
    }
    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 3,
        // variableWidth: true,
        className: "variable-width",
        nextArrow: <SampleNextArrow className={"next-arrow"} />,
        prevArrow: <SamplePrevArrow className={"prev-arrow"} />,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1008,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 3
                }
            },


        ]
    };
    return (
        <div className='career-path-holder'>
            <h1>Career Path</h1>
            <div className='career-cards'>
                <div className='career-card container-fluid ' style={{ paddingRight: 0 }}>
                    <h4>Most popular career paths</h4>
                    {
                        window.innerWidth > 767 ?

                            <div className={'row mt-1'} style={{ marginLeft: -25 }}>
                                <div className='w-100'>
                                    <Slider {...settings} >
                                        {
                                            careers !== undefined && careers.map((el) => {
                                                return (
                                                    <div key={el._id} className="card" style={{ width: '18.5%', marginRight: 10, }} >
                                                        <Link to={`/career-path/path`}
                                                            state={{
                                                                detail: el,
                                                                path: true
                                                            }}
                                                        >
                                                            <img src={el.image} className="card-img-top" alt='' width={'100%'} height={140} />

                                                            <div className="card-body p-0">
                                                                <div style={{ padding: ' 7px 7px 0px 7px' }}>
                                                                    <div className='d-flex justify-content-center align-items-start flex-column'>
                                                                        <h5 className='elipsis-2 w-96' title={el.jobtitle}>
                                                                            {el.jobtitle}
                                                                        </h5>

                                                                    </div>

                                                                    <div style={{ borderTop: '1px solid #ddd', marginTop: 8 }}></div>
                                                                    <button>{t('View Path')}</button>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Slider>
                                </div>
                            </div>
                            :
                            <div className={window.innerWidth > 767 ? 'row' : 'row flex-nowrap overflow-auto'}>
                                {
                                    careers !== undefined && careers?.map((el) => {
                                        return (
                                            <div key={el._id} className="card" style={{ width: 230, marginRight: 10 }} >
                                                <Link to={`/career-path/path`}
                                                    state={{
                                                        detail: el,
                                                        path: true
                                                    }}
                                                >
                                                    <img className="card-img-top" src={el.image} style={{ height: 170 }} alt="Card" />

                                                    <div className="card-body p-0">
                                                        <div style={{ padding: 7, paddingTop: 5, paddingRight: 15, }}>
                                                            <div className='d-flex justify-content-center align-items-start flex-column'>
                                                                <h5 className='elipsis-2 w-96' title={el.jobtitle}>
                                                                    {el.jobtitle}
                                                                </h5>
                                                            </div>
                                                            <div style={{ borderTop: '1px solid #ddd', marginTop: 8 }}></div>
                                                            <button>{t('View Path')}</button>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    }
                </div>
            </div>
            <div className='counselling-introduction'>
                <div className='introduction'>
                    <h3>{t('Not sure what to do next?')}</h3>
                    {window.innerWidth > 767 &&
                        <p>{t('Take the first step towards a fulfilling career by exploring your strengths, interests, and skills. Our easy-to-follow assessment will provide you with')}</p>}
                    <div className='counselling-btn' >
                        <button onClick={() => goToCareers('10')}>{t('Explore options')}</button>
                    </div>
                </div>
                <div className='counselling-img' >
                    {window.innerWidth > 767 ?
                        <img src='/images/career-path/career-path1.svg' alt='' /> :
                        <img src='/images/coun-img4.svg' alt='' />
                    }
                </div>
            </div>
            <div className='career-classes'>
                <div className='career-class position-relative'>
                    <div style={{ width: window.innerWidth > 767 ? '45%' : '100%' }}>
                        <h4>
                            Explore Career options after 10th
                        </h4>
                        <p className='mt-2'>Level up on the above skills and collect up to 50 Engagement points </p>
                        <button className='outline-btn mt-3' onClick={() => goToCareers('10')}>Explore after 10th</button>
                    </div>
                    {window.innerWidth > 767 &&
                        <div style={{ position: 'absolute', right: 0, top: 0 }}>
                            <img src='/images/career-path/student10th.svg' alt='' height={216} />
                        </div>}
                </div>
                <div className='career-class position-relative'>
                    <div style={{ width: window.innerWidth > 767 ? '45%' : '100%' }}>
                        <h4>
                            Explore Career options after 12th
                        </h4>
                        <p className='mt-2'>Level up on the above skills and collect up to 50 Engagement points </p>
                        <button className='outline-btn mt-3' onClick={() => goToCareers('12')}>Explore after 12th</button>
                    </div>
                    {window.innerWidth > 767 &&
                        <div style={{ position: 'absolute', right: 0, top: 0 }}>
                            <img src='/images/career-path/student12th.svg' alt='' height={216} />
                        </div>}
                </div>
            </div>
            {/* <div className='explore-path'>
                <img src='/images/career-path-explore.svg' alt='' />
                <h2>We have noticed you haven’t settled on your career path yet!</h2>
                <p>We are here to help you make the most of your professional journey
                    Ready to take the next step?</p>
                <button>Explore your career path</button>
            </div> */}
        </div >
    )
}
