import React, { Fragment, useEffect, useState } from 'react';
import './counselling.scss';
import Modal from 'react-bootstrap/Modal';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';
import TestReport from './test-report';
import { useSelector } from 'react-redux';
import { resetReport, getReport, resetListLoading, getSlotList, addSlots, editSlots, changeSlotStatus, confirmDeleteSlot, getAllBookings, getBookings, getBookingSlots, bookSlots } from '../../Redux/ActionCreators/counsellingAction';
import { useDispatch } from 'react-redux';
import { exitFullscreen, goFullScreen, convertDate, formatAMPM, getDatesInMonth, getValuesInRange, updateActiveStatus } from '../../utils/miscelleneous';
import { Table } from 'react-bootstrap';
import { BallBeat, TopicLoader } from '../../Loader/loader';
import Select from 'react-select';
import Switch from 'react-switch';
import ConfirmModal from '../../utils/confirmModal';
import { getProfileDetails } from '../../Redux/ActionCreators/profileAction';
const SelfTest = React.lazy(() => import('../Counselling/selfTest'));


const years = [{ label: 2024, value: '2024' }, { label: 2025, value: '2025' }, { label: 2026, value: '2026' }, { label: 2027, value: '2027' }];
const months = ['January', 'Feburary', 'March', 'April', 'May', "June", "July", 'August', 'September', 'October', 'November', 'December'];
const time = ['09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', "13:30", '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30'];


export default function Counselling() {
    const [isSchedule, setIsSchedule] = useState(false);
    const [active, setActive] = useState(localStorage.getItem('role')?.includes('admin') ? 'schedule' : 'upcoming');
    const [value, setValue] = useState(new Date());
    const [test, setTest] = useState(false);
    const { t } = useTranslation();
    const [isReport, setIsReport] = useState(false);
    const [isAddSlot, setIsAddSlot] = useState(false);
    const [edit, setEdit] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [yearError, setYearError] = useState('');
    const [monthError, setMonthError] = useState('');
    const [dateError, setDateError] = useState('');
    const [startDateError, setStartDateError] = useState('');
    const [endDateError, setEndDateError] = useState('');
    const [session, setSessions] = useState([{ key: 0, from: '', to: '' }]);
    const [sessionError, setSessionError] = useState('');
    const [slotId, setSlotId] = useState('');
    const [sessionId, setSessionId] = useState('');
    const [slotError, setSlotError] = useState('');
    const [isDetails, setIsDetails] = useState(false);
    const [details, setDetails] = useState('');
    const [deleteId, setDeleteId] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const [testTypes, setTestTypes] = useState(null);
    const dispatch = useDispatch();
    const { isLoading, loading, slots, allBookings, bookingList, bookingSlots, profil } = useSelector(state => state.counselling);


    useEffect(() => {
        const firstRender = async () => {
            await dispatch(resetListLoading());
            const res1 = await dispatch(getProfileDetails());
            if (res1?.type === 'GET_PROFILE') {
                setTestTypes(res1?.payload.tests?.filter(el => el.name === 'riasec')[0]);
            }
            if (active === 'dates') {
                dispatch(getSlotList());
            }
            else if (active === 'schedule') {
                dispatch(getAllBookings());
            }
            else if (active === 'upcoming') {
                dispatch(getBookings())
            }
        }
        firstRender();
    }, [active])

    const openAdd = (detail, edit, slot) => {
        setIsAddSlot(true);
        setEdit(edit);
        if (detail !== null) {
            setSlotId(slot?._id);
            setMonth({ label: detail?.month, value: detail?.month });
            setYear({ label: detail?.year, value: detail?.year });
            setStartDate({ label: slot.date, value: slot.date });
            setEndDate({ label: detail?.slots[detail.slots?.length - 1].date, value: detail.slots[detail.slots?.length - 1].date });
            const filtered = detail?.slots.filter(el => el.date === slot.date)[0].sessions;
            setSessions(filtered);
        } else {
            setYear('');
            setMonth('');
            setStartDate('');
            setEndDate('');
            setSessions([{ key: 0, from: '', to: '' }]);
        }
    }

    const selectYear = (val) => {
        setYear(val);
        setYearError('');
    }

    const selectMonth = (val) => {
        setMonth(val);
        setMonthError('');
    }
    const selectStartDate = (val) => {
        setStartDate(val);
        setStartDateError('');
    }
    const selectEndDate = (val) => {
        setEndDate(val);
        setEndDateError('');
    }

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newFields = [...session];
        newFields[index][name] = value;
        setSessions(newFields);
        setSessionError('');
    };


    const addInputField = () => {
        setSessions([...session, { key: session?.length, from: '', to: '' }]);
    };

    const removeInput = (idx) => {
        setSessions(session.filter((s, index) => idx !== index));
        session.forEach(el => {
            if (idx < el.key) {
                el.key = el.key - 1
            }
        })
    };

    const validateSessions = () => {
        let isValid = true;
        for (let i = 0; i < session.length; i++) {
            const fromTime = session[i].from;
            const toTime = session[i].to;


            // Check if from time is not greater than to time
            if (fromTime >= toTime) {
                isValid = false;
                break;
            }

            // Check if from and to times are not the same
            if (fromTime === toTime) {
                isValid = false;
                break;
            }

            // Check if two rows in array are not the same
            const duplicateSession = session.find(
                (session, index2) => i !== index2 && session.from === fromTime && session.to === toTime
            );
            if (duplicateSession) {
                isValid = false;
                break;
            }

            // Check if there's a 30-minute difference
            const fromIndex = time.indexOf(fromTime);
            const toIndex = time.indexOf(toTime);
            if (toIndex - fromIndex !== 1) {
                isValid = false;
                break;
            }
        }

        return isValid;
    };

    const addSlot = async () => {
        await validateSessions();
        if (year === '') {
            setYearError('Please select year');
        }
        else if (month === '') {
            setMonthError('Please select month');
        } else if (startDate === '') {
            setStartDateError('Please select start date');
        }
        else if (!edit && endDate === '') {
            setEndDateError('Please select end date');
        }
        else if (!edit && (Number(startDate?.value) === Number(endDate?.value))) {
            setDateError('Start date not equal to end date');
        }
        else if (!edit && (startDate?.value > endDate?.value)) {
            setDateError(`Start date can't less than to end date`);
        }
        else if (session.some(el => el.from === '') && session.some(el => el.to === '')) {
            setSessionError('Please select sessions');
        } else if (!validateSessions()) {
            setSessionError('Please select valid sessions with 30-minute intervals and no overlapping times.')
        }
        else {
            setIsLoading1(true);
            setDateError('');
            if (!edit) {
                const obj = {
                    year: year?.value,
                    month: month?.value,
                    dates: getValuesInRange(startDate?.value, endDate?.value),
                    sessions: session?.map(el => [{ from: el.from, to: el.to }][0])
                }
                const res = await dispatch(addSlots(obj));
                if (res?.type === 'ADD_SLOTS') {
                    setIsAddSlot(false);
                    setYear('');
                    setMonth('');
                    setStartDate('');
                    setEndDate('');
                    setStartDateError('');
                    setEndDateError('');
                    setYearError('');
                    dispatch(getSlotList());
                }
            } else {
                const obj = {
                    slot_id: slotId,
                    sessions: session?.map(el => [{ from: el.from, to: el.to }][0])
                }
                const res = await dispatch(editSlots(obj));
                if (res?.type === 'EDIT_SLOTS') {
                    const res = await dispatch(getSlotList());
                    if (res?.type === 'GET_SLOT_LIST') {
                        const slot = await res.payload.filter(el => { return el.month === month?.value })[0];
                        setDetails(slot);
                    }
                    setIsAddSlot(false);
                    setYear('');
                    setStartDate('');
                    setEndDate('');
                    setStartDateError('');
                    setEndDateError('');
                    setYearError('');

                }
            }

            setIsLoading1(false);
        }
    }

    useEffect(() => {
        if (!edit) {
            const data = slots?.filter(rl => rl.month === details?.month)[0]
            setDetails(data)
        }
    }, [slots])

    const handleChange = async (el, month) => {
        await dispatch(changeSlotStatus({ ...el, month: month }));
    }

    const openDelete = (el) => {
        setDeleteId(el._id);
        setIsConfirm(true);
    }

    const confirmDelete = async (id) => {
        let obj = {
            slot_id: id
        }
        const res = await dispatch(confirmDeleteSlot(obj));
        if (res?.type === 'DELETE_SLOT') {
            setIsConfirm(false);
            dispatch(getSlotList());
        }
    }

    const openDetails = (el) => {
        setIsDetails(true);
        setDetails(el);
    }

    const slotClick = (sessionId, slotId) => {
        setSessionId(sessionId);
        setSlotId(slotId);
    }

    const bookSession = async () => {
        if (sessionId === '') {
            setSlotError('Please select slot');
        } else if (slotId === '') {
            setSlotError('Please select slot')
        } else {
            let obj = {
                slot_id: slotId,
                session_id: sessionId
            }
            await dispatch(bookSlots(obj));
            await getBookings();
            await setSlotId('');
            await setSessionId('');
            await setValue(new Date())
            setIsSchedule(false);
        }

    }


    const onDateChange = async (e) => {
        setValue(e);
        const date = new Date(e).getDate();
        const month = new Date(e).toLocaleString('default', { month: 'long' });
        const year = new Date(e).getFullYear();
        await dispatch(getBookingSlots(date, month, year));

    }

    const onTabChange = (e) => {
        setActive(e);
    }


    const takeTest = () => {
        setTest(true);
        goFullScreen();
    }




    const closeFullScreen = async () => {
        await setTest(false);
        await setIsReport(true);
        await exitFullscreen();
        await dispatch(resetReport());
        await dispatch(getReport());
    }

    const back = async () => {
        await setIsReport(false);
    }

    return (
        <Fragment>

            {
                localStorage.getItem('role')?.includes('admin') ?
                    <div className='counselling-holder'>
                        {!isDetails ?
                            <div className='bookings'>
                                <div className='d-flex justify-content-between align-items-center flex-wrap'>
                                    <ul className='booking-tabs'>
                                        <li className={active === 'schedule' ? 'active' : ''} onClick={() => onTabChange('schedule')}>{t('Schedules')}</li>
                                        <li className={active === 'dates' ? 'active' : ''} onClick={() => onTabChange('dates')}>{t('Scheduled Counselling dates')}</li>
                                    </ul>
                                    {
                                        active === 'dates' &&
                                        <button className='float-right' onClick={() => openAdd(null, false, '')} style={{ marginTop: 15 }}>Add slots</button>
                                    }
                                </div>
                                <div style={{ borderBottom: '0.5px solid #ACACAC', marginTop: -2 }}></div>
                                {
                                    (active === 'schedule' ?

                                        (isLoading ?
                                            <TopicLoader />
                                            :
                                            allBookings && allBookings?.length > 0 ?
                                                <div className='report-table'>
                                                    <Table responsive>
                                                        <thead>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                            <th>Date</th>
                                                            {/* <th>Status</th> */}
                                                            {/* <th>Action</th> */}
                                                        </thead>
                                                        <tbody className="table-body">
                                                            {
                                                                allBookings && allBookings?.map(el => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{el.name}</td>
                                                                            <td>{el.email}</td>
                                                                            <td >
                                                                                {el.date}-{el.month.split('').slice(0, 3)}-{el.year}
                                                                            </td>
                                                                            <td >
                                                                                {/* <img src='/images/edit.svg' alt='' className='cursor-pointer' onClick={() => openAdd(el, true)} /> */}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                :
                                                <div className='empty-list'>
                                                    <img src='/images/no-saved.svg' alt='' />
                                                    <p>{t('There are no slots')} </p>
                                                </div>)
                                        :

                                        (isLoading ? <TopicLoader />
                                            : slots && slots?.length > 0 ?
                                                <div className='report-table'>
                                                    <Table responsive>
                                                        <thead>
                                                            <th>Year</th>
                                                            <th>Month</th>
                                                            <th>Date</th>
                                                            {/* <th>Status</th> */}
                                                            <th>Action</th>
                                                        </thead>
                                                        <tbody className="table-body">
                                                            {
                                                                slots && slots?.map(el => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{el.year}</td>
                                                                            <td>{el.month}</td>
                                                                            <td >
                                                                                {el.slots.map(el1 => el1.date).join(', ')}
                                                                            </td>

                                                                            <td >
                                                                                <img src='/images/view_grey.svg' alt='' className='cursor-pointer' onClick={() => openDetails(el)} />
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                :
                                                <div className='empty-list'>
                                                    <img src='/images/no-saved.svg' alt='' />
                                                    <p>{t('There are no slots')} </p>
                                                </div>))

                                }
                            </div> :
                            <div>
                                <div style={{ width: '55%' }} className='d-flex justify-content-between'>
                                    <img src='/images/back.svg' className='cursor-pointer' alt='' onClick={() => setIsDetails(false)} />
                                    Slot Details
                                </div>
                                <div className='report-table'>
                                    <Table responsive>
                                        <thead>
                                            <th>Date</th>

                                            <th>Status</th>
                                            <th>Action</th>
                                        </thead>
                                        <tbody className="table-body">
                                            {
                                                details?.slots && details?.slots?.map(el => {
                                                    return (
                                                        <tr>
                                                            <td>{el.date}-{details?.month.split('').slice(0, 3)}-{details?.year}</td>
                                                            <td >
                                                                <div className='d-flex justify-content-center align-items-center'
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        e.stopPropagation()
                                                                        handleChange(el, details?.month)
                                                                    }}> <Switch
                                                                        onChange={(e) => { console.log(e) }}
                                                                        width={30}
                                                                        height={16}
                                                                        uncheckedIcon={false}
                                                                        checkedIcon={false}
                                                                        onColor='#00B24B'
                                                                        checked={el.active} />&nbsp;
                                                                    {el.active ? 'Active' : 'Inactive'}
                                                                </div>
                                                            </td>
                                                            <td  >
                                                                <div className='d-flex justify-content-center align-items-center'>
                                                                    <img src='/images/edit.svg' alt='' className='cursor-pointer' onClick={() => openAdd(details, true, el)} />&nbsp;&nbsp;
                                                                    {!el.active && <img src='/images/delete.svg' alt='' className='cursor-pointer'
                                                                        onClick={() => openDelete(el)}
                                                                    />}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        }
                    </div>
                    :
                    !isReport ?
                        <div className='counselling-holder'>
                            <Fragment>
                                <div className='counselling-introduction'>
                                    <div className='introduction'>
                                        <h3>{t('Get some career options by getting counselling from experts')}</h3>
                                        {window.innerWidth > 767 &&
                                            <p>{t('Take the first step towards a fulfilling career by exploring your strengths, interests, and skills. Our easy-to-follow assessment will provide you with')}</p>}
                                        <div className='counselling-btn'>
                                            <button onClick={() => {
                                                setIsSchedule(true)
                                                onDateChange(new Date())
                                            }}>{t('Book a session')}</button>
                                            {window.innerWidth > 767 &&
                                                <button onClick={() => takeTest()}>{t('Take a self assessment test')}</button>}

                                        </div>
                                    </div>
                                    <div className='counselling-img' >
                                        {window.innerWidth > 767 ?
                                            <img src='/images/counselling.svg' alt='' /> :
                                            <img src='/images/coun-img4.svg' alt='' />
                                        }
                                    </div>
                                </div>
                                <div className='how-to-works'>
                                    <h4>{t('How Does it works ?')}</h4>
                                    <div className={window.innerWidth > 767 ? 'row' : 'row flex-nowrap overflow-auto'}>
                                        <div className='col-md-4'>
                                            <img src='/images/coun-img1.svg' alt='' />
                                            <h5>{t('Take a self assessment test')}</h5>
                                            <p>{t('Take a test and get insights of your strengths through our detailed report')}</p>
                                        </div>
                                        <div className='col-md-4'>
                                            <img src='/images/coun-img2.svg' alt='' />
                                            <h5>{t('Book a counselling session')}</h5>
                                            <p>{t('Empower your professional journey by booking a personalized career counseling session')}</p>
                                        </div>
                                        <div className='col-md-4'>
                                            <img src='/images/coun-img3.svg' alt='' />
                                            <h5>{t('Get guidance from experts')}</h5>
                                            <p>{t('Unlock tailored guidance to help you navigate the complex landscape of career choices,')}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='why-counselling'>
                                    <div className='introduction'>
                                        <p>{t('Why Counselling?')}</p>
                                        <p style={{ color: '#272727', fontWeight: 400, lineHeight: 1.5 }}>{t('Take the first step towards a fulfilling career by exploring your strengths, interests, and skills. Our')} </p>
                                        <div className='counselling-points'>
                                            <div className='d-flex align-items-center'>
                                                <img src='/images/tick.svg' alt='' />
                                                <h6>
                                                    {t('Gain Insights for smart career choices.')}
                                                </h6>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <img src='/images/tick.svg' alt='' />
                                                <h6>
                                                    {t('Explore a wide range of career options.')}
                                                </h6>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <img src='/images/tick.svg' alt='' />
                                                <h6>
                                                    {t('Get guidance for optimal academic stream.')}
                                                </h6>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <img src='/images/tick.svg' alt='' />
                                                <h6>
                                                    {t('Align skills, passions for career success.')}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    {window.innerWidth > 767 &&
                                        <div className='counselling-img' >
                                            <img src='/images/coun-img1.svg' alt='' width={514} height={230} />
                                        </div>}
                                </div>
                                {window.innerWidth > 767 ?
                                    null :
                                    <div className='self-assessment'>
                                        <h4>{t('Take a  self assement test')}</h4>
                                        <button>{t('Start Test')}</button>
                                    </div>
                                }
                            </Fragment>

                            {bookingList && bookingList?.length > 0 ?
                                <div className='bookings mt-2'>
                                    {/* <div className="bc-nav ml-2 mt-2" >
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"> <img src='/images/home.svg' alt='' /></li>
                                    <li className="breadcrumb-item cursor-pointer" onClick={() => setIsBookPage(false)} >{t('Counselling')}</li>
                                    <li className="breadcrumb-item  b-active">{t('My bookings')}</li>
                                </ol>
                            </nav>
                        </div> */}
                                    <h3>{t('My bookings')}</h3>
                                    {/* <ul className='booking-tabs'>
                                    <li className={active === 'upcoming' ? 'active' : ''} onClick={() => onTabChange('upcoming')}>{t('Upcoming Sessions')}</li>
                                    <li className={active === 'past' ? 'active' : ''} onClick={() => onTabChange('past')}>{t('Past Sessions')}</li>
                                </ul> */}
                                    {/* <div style={{ borderBottom: '0.5px solid #ACACAC', marginTop: -2 }}></div> */}
                                    <div className='booking-list'>
                                        {
                                            bookingList && bookingList.map(el => {
                                                return (
                                                    <div className='booking-item'>
                                                        <div style={{ width: '20%' }}>
                                                            <h4 style={{ fontWeight: 700 }}>{t('Career Counselling Session')}
                                                            </h4>
                                                        </div>
                                                        <div>
                                                            <h4 style={{ fontWeight: 700 }}>{t('Date')}</h4>
                                                            <h4>{el.date} {el.month} {el.year}</h4>
                                                        </div>
                                                        <div>
                                                            <h4 style={{ fontWeight: 700 }}>{t('Time')}</h4>
                                                            <h4>{el.session[0].from}</h4>
                                                        </div>
                                                        {/* <div>
                                                            <p>{t('View')}</p>
                                                        </div> */}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div> :
                                null
                            }
                            <div className="self-assessment-test-view" style={{ zIndex: window.innerWidth > 767 ? 8 : 9, display: test ? "block" : "none", top: 0 }}>
                                <SelfTest
                                    test={test}
                                    id={testTypes?._id}
                                    closeFullScreen={closeFullScreen}
                                />
                            </div>

                            <Modal
                                show={isSchedule}
                                size="md"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                dialogClassName={"schedule-modal"}
                            >
                                <div className="modal-header">
                                    <h5 className="modal-title">{t('Book a counselling session')}</h5>

                                    <div className="sticky-close" onClick={() => setIsSchedule(false)}>
                                        <img src="/images/close.svg" width='10px' alt='' /></div>

                                </div>
                                <Modal.Body >
                                    <div className='date-modal text-center'>
                                        <Calendar
                                            onChange={(e) => onDateChange(e)}
                                            value={value}
                                            minDate={new Date()}
                                            // calendarType='US'
                                            formatShortWeekday={(locale, value) => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][value.getDay()]}
                                        />
                                    </div>
                                    <div style={{ paddingLeft: 15 }}>
                                        <h6>{t('Available time slots')}</h6>
                                        <div className='time-slots'>
                                            {bookingSlots?.sessions?.length > 0 ?
                                                bookingSlots && bookingSlots.sessions?.map(el => {
                                                    return (
                                                        <div className={el._id === sessionId ? 'slots active' : 'slots'} style={{ background: el.booked ? 'grey' : '', pointerEvents: el.booked ? 'none' : 'auto' }} onClick={() => slotClick(el._id, bookingSlots?._id)}>{el.from} - {el.to}</div>
                                                    )
                                                }) :
                                                <p className='text-center mt-2'>No Slots Available</p>

                                            }
                                        </div>
                                    </div>


                                </Modal.Body>
                                <Modal.Footer>
                                    <div className='d-flex justify-content-end'>
                                        <button onClick={async () => {
                                            setIsSchedule(false)
                                            await setSlotId('');
                                            await setSessionId('');
                                            await setValue(new Date())
                                        }}>{t('Cancel')}</button>
                                        <button onClick={bookSession}>{t('Book session')}</button>
                                    </div>
                                </Modal.Footer>
                            </Modal>
                        </div>
                        :
                        <TestReport
                            loading={loading}
                            id={null}
                            back={back}
                        />
            }

            {/* //edit modal */}
            <Modal
                show={isAddSlot}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"edit-schedule-modal"}
            >

                <div class="modal-header" style={{ background: '#1D8BCB' }}>
                    <h5 class="modal-title ">{edit ? 'Edit Schedule' : 'Add Schedule'}</h5>

                    <div className="sticky-close" onClick={() => setIsAddSlot(false)}>
                        <img src="/images/close-white.svg" width='15px' alt='' /></div>

                </div>
                <Modal.Body style={{ padding: '22px 35px 22px 35px', }}>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='mt-2'>
                                <label>Year
                                </label>
                                <div className='mt-2'>
                                    <Select
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                        // styles={customStyles}
                                        // components={{ DropdownIndicator }}
                                        options={years}
                                        placeholder='Select Year'
                                        onChange={selectYear}
                                        value={year}
                                        isDisabled={edit}
                                    />
                                </div>
                            </div>
                            <small>{yearError}</small>
                        </div>
                        <div className='col-md-6 pl-0'>
                            <div className='mt-2'>
                                <label>Month
                                </label>
                                <div className='mt-2'>
                                    <Select
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                        // styles={customStyles}
                                        // components={{ DropdownIndicator }}
                                        options={months?.map(el => [{ label: el, value: el }][0])}
                                        placeholder='Select Month'
                                        onChange={selectMonth}
                                        value={month}
                                        isDisabled={edit}
                                    />
                                </div>
                            </div>
                            <small>{monthError}</small>
                        </div>
                    </div>

                    {!edit ?
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='mt-2'>
                                    <label>Start Date
                                    </label>
                                    <div className='mt-2'>
                                        <Select
                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            // styles={customStyles}
                                            // components={{ DropdownIndicator }}
                                            options={getDatesInMonth(new Date().getFullYear(), new Date().getMonth() + 1)}
                                            placeholder='Select Day'
                                            onChange={selectStartDate}
                                            value={startDate}
                                            isDisabled={edit}
                                        />
                                    </div>
                                </div>
                                <small>{startDateError}</small>
                            </div>
                            <div className='col-md-6 pl-0'>
                                <div className='mt-2'>
                                    <label>End Date
                                    </label>
                                    <div className='mt-2'>
                                        <Select
                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            // styles={customStyles}
                                            // components={{ DropdownIndicator }}
                                            options={getDatesInMonth(new Date().getFullYear(), new Date().getMonth() + 1)}
                                            placeholder='Select Day'
                                            onChange={selectEndDate}
                                            value={endDate}
                                            isDisabled={edit}
                                        />
                                    </div>
                                </div>
                                <small>{endDateError}</small>
                            </div>
                        </div>
                        :
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='mt-2'>
                                    <label>Date
                                    </label>
                                    <div className='mt-2'>
                                        <Select
                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            // styles={customStyles}
                                            // components={{ DropdownIndicator }}
                                            options={getDatesInMonth(new Date().getFullYear(), new Date().getMonth() + 1)}
                                            placeholder='Select Day'
                                            onChange={selectStartDate}
                                            value={startDate}
                                            isDisabled={edit}
                                        />
                                    </div>
                                </div>
                                <small>{startDateError}</small>
                            </div>
                        </div>
                    }
                    <small>{dateError}</small>
                    <div className='row'>
                        <div className='mt-2' >
                            <label className='mb-2'>Session</label>
                            {session && session.map((field, index) => (
                                <div className='row' key={index}>
                                    <div className='col-md-6 position-relative'>
                                        <div className='form-group'>
                                            <select name='from' className='form-control' value={field.from} onChange={(e) => handleInputChange(index, e)}>
                                                <option hidden>Select</option>
                                                {
                                                    time && time.map(el => {
                                                        return (
                                                            <option value={el}>{el}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-md-6 position-relative'>
                                        <div className='form-group'>
                                            <select name='to' className='form-control' value={field.to} onChange={(e) => handleInputChange(index, e)}>
                                                <option hidden>Select</option>
                                                {
                                                    time && time.map(el => {
                                                        return (
                                                            <option value={el}>{el}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {
                                            index === 0 ? null : <button
                                                type="button"
                                                onClick={() => removeInput(index)}
                                                className="remove-btn"
                                            ><img src="/images/delete.svg" alt='' /></button>
                                        }
                                    </div>
                                </div>
                            ))}
                            <button className='add-btn text-left'
                                disabled={(session[session?.length - 1].from === '') || (session[session?.length - 1].to === '')}
                                onClick={addInputField}>Add
                            </button>
                        </div>
                    </div>
                    <small>{sessionError}</small>

                    <div className='save-btn'>
                        <button disabled={isLoading1} onClick={addSlot} >
                            {isLoading1 ? <div className='ballbeat'><BallBeat color="#fff" /></div> : 'Save'}</button>
                        <button onClick={() => {
                            setIsAddSlot(false)
                            setIsLoading1(false)
                        }}>Cancel</button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* ################## Confirm Delete modal ############################ */}
            <ConfirmModal
                isConfirm={isConfirm}
                confirmDelete={() => { confirmDelete(deleteId) }}
                closeModal={() => setIsConfirm(false)}
                type='Slot'
            />
        </Fragment>
    )
}
