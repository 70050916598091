import { GET_MY_CONTENT, GET_NEXT_MY_CONTENT, CHANGE_CONTENT_STATUS, SEARCH_MY_CONTENT, Add_CONTENT, EDIT_CONTENT, GET_CONTENT_STATE, ADD_QUESTION_MY_CONTENT, GET_QUESTION_DATA, ADD_RESOURCE_TO_ATTACHMENT } from "../Types/types";

let intialState = {
    isResourceLoading: true,
    contents: [],
    documentContentState: false,
    isLoading: true,
    isShareFeedPopup: false
}

export default function myContentReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_MY_CONTENT:
            stateCopy.contents = action.payload.map(item => ({ ...item, add: false }));
            stateCopy.isResourceLoading = false;
            stateCopy.isLoading = false;
            return stateCopy;
        case GET_NEXT_MY_CONTENT:
            if (action.payload.length > 0) {
                stateCopy.contents = stateCopy.contents.concat(action.payload.map(item => ({ ...item, add: false })));
                stateCopy.isLoading = false;
            }
            else {
                stateCopy.isLoading = false;
            }

            return stateCopy;
        case CHANGE_CONTENT_STATUS:
            stateCopy.contents.forEach((answer) => {
                if (answer._id === action.payload.id) {
                    answer.active = !action.payload.checked;
                }
            });
            return stateCopy;
        case SEARCH_MY_CONTENT:
            stateCopy.contents = action.payload;
            stateCopy.isResourceLoading = false;
            stateCopy.isLoading = false;
            return stateCopy;
        case Add_CONTENT:
            stateCopy.contents = [action.payload.map(item => ({ ...item, add: false })), ...stateCopy.contents];
            stateCopy.isResourceLoading = false;
            return stateCopy;
        case EDIT_CONTENT:
            stateCopy.documentContentState = false;
            return stateCopy;
        case GET_CONTENT_STATE:
            stateCopy.documentContentState = action.payload;
            return stateCopy

        case ADD_QUESTION_MY_CONTENT:
            stateCopy.contents = [action.payload.map(item => ({ ...item, add: false })), ...stateCopy.contents];
            stateCopy.isResourceLoading = false;
            return stateCopy;
        case GET_QUESTION_DATA:
            stateCopy.questionData = action.payload;
            return stateCopy;
        case ADD_RESOURCE_TO_ATTACHMENT:
            stateCopy.contents.forEach(answer => {
                if (answer._id === action.payload) {
                    answer.add = true
                }
            })
            return stateCopy;
        default:
            return stateCopy;

    }
}