import React, { Fragment, useEffect, useState } from 'react';
import '../contentLibrary.scss';
import { useNavigate, Link } from 'react-router-dom';
import { BubbleLoader } from '../../../Loader/loader';
import { useSelector, useDispatch } from 'react-redux';
import { resetClassrooms, getOtherSkills, getSearchResults, getBookmarkedSkill, bookmarksSkill } from '../../../Redux/ActionCreators/contentLibraryAction';
import { useTranslation } from 'react-i18next';
import { showSideBar } from '../../../Redux/ActionCreators/manageContentAction';


export default function Skills() {
    const [search, setSearch] = useState('');
    const [active, setActive] = useState('all')

    const navigate = useNavigate();
    const { isSkillLoading, otherSkills, bookmarkedSkill, } = useSelector(state => state.contentLibrary);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        const firstRender = async () => {
            await dispatch(showSideBar());
            await dispatch(getOtherSkills());
        }
        firstRender();
    }, [])

    const onTabChange = async (e) => {
        setActive(e);
        if (e === 'all') {
            await dispatch(resetClassrooms());
            await dispatch(getOtherSkills());
        } else {
            await dispatch(getBookmarkedSkill());
        }
    }


    const timerRef1 = React.useRef(null);

    const getQuery = (val) => {
        clearTimeout(timerRef1.current);
        setSearch(val);
        timerRef1.current = setTimeout(() => delaySearch(val), 800);
    }


    const delaySearch = async (val) => {
        await dispatch(getSearchResults(val));
    }


    const goBack = () => {
        navigate(-1);
    }


    const bookmarkSkill = async (bookmark, id) => {
        await dispatch(bookmarksSkill(bookmark, id));
        await dispatch(getOtherSkills());
    }


    const removeBookmark = async (bookmark, id) => {
        await dispatch(bookmarksSkill(bookmark, id));
        if (bookmark) {
            await onTabChange('saved');
        }
    }


    return (
        <div className='skillmap-holder'>
            <div className='d-flex flex-wrap justify-content-between align-items-center' >
                <h3>Explore skills</h3>

            </div>
            <div className='d-flex justify-content-between align-items-center flex-wrap'>
                <ul className='skill-tabs'>
                    <li className={active === 'all' ? 'active' : ''} onClick={() => onTabChange('all')}>All Skills</li>
                    <li className={active === 'saved' ? 'active' : ''} onClick={() => onTabChange('saved')}>Saved</li>
                </ul>
                {active === 'all' &&
                    <div className="has-search"  >
                        <span className="form-control-feedback" >
                            <img src="/images/search.svg" alt='' />
                        </span>
                        <input type="search" className="form-control" value={search} placeholder={t("Search skill")} onChange={(e) => getQuery(e.target.value)} />
                    </div>}
            </div>
            <div style={{ borderBottom: '0.5px solid #ACACAC', marginTop: -2 }}>

            </div>
            {
                active === 'all' ?
                    (
                        <div className='subject-holder' >
                            {
                                !otherSkills?.length ?
                                    <BubbleLoader />
                                    :
                                    otherSkills?.length === 0 ?
                                        <div className="empty-list">
                                            <img src="/images/empty.png" alt="" />
                                            <p>There is not added any Skills yet.</p>
                                        </div>
                                        :
                                        <Fragment>
                                            <div className=' mt-2'>
                                                <div className={window.innerWidth > 767 ? 'd-flex flex-wrap' : 'row flex-nowrap overflow-auto mr-2'}>
                                                    {
                                                        window.innerWidth > 767 ?

                                                            isSkillLoading ?
                                                                <BubbleLoader /> :
                                                                otherSkills?.map((el, index) => {
                                                                    return (
                                                                        <div class="card" key={el._id} style={{ width: '18.5%', marginRight: 10 }} >
                                                                            <Link to={`/content-library/chapters`}
                                                                                state={{
                                                                                    id: el._id,
                                                                                    img: el.image,
                                                                                    type: 'main',
                                                                                    title: el.title,
                                                                                    detail: el,
                                                                                    content: true
                                                                                }} >
                                                                                <img class="card-img-top" src={el.image} alt="Card" height='118px' loading='lazy'
                                                                                    onError={(e) => {
                                                                                        if (
                                                                                            e.target.src !==
                                                                                            "https://elite-skill-images.s3.ap-south-1.amazonaws.com/Competitive+Intelligence.png"
                                                                                        ) {
                                                                                            e.target.onerror =
                                                                                                "https://elite-skill-images.s3.ap-south-1.amazonaws.com/Competitive+Intelligence.png";
                                                                                            e.target.src =
                                                                                                "https://elite-skill-images.s3.ap-south-1.amazonaws.com/Competitive+Intelligence.png";
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                <div className='card-body p-0'>
                                                                                    <div style={{ padding: '5px 7px' }}>
                                                                                        <div className='d-flex justify-content-between flex-column' >
                                                                                            <h5>
                                                                                                {el.title}
                                                                                            </h5>
                                                                                            <p><img src='/images/chapter.svg' alt='' /> &nbsp;&nbsp; {el.total} {t('Items')}</p>

                                                                                            <div style={{ borderTop: '1px solid #ddd', marginTop: 0 }}></div>
                                                                                            <h4>{t('Start learning')}
                                                                                                {
                                                                                                    el.bookmarked ?
                                                                                                        <img src='/images/saved.svg' alt='' width='18px' height='20px' onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            e.stopPropagation();
                                                                                                            bookmarkSkill(el.bookmarked, el._id);
                                                                                                        }} />
                                                                                                        : <img src='/images/save.svg' alt='' width='18px' height='20px' onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            e.stopPropagation();
                                                                                                            bookmarkSkill(el.bookmarked, el._id);
                                                                                                        }} />
                                                                                                }
                                                                                            </h4>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    )
                                                                })
                                                            :
                                                            otherSkills.map((el) => {
                                                                return (
                                                                    <div class="card" key={el._id} style={{ minWidth: '180px', width: 180, marginRight: 10 }} >
                                                                        <Link to={`/content-library/chapters`}
                                                                            state={{
                                                                                id: el._id,
                                                                                img: el.image,
                                                                                type: 'main',
                                                                                title: el.title,
                                                                                detail: el,
                                                                                content: true
                                                                            }} >
                                                                            <img class="card-img-top" src={el.image} alt="Card" height='118px' loading='lazy'
                                                                                onError={(e) => {
                                                                                    if (
                                                                                        e.target.src !==
                                                                                        "https://elite-skill-images.s3.ap-south-1.amazonaws.com/Competitive+Intelligence.png"
                                                                                    ) {
                                                                                        e.target.onerror =
                                                                                            "https://elite-skill-images.s3.ap-south-1.amazonaws.com/Competitive+Intelligence.png";
                                                                                        e.target.src =
                                                                                            "https://elite-skill-images.s3.ap-south-1.amazonaws.com/Competitive+Intelligence.png";
                                                                                    }
                                                                                }} />
                                                                            <div className='card-body p-0'>
                                                                                <div style={{ padding: '5px 7px' }}>
                                                                                    <div className='d-flex justify-content-between flex-column' >
                                                                                        <h5>
                                                                                            {el.title}
                                                                                        </h5>
                                                                                        <p><img src='/images/chapter.svg' alt='' /> &nbsp;&nbsp; {el.total} {t('Items')}</p>

                                                                                        <div style={{ borderTop: '1px solid #ddd', marginTop: 0 }}></div>
                                                                                        <h4>{t('Start learning')}
                                                                                            {
                                                                                                el.bookmarked ?
                                                                                                    <img src='/images/saved.svg' alt='' width='18px' height='20px' onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        e.stopPropagation();
                                                                                                        bookmarkSkill(el.bookmarked, el._id);
                                                                                                    }} />
                                                                                                    : <img src='/images/save.svg' alt='' width='18px' height='20px' onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        e.stopPropagation();
                                                                                                        bookmarkSkill(el.bookmarked, el._id);
                                                                                                    }} />
                                                                                            }
                                                                                        </h4>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                )
                                                            })
                                                    }
                                                </div>
                                            </div>

                                        </Fragment>

                            }
                        </div>
                    ) :

                    isSkillLoading ?
                        <BubbleLoader /> :
                        (bookmarkedSkill?.length > 0) ?
                            <div className='explore-skills'>
                                <h4>{t('Bookmarked')}</h4>
                                {
                                    window.innerWidth > 767 ?
                                        <div className='row'>

                                            {
                                                bookmarkedSkill && bookmarkedSkill?.map((el) => {
                                                    return (
                                                        <div className="card mt-2" style={{ width: '18.9%', marginRight: 10 }} key={el._id}>
                                                            <Link to={`/content-library/chapters`}
                                                                state={{
                                                                    id: el._id,
                                                                    img: el.image,
                                                                    type: 'main',
                                                                    title: el.title,
                                                                    detail: el,
                                                                    content: true
                                                                }} >
                                                                <img className="card-img-top" src={el.image} alt="Card" onError={(e) => {
                                                                    if (
                                                                        e.target.src !==
                                                                        "https://elite-skill-images.s3.ap-south-1.amazonaws.com/Competitive+Intelligence.png"
                                                                    ) {
                                                                        e.target.onerror =
                                                                            "https://elite-skill-images.s3.ap-south-1.amazonaws.com/Competitive+Intelligence.png";
                                                                        e.target.src =
                                                                            "https://elite-skill-images.s3.ap-south-1.amazonaws.com/Competitive+Intelligence.png";
                                                                    }
                                                                }}
                                                                    loading='lazy' />
                                                                <div className="card-body p-0">
                                                                    <div style={{ padding: '0px 7px' }}>
                                                                        <div className='d-flex justify-content-between flex-column' >
                                                                            <h5>
                                                                                {el.title}
                                                                            </h5>
                                                                            <p><img src='/images/chapter.svg' alt='' /> &nbsp;&nbsp; {el.total} {t('Items')}</p>

                                                                            <div style={{ borderTop: '1px solid #ddd', marginTop: 0 }}></div>
                                                                            <h4>{t('Start learning')}

                                                                                <img src='/images/saved.svg' alt='' width='18px' height='20px' onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    e.stopPropagation();
                                                                                    removeBookmark(true, el._id);
                                                                                }} />


                                                                            </h4>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                        :
                                        <div className='row flex-nowrap ml-0 mr-1' style={{ overflowY: 'hidden', overflowX: 'auto' }}>
                                            {
                                                bookmarkedSkill?.map((el) => {
                                                    return (
                                                        <div key={el._id} className="card mt-2" style={{ minWidth: '180px', width: 180, marginRight: 10 }}>
                                                            <Link to={`/content-library/chapters`}
                                                                state={{
                                                                    id: el._id,
                                                                    img: el.image,
                                                                    type: 'main',
                                                                    title: el.title,
                                                                    detail: el,
                                                                    content: true
                                                                }} >
                                                                <img className="card-img-top" src={el.image} alt="Card" />
                                                                <div className="card-body p-0">
                                                                    <div style={{ padding: '0px 7px' }}>
                                                                        <div className='d-flex justify-content-between flex-column' >
                                                                            <h5>
                                                                                {el.title}
                                                                            </h5>
                                                                            <p><img src='/images/chapter.svg' alt='' /> &nbsp;&nbsp;  {el.total} {t('Items')}</p>

                                                                            <div style={{ borderTop: '1px solid #ddd', marginTop: 0 }}></div>
                                                                            <h4>{t('Start learning')}

                                                                                <img src='/images/saved.svg' alt='' width='18px' height='20px' onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    e.stopPropagation();
                                                                                    removeBookmark(true, el._id);
                                                                                }} />


                                                                            </h4>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                }
                            </div>
                            :
                            <div className='empty-list'>
                                <img src='/images/no-saved.svg' alt='' />
                                <p>{t('There are no skills saved')} </p>
                            </div>
            }
        </div>
    )
}
