import { RESET_LOADING, GET_MENTOR_LIST, GET_STUDENT_CONVERSATION_LIST, SUBJECT_FILTER, GET_FACULTY_SUBJECTS, RESET_FACULTY_SUBJECTS, ADD_QUERY, GET_QUESTION_DETAIL, CLOSE_QUESTION_DETAIL, REPLY_SENT } from "../Types/types";


let intialState = {
    activeTab: 'mentor',
    isMentorLoading: true,
    mentorList: [],
    attachment: [],
    schools: [
        {
            "value": "61335855acf0f95ea60e9267",
            "label": "Vellore (VTC)"
        },
        {
            "value": "615fa9a928469b774d8098ad",
            "label": "Tellicherry (TTC)"
        },
        {
            "value": "6176bd899cd7c31e095ed6b0",
            "label": "Belagavi (BSTC)"
        },
        {
            "value": "613355d5a4d07b5e5ace6034",
            "label": "Bangalore Peenya (BTC)"
        },
        {
            "value": "615fa9073518417769fd2930",
            "label": "Malappuram (INTC)"
        },
        {
            "value": "615fa88628469b774d8098aa",
            "label": "Coimbatore (GNTC)"
        },
        {
            "value": "61335657e11e2f5e9098bfc2",
            "label": "Jamshedpur (RNTC)"
        },
        {
            "value": "613355a6e11e2f5e9098bfc1",
            "label": "Bangalore Electronic City (NEC)"
        },
        {
            "value": "615fa97d28469b774d8098ac",
            "label": "Kumbakonam (KTC)"
        },
        {
            "value": "618a619184cd1606dea72969",
            "label": "Trichy (TYTC)"
        },
        {
            "value": "615fa80c28469b774d8098a9",
            "label": "Dharwad (DTC)"
        },
        {
            "value": "615fa94228469b774d8098ab",
            "label": "Gopalpur Odisha (JNTC)"
        }
    ],
    catagories: [{ value: 'Subject Related', label: 'Subject Related' }, { value: 'Exam', label: 'Exam Related' }, { value: 'Assignment', label: 'Assignment Related' }, { value: 'Others', label: 'Others' }],
    viewDetails: false,
    conversationList: []
}


export default function mentorConnectReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_MENTOR_LIST:
            stateCopy.mentorList = action.payload;
            stateCopy.selectFacultyOptions = action.payload.map(el => [{ ...el, value: el._id, label: el.name }][0]);
            stateCopy.isMentorLoading = false;
            return stateCopy;
        case GET_STUDENT_CONVERSATION_LIST:
            stateCopy.conversationList = action.payload;
            stateCopy.isMentorLoading = false;
            return stateCopy;
        case SUBJECT_FILTER:
            stateCopy.filterSubjects = action.payload.map(el => [{ label: el.name, value: el._id }][0]);
            return stateCopy;
        case RESET_LOADING:
            stateCopy.isMentorLoading = true;
            return stateCopy;
        case GET_FACULTY_SUBJECTS:
            stateCopy.facultySubjects = stateCopy.mentorList?.filter(el => el._id === action.payload)[0].subjects;
            return stateCopy;
        case RESET_FACULTY_SUBJECTS:
            stateCopy.facultySubjects = [];
            return stateCopy;
        case ADD_QUERY:
            stateCopy.activeTab = 'conversation';
            stateCopy.attachment = [];
            return stateCopy;
        case GET_QUESTION_DETAIL:
            stateCopy.viewDetails = true;
            stateCopy.conversation = action.payload.data?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
            stateCopy.question = action.payload.detail;
            return stateCopy;
        case REPLY_SENT:
            if (localStorage.getItem('role') === 'student') {
                stateCopy.conversationList.forEach(el => {
                    if (el._id === action.payload._id) {
                        el.new = false;
                    }
                })
            }
            else {
                stateCopy.facultyQuestionList.forEach(el => {
                    if (el._id === action.payload._id) {
                        el.new = false;
                    }
                })
            }
            return stateCopy;
        case CLOSE_QUESTION_DETAIL:
            stateCopy.viewDetails = false;
            stateCopy.question = null;
            stateCopy.conversation = null;
            stateCopy.attachment = [];
            return stateCopy;
        default:
            return stateCopy;
    }
}