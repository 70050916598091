import moment from 'moment'
import { GET_NOTES_LIST, GET_fILTERS, SUBJECT_FILTER_CLICK, DATE_FILTER_CLICK, CLEAR_FILTER, GET_NOTES, SET_NOTE, SAVE_NOTES, GET_LIKED } from '../Types/types';
let intialState = {
    isLoading: true,
    isNote: false,
    subjectFilter: [],
    dateFilter: [{ name: 'Last 3 months', code: 3, value: false }, { name: 'Last 6 months', code: 6, value: false }, { name: 'Last year', code: 12, value: false }],
    fromDate: '',
    toDate: '',
    programSelected: [],
    subjectSelected: []
}

export default function notesReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_NOTES_LIST:
            stateCopy.notes = action.payload.data;
            stateCopy.notesPerPage = action.payload.per_page;
            stateCopy.notesTotalPage = action.payload.total;
            stateCopy.pageNumber = 1;
            stateCopy.isLoading = false;
            return stateCopy;
        case GET_fILTERS:
            stateCopy.subjectFilter = action.payload.subjects;
            return stateCopy;
        case SUBJECT_FILTER_CLICK:

            stateCopy.subjectFilter = stateCopy.subjectFilter.map(answer => {
                if (answer._id === action.payload.id) return { _id: answer._id, value: !action.payload.value, name: answer.name }
                return answer
            });
            stateCopy.dateFilter = stateCopy.dateFilter.map(answer => {
                if (answer.code === action.payload.code) return { code: answer.code, value: !action.payload.value, name: answer.name }
                return answer
            });
            var isSubjectSelected1 = stateCopy.subjectFilter.filter((e) => {
                return e.value === true
            });

            stateCopy.subjectSelected = isSubjectSelected1;
            return stateCopy
        case DATE_FILTER_CLICK:
            stateCopy.subjectFilter = stateCopy.subjectFilter.map(answer => {
                if (answer._id === action.payload.id) return { _id: answer._id, value: !action.payload.value, name: answer.name }
                return answer
            });
            stateCopy.dateFilter = stateCopy.dateFilter.map(answer => {
                if (answer.code === action.payload.code) return { code: answer.code, value: !action.payload.value, name: answer.name }
                return answer
            });
            stateCopy.dateFilter.forEach(el => {
                if (el.code === action.payload.code) {
                    if (el.value === false) {
                        stateCopy.fromDate = '';
                        stateCopy.toDate = '';
                    }
                    else {
                        var threeMonthsAgo = moment().subtract(action.payload.code, 'months');
                        stateCopy.fromDate = threeMonthsAgo.format('YYYY-MM-DD');
                        stateCopy.toDate = moment().format('YYYY-MM-DD');
                    }
                }
            })
            var isSubjectSelected3 = stateCopy.subjectFilter.filter((e) => {
                return e.value === true
            });
            stateCopy.subjectSelected = isSubjectSelected3;
            return stateCopy;
        case CLEAR_FILTER:
            stateCopy.subjectSelected = [];
            stateCopy.subjectFilter = stateCopy.subjectFilter.map(answer => {
                return { _id: answer._id, value: false, name: answer.name }
            });
            stateCopy.dateFilter = stateCopy.dateFilter.map(answer => {
                return { code: answer.code, value: false, name: answer.name }
            });
            return stateCopy;

        case GET_NOTES:
            stateCopy.note = stateCopy.notes.filter(el => el._id === action.payload._id)[0];
            stateCopy.note = { ...stateCopy.note, ...action.payload };
            stateCopy.isNote = true;
            return stateCopy;
        case SET_NOTE:
            stateCopy.note.text = action.payload;
            return stateCopy;

        case SAVE_NOTES:
            console.log(action.payload)
            const notes = stateCopy.notes.map(answer => {
                console.log(answer)
                if (answer._id === action.payload.resource_id) return {
                    _id: answer._id,
                    title: answer.title,
                    image: answer.image,
                    url: answer.url,
                    active: answer.active,
                    source: answer.source,
                    type: answer.type,
                    subject_id: action.payload.subject_id,
                    text: action.payload.text,
                    chapter: action.payload.chapter,
                    createdAt: answer.createdAt

                }
                return answer
            })
            stateCopy.notes = notes;
            return stateCopy;
        case GET_LIKED:
            stateCopy.note.liked = !action.payload.liked;
            return stateCopy;

        case "Reset_Popup":
            stateCopy.note = {};
            stateCopy.isNote = false;
            return stateCopy;

        default:
            return stateCopy;
    }
}

