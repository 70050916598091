/* eslint-disable no-unused-vars */
import { getApiCall, postApiCall, putApiCall } from '../../utils/request';
import {
    CHECK_EMAIL, RESET_USERS_LIST, GET_STUDENT_LIST, GET_STAFFS, ADD_STAFF, TOGGLE_USER, UPDATE_STATUS,
    SELECT_ALL_STUDENTS, SELECT_ALL_STAFF, SELECT_ONE_STUDENT, SELECT_ONE_STAFF, VIEW_STUDENT, BACK_TO_USERS, RESEND_INVITES,
    GET_CLASSES, GET_SECTION_FROM_CLASS
} from '../Types/types';
import { returnErrors } from './errorAction';



export const checkUser = (email) => async (dispatch) => {
    let obj = {
        email: email
    }
    try {
        const response = await postApiCall(`admin/users/check`, obj);
        return dispatch({ type: CHECK_EMAIL, payload: response.data.exists });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const resetLists = () => async (dispatch) => {
    return dispatch({ type: RESET_USERS_LIST })
}

export const getStudentList = (size, page, role) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/users/student?size=${size}&page=${page}`);
        const data = response.data
        console.log(data)
        dispatch({ type: GET_STUDENT_LIST, payload: { data, role } });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const savedStudent = (result) => async (dispatch) => {
    try {
        const response = await postApiCall(`admin/users/student`, { students: result });
        if (response.data.success) {

        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status);
    }
}

export const editedStudent = (result) => async (dispatch) => {
    try {
        const response = await putApiCall(`admin/users/student`, result);
        if (response.data.success) {

        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status);
    }
}

export const getStaffList = (size, page) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/users/staff?size=${size}&page=${page}`);
        dispatch({ type: GET_STAFFS, payload: response.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status);
    }
}

export const savedStaff = (result) => async (dispatch) => {
    try {
        const response = await postApiCall(`admin/users/staff`, result);
        if (response.data.success) {
            dispatch({ type: ADD_STAFF })
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status);
    }
}

export const editedStaff = (result) => async (dispatch) => {
    try {
        const response = await putApiCall(`admin/users/staff`, result);
        if (response.data.success) {
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status);
    }
}

export const changeStatus = (id, type) => async (dispatch) => {
    try {
        const response = await putApiCall(`admin/users/toggle`, { _id: id });
        dispatch({ type: TOGGLE_USER, payload: { id, type } });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const clearFilter = (role) => async (dispatch) => {
    try {
        if (role === 'student') {
            const response = await getApiCall(`admin/users/student?size=20&page=1`);
            const data = response.data
            dispatch({ type: GET_STUDENT_LIST, payload: { data, role } });
        }
        else {
            const response = await getApiCall(`admin/users/staff?size=20&page=1`);
            dispatch({ type: GET_STAFFS, payload: response.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const getFilterList = (size, page, role, search, clsId, sId, status) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/users/student?size=${size}&page=${page}&search=${search}&class=${clsId === undefined ? '' : clsId}&section=${sId === undefined ? '' : sId}&status=${status === undefined ? '' : status}`);
        const data = response.data
        dispatch({ type: GET_STUDENT_LIST, payload: { data } });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getFilterStaffList = (size, page, search, role, subject, status) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/users/staff?size=${size}&page=${page}&search=${search}&role=${role === undefined ? '' : role}&subject=${subject === undefined ? '' : subject}&status=${status === undefined ? '' : status}`);
        dispatch({ type: GET_STAFFS, payload: response.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const selectAllStudent = (checked) => async (dispatch) => {
    return dispatch({ type: SELECT_ALL_STUDENTS, payload: checked })
}

export const selectAllStaff = (checked) => async (dispatch) => {
    return dispatch({ type: SELECT_ALL_STAFF, payload: checked })
}

export const checkOneStudent = (id, checked) => async (dispatch) => {
    return dispatch({ type: SELECT_ONE_STUDENT, payload: { id, checked } })
}
export const checkOneStaff = (id, checked) => async (dispatch) => {
    return dispatch({ type: SELECT_ONE_STAFF, payload: { id, checked } })
}

export const viewStudent = (id) => async (dispatch) => {
    return dispatch({ type: VIEW_STUDENT, payload: id })
}

export const backToMain = () => async (dispatch) => {
    return dispatch({ type: BACK_TO_USERS })
}

export const updateUserStatus = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`admin/users/update/status`, obj);
        if (response.data.success) {
            dispatch({ type: UPDATE_STATUS, });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const updateUserClass = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`admin/users/update/class`, obj);
        if (response.data.success) {
            dispatch({ type: UPDATE_STATUS, });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const resendInvite = (arr, type) => async (dispatch) => {
    let obj = {
        _ids: arr
    }
    let len = arr.length
    try {
        const response = await postApiCall(`admin/users/resend/invite`, obj);
        if (response.data.success) {
            dispatch({ type: RESEND_INVITES, payload: { len, type } });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getClasses = () => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/school/classes/list`);
        if (response.data.success) {
            dispatch({ type: GET_CLASSES, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getSectionFromClass = (cls, id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/school/classes/sections?class=${cls}&affiliation_id=${id ? id : ''}`);
        if (response.data.success) {
            dispatch({ type: GET_SECTION_FROM_CLASS, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}






