import React, { Fragment, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Select, { components } from 'react-select';
import InfiniteScroll from 'react-infinite-scroll-component';
import HistoryPopup from './historyPopup';
import Notespopup from '../My-notes/notespopup';
// import ViewResource from '../../utils/viewResource';
import {
    refreshTests, getTestClassWise, getClassSubjects, getQuizList, nextQuizList, getPracticeTest, getNextPracticeTest, refreshQuestion, getQuestions, resetHistoryLoading, getTestHistory,// test section
    openIndividualItem,
    resetTestDetails
} from '../../Redux/ActionCreators/reportAction';
import { getQuestionData } from '../../Redux/ActionCreators/myContentAction';
import { getAllSubjects } from '../../Redux/ActionCreators/manageContentAction';
import { BarChart, TopicLoader } from '../../Loader/loader';
import { useDispatch, useSelector } from 'react-redux';
import Highcharts, { chart } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import TableComponent from './TableComponent';


const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/select-indicator.svg' alt='' />
        </components.DropdownIndicator>
    );
}

function TestModule() {

    const [quesView, setQuesView] = useState(false);
    const [activeStatus, setActiveStatus] = useState('test1');
    const [testpage, setTestpage] = useState(1);
    const [quizpage, setQuizpage] = useState(1);
    const [subjectId, setSubjectId] = useState('');
    const [filterSubject, setFilterSubject] = useState(null);
    const [isView, setIsView] = useState(false);
    const [isHistory, setIsHistory] = useState(false);
    const [resource, setResource] = useState({});
    const [show, setShow] = useState('');
    const classWiseTestHeader = [{ title: 'class', label: 'Class', sort: false }, { title: 'total_tests', label: 'Total Tests', sort: false }, { title: 'total_test_students', label: 'Tests Taken by Students', sort: false }, { title: 'average_test_score', label: 'Avg Test Score', sort: false }];
    const dispatch = useDispatch();
    const { testsLoading, classWiseTestGraph, topTestGraph, classWiseTestData, quizList, practiceTestList, quesLoading, quesList, ishLoading, testHistory } = useSelector(state => state.report);
    const { questionData } = useSelector(state => state.myContent);
    const { selectFromSubject } = useSelector(state => state.manageContent);

    useEffect(() => {
        dispatch(getAllSubjects());
    }, [])


    useEffect(() => {
        const getData = async () => {
            if (activeStatus === 'test') {
                await dispatch(getPracticeTest(testpage, subjectId));
                // await fetchTestData();
            } else if (activeStatus === 'quiz') {
                await dispatch(refreshTests());
                await dispatch(getQuizList(quizpage, subjectId));
                // await fetchQuizData();
            } else {
                await dispatch(getTestClassWise());
            }
        }
        getData();
    }, [activeStatus, subjectId])

    const onChangeStatus = async (e) => {
        setActiveStatus(e);
        setTestpage(1);
        setQuizpage(1);
        setSubjectId('');
        setFilterSubject(null)

    }


    const fetchQuizData = () => {
        setQuizpage(quizpage + 1);
        dispatch(nextQuizList(quizpage + 1, subjectId));
    }

    const fetchTestData = () => {
        setTestpage(testpage + 1);
        dispatch(getNextPracticeTest(testpage + 1, subjectId));
    }


    const getFilterSubject = async (e) => {
        setFilterSubject(e);
        setSubjectId(e ? e.value : '');
        setQuizpage(1);
        setTestpage(1);
    }


    const getTestsHistory = async (id, question) => {
        console.log(id, question)
        setIsHistory(true);
        await dispatch(resetHistoryLoading());
        await dispatch(getTestHistory(id, question));
    }

    const openQuesView = async (id) => {
        setQuesView(true);
        await dispatch(refreshQuestion());
        await dispatch(getQuestions(id));
    }


    useEffect(() => {
        setResource({ ...questionData, type1: 'Question' });
    }, [questionData])

    const openView = async (el) => {
        const res = await dispatch(getQuestionData('Question', el.question_id));
        if (res?.type === 'GET_QUESTION_DATA') {
            setIsView(true);
            setResource({ ...res?.payload, type1: 'Question' });
        }
    }

    const closeDetails = () => {
        setIsView(false);
        setResource({});
    }


    //courses graph
    const testGraph = {
        chart: {
            type: 'pie',
            height: 300,
        },
        title: {
            text: 'Test Distribution'
        },

        accessibility: {
            announceNewData: {
                enabled: true
            },
            point: {
                // valueSuffix: '%'
            }
        },
        credits: {
            enabled: false
        },

        colors: classWiseTestGraph !== undefined && classWiseTestGraph.map(el => el.color),
        plotOptions: {
            pie: {
                dataLabels: {
                    // connectorColor: 'transparent',
                    connectorPadding: 5,
                    distance: 20,
                    enabled: true,

                },
            },
            series: {
                animation: false,
                dataLabels: {
                    enabled: true,
                    format: `{point.name} <br/>  {point.y}`,
                }
            },

        },

        tooltip: {
            enabled: false,

        },

        series: [
            {
                name: "Tests",
                colorByPoint: true,
                enableMouseTracking: false,
                data: classWiseTestGraph !== undefined && classWiseTestGraph
            }
        ],

    }

    const testEngagement = {
        chart: {
            type: 'bar',
            height: 300,

        },
        title: {
            text: 'Top Avg Tests Score'
        },
        // colors: ['#00B24B', '#FF5A5F', '#ECC30B', '#6457E8', '#3ABEBC', '#1D8BCB'],
        xAxis: {
            categories: topTestGraph && topTestGraph?.map(el => el.class),
            title: {
                text: null
            }
        },
        yAxis: {
            visible: true,
            title: false,
            labels: {
                enabled: false
            },
            gridLineColor: 'transparent',
            stackLabels: {
                enabled: true,
                formatter: function () {
                    var val = this.total;
                    if (val > 0) {
                        return val;
                    }
                    return '';
                },
                style: {
                    fontWeight: 'bold',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || '#166B9C'
                }
            },
        },

        plotOptions: {
            series: {
                animation: false,
                pointPadding: 0.1,
                groupPadding: 0.1,
            },
            bar: {
                dataLabels: {
                    enabled: true,
                    crop: false,
                    overflow: 'hidden',
                    formatter: function () {
                        var pcnt = (this.y);
                        if (pcnt > 0) {
                            return pcnt.toFixed(2);
                        }
                        else {
                            return '';
                        }

                    }
                },

            }
        },

        credits: {
            enabled: false
        },
        series: [
            {
                enableMouseTracking: false,
                showInLegend: false,
                data: topTestGraph !== undefined && topTestGraph
            }
        ]

    }




    return (
        <div>
            {!quesView ?
                <div className='test-wrapper'>
                    <div className="content-tabs d-flex justify-content-between" style={{ minHeight: 34, flexWrap: window.innerWidth > 767 ? '' : 'wrap', overflow: 'unset' }}>
                        <div>
                            <button
                                className={
                                    activeStatus === "test1" ? "active-tab" : null
                                }
                                onClick={() => onChangeStatus("test1")}
                            >
                                Practice tests Overview
                            </button>
                            &nbsp;&nbsp;&nbsp;
                            <button
                                className={
                                    activeStatus === "test" ? "active-tab" : null
                                }
                                onClick={() => onChangeStatus("test")}
                            >
                                Practice tests
                            </button>&nbsp;&nbsp;&nbsp;
                            <button
                                className={
                                    activeStatus === "quiz" ? "active-tab" : null
                                }
                                onClick={() => onChangeStatus("quiz")}
                            >
                                Quizzes
                            </button>&nbsp;&nbsp;&nbsp;
                        </div>

                        {activeStatus !== 'test1' ?
                            <div style={{ width: window.innerWidth > 767 ? '20%' : '342px' }}>
                                <Select
                                    components={{ DropdownIndicator }}
                                    onChange={getFilterSubject}
                                    placeholder='Subjects'
                                    value={filterSubject}
                                    options={selectFromSubject}
                                    isClearable
                                />
                            </div> :
                            null
                        }
                    </div>
                    <div className='completion-details mt-5'>
                        <div className="test-wise-details mt-3">
                            {activeStatus === 'quiz' ?
                                <ul>
                                    <InfiniteScroll
                                        dataLength={quizList !== undefined && quizList?.length} //This is important field to render the next data
                                        next={fetchQuizData}
                                        hasMore={true}
                                    >
                                        {testsLoading ?
                                            <TopicLoader /> :
                                            quizList !== undefined && !quizList.length ?
                                                <div className="empty-test">
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-md-5 d-flex flex-column justify-content-center align-items-center no-test">
                                                                <h4>Oops! No tests available</h4>
                                                                <p>
                                                                    There are no quizzes available relevant to this Subject.
                                                                </p>
                                                            </div>
                                                            {window.innerWidth > 767 ? (
                                                                <div className="col-md-7 d-flex justify-content-center align-items-center">
                                                                    <img
                                                                        src="/images/empty.png"
                                                                        width="740px"
                                                                        height="350px"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    style={{
                                                                        backgroundImage: "url(/images/empty.png)",
                                                                        backgroundPosition: "center",
                                                                        backgroundRepeat: "no-repeat",
                                                                        padding: 100,
                                                                        backgroundSize: "contain",
                                                                        width: 400,
                                                                    }}
                                                                ></div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div> :
                                                quizList !== undefined &&
                                                quizList.map((el, index) => {
                                                    return window.innerWidth > 767 ? (
                                                        <Fragment>
                                                            <li key={index} style={{ marginTop: 15 }}>
                                                                <div className="topic-name">
                                                                    {index + 1}. {el.title}
                                                                </div>
                                                                <div className="topic-time" >
                                                                    <p style={{ fontSize: 14, color: '#222222' }}>
                                                                        {el.subject_name} <span>Subject</span>
                                                                    </p>
                                                                </div>
                                                                <div className="topic-time" style={{ width: '5%' }}>
                                                                    <p>
                                                                        {el.no_ques} <span>Questions</span>
                                                                    </p>
                                                                </div>
                                                                <div className="last-score">
                                                                    <p>
                                                                        {el.no_students !== undefined && el.no_students} <span>Total taken</span>
                                                                    </p>
                                                                </div>
                                                                <div className="last-score">
                                                                    <p>
                                                                        {el.avg_score !== undefined && ((el.avg_score === 0 || el.avg_score == null) ? el.avg_score : el.avg_score?.toFixed(2))}% <span>Avg Score</span>
                                                                    </p>
                                                                </div>
                                                                <div className="last-history" style={{ width: '5%' }}>
                                                                    {
                                                                        el.no_students >= 1 &&
                                                                        <div className="d-flex" onClick={() => getTestsHistory(el._id, el.no_ques)}>
                                                                            <img src="/images/history.svg" alt="" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div onClick={() => { openQuesView(el._id) }} style={{ color: '#f5821f', fontWeight: 'bold', cursor: 'pointer' }}>
                                                                    View Details
                                                                </div>
                                                            </li>
                                                        </Fragment>
                                                    ) : (
                                                        <Fragment>
                                                            <li>

                                                                <div className="last-taken1 ml-4" >
                                                                    <h3>
                                                                        {index + 1}. {el.title}
                                                                    </h3>
                                                                    {
                                                                        el.no_students >= 1 ? (
                                                                            <p>
                                                                                Total taken : {el.no_students} &nbsp;
                                                                                <img src="/images/info1.svg" onClick={() => getTestsHistory(el._id, el.no_ques)} style={{ verticalAlign: "top" }} alt="" />
                                                                            </p>)
                                                                            :
                                                                            <p className='d-flex flex-row'>
                                                                                {el.no_ques} Questions &nbsp;
                                                                            </p>
                                                                    }
                                                                </div>

                                                            </li>
                                                        </Fragment>
                                                    );
                                                })}
                                    </InfiniteScroll>
                                </ul>
                                : activeStatus === 'test' ?
                                    <ul>
                                        <InfiniteScroll
                                            dataLength={practiceTestList !== undefined && practiceTestList?.length} //This is important field to render the next data
                                            next={fetchTestData}
                                            hasMore={true}
                                        >
                                            {testsLoading ?
                                                <TopicLoader /> :
                                                practiceTestList !== undefined && !practiceTestList.length ?
                                                    <div className="empty-test">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-md-5 d-flex flex-column justify-content-center align-items-center no-test">
                                                                    <h4>Oops! No tests available</h4>
                                                                    <p>
                                                                        There are no tests available relevant to this Subject.
                                                                    </p>
                                                                </div>
                                                                {window.innerWidth > 767 ? (
                                                                    <div className="col-md-7 d-flex justify-content-center align-items-center">
                                                                        <img
                                                                            src="/images/empty.png"
                                                                            width="740px"
                                                                            height="350px"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            backgroundImage: "url(/images/empty.png)",
                                                                            backgroundPosition: "center",
                                                                            backgroundRepeat: "no-repeat",
                                                                            padding: 100,
                                                                            backgroundSize: "contain",
                                                                            width: 400,
                                                                        }}
                                                                    ></div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div> :
                                                    practiceTestList !== undefined &&
                                                    practiceTestList.map((el, index) => {
                                                        return window.innerWidth > 767 ? (
                                                            <Fragment>
                                                                <li key={index} style={{ marginTop: 15 }}>
                                                                    <div className="topic-name">
                                                                        {index + 1}. {el.title}
                                                                    </div>
                                                                    <div className="topic-time" >
                                                                        <p style={{ fontSize: 14, color: '#222222' }}>
                                                                            {el.subject_name} <span>Subject</span>
                                                                        </p>
                                                                    </div>
                                                                    <div className="topic-time" style={{ width: '5%' }}>
                                                                        <p>
                                                                            {el.no_ques} <span>Questions</span>
                                                                        </p>
                                                                    </div>
                                                                    <div className="last-score">
                                                                        <p>
                                                                            {el.no_students !== undefined && el.no_students} <span>Total taken</span>
                                                                        </p>
                                                                    </div>
                                                                    <div className="last-score">
                                                                        <p>
                                                                            {el.avg_score !== undefined && ((el.avg_score === 0 || el.avg_score == null) ? el.avg_score : el.avg_score?.toFixed(2))}% <span>Avg Score</span>
                                                                        </p>
                                                                    </div>
                                                                    <div className="last-history" style={{ width: '5%' }}>
                                                                        {
                                                                            el.no_students >= 1 &&
                                                                            <div className="d-flex" onClick={() => getTestsHistory(el._id, el.no_ques)}>
                                                                                <img src="/images/history.svg" alt="" />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div onClick={() => { openQuesView(el._id) }} style={{ color: '#f5821f', fontWeight: 'bold', cursor: 'pointer' }}>
                                                                        View Details
                                                                    </div>
                                                                </li>
                                                            </Fragment>
                                                        ) : (
                                                            <Fragment>
                                                                <li>

                                                                    <div className="last-taken1 ml-4" >
                                                                        <h3>
                                                                            {index + 1}. {el.title}
                                                                        </h3>
                                                                        {
                                                                            el.no_students >= 1 ? (
                                                                                <p>
                                                                                    Total taken : {el.no_students} &nbsp;
                                                                                    <img src="/images/info1.svg" onClick={() => getTestsHistory(el._id, el.no_ques)} style={{ verticalAlign: "top" }} alt="" />
                                                                                </p>)
                                                                                :
                                                                                <p className='d-flex flex-row'>
                                                                                    {el.no_ques} Questions &nbsp;
                                                                                </p>
                                                                        }
                                                                    </div>

                                                                </li>
                                                            </Fragment>
                                                        );
                                                    })}
                                        </InfiniteScroll>
                                    </ul>
                                    : <div className='reports-holder'>
                                        <div className='center-reports mt-2'>
                                            <div className='program-graph '>
                                                {
                                                    classWiseTestGraph ?
                                                        <Fragment>
                                                            <div className='row ml-0 mr-0'>
                                                                <div className='col-md-6'>
                                                                    <HighchartsReact
                                                                        highcharts={Highcharts}
                                                                        options={testGraph}
                                                                        oneToOne={true}
                                                                    />
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <HighchartsReact
                                                                        highcharts={Highcharts}
                                                                        options={testEngagement}
                                                                        oneToOne={true}
                                                                    />
                                                                </div>

                                                            </div>
                                                            <div className='row ml-0 mr-0'>
                                                                {/* <div className='program-legand mt-3' >
                                                                {
                                                                    legands !== undefined && legands.map((el, i) => {
                                                                        return (
                                                                            window.innerWidth > 767 ?
                                                                                <Fragment>
                                                                                    <div style={{ background: el.color }} className='legand'></div> &nbsp; &nbsp;<p>{el.name}</p>
                                                                                </Fragment>
                                                                                :
                                                                                <div className='col-3 d-flex'>
                                                                                    <div style={{ background: el.color }} className='legand'></div> &nbsp; &nbsp;<p>{el.name}</p>
                                                                                </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div> */}
                                                            </div>
                                                        </Fragment> :
                                                        <div className='text-center mt-5'>
                                                            <BarChart />
                                                        </div>
                                                }
                                            </div>
                                            <div className='reports-header  '>
                                                <div className='d-flex justify-content-between align-items-center header-width'>
                                                    <h3>Practice Test Detailed Data </h3>
                                                </div>
                                            </div>
                                            <div className='center-table '>
                                                <TableComponent
                                                    header={classWiseTestHeader}
                                                    centerList={classWiseTestData}
                                                    type='classwisetest'
                                                    // sortProgramList={(sortKey, asc) => dispatch(sortProgramList(sortKey, asc))}
                                                    openIndividualItem={(type, el) => dispatch(openIndividualItem(type, el))}
                                                    resetTestDetails={() => dispatch(resetTestDetails())}
                                                    getClassSubjects={(cid) => dispatch(getClassSubjects(cid))}
                                                    setShow={(val) => setShow(val)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                            }
                        </div>
                    </div>
                </div> :
                <div className='center-reports'>
                    <div className='reports-header  '>
                        <div className='d-flex justify-content-between align-items-center header-width'>
                            <h3><img src='/images/back.svg' alt='' style={{ verticalAlign: 'middle', cursor: 'pointer' }} onClick={() => setQuesView(false)} /> &nbsp;Question Details </h3>
                        </div>

                    </div>
                    <div className='center-table '>
                        <Table>
                            <thead>
                                <th>Question title</th>
                                <th>Attempted</th>
                                <th>Correct answered</th>
                                <th>View</th>
                            </thead>
                            <tbody>
                                {quesLoading ?
                                    <TopicLoader /> :
                                    quesList && quesList.map(el => {
                                        return (
                                            <tr>
                                                <td style={{ width: '50%', color: '#696873' }} dangerouslySetInnerHTML={{ __html: el.question_title }}></td>
                                                <td className='text-center'>{el.no_attempts}</td>
                                                <td className='text-center'>{el.correct_answered}</td>
                                                <td className='text-center'><img src='/images/view_grey.svg' alt='' className='cursor-pointer' width={25} onClick={() => openView(el)} /></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
            }


            {/* //historypopup */}
            {
                isHistory ?
                    <HistoryPopup
                        testHistory={testHistory}
                        closePopup={() => setIsHistory(false)}
                        ishistoryLoading={ishLoading}
                        type='test-history'
                    />
                    : null
            }

            {/* // view question */}
            <div className="resource-details" style={{ display: isView ? 'block' : 'none', }}>
                <Notespopup
                    activeFeedData={resource}
                    closeNotes={closeDetails}
                />
            </div>
        </div >
    )
}




export default TestModule;
