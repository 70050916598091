import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ProgressBar from '../../../utils/progressBar';
import { useTranslation } from 'react-i18next';

export default function SubjectBrief({ isOpen, subjectDescription, setIsOpen }) {
    const { t } = useTranslation();
    return (
        <div>
            {/* ################## Details modal ############################ */}
            <Modal
                show={isOpen}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"subject-details"}
            >
                <div class="modal-header">
                    <h5 class="modal-title ">{t('Course Overview')}</h5>

                    <div className="sticky-close" onClick={() => setIsOpen(false)}>
                        <img src="/images/close.svg" width='15px' alt='' /></div>

                </div>
                <Modal.Body >
                    < div className='course-description'>

                        <div className='chapter-desc'>
                            <div className='d-flex align-items-center'>
                                <h3>
                                    {subjectDescription?.name}
                                </h3>
                            </div>
                            <div className='sub-description'>
                                {subjectDescription?.description}
                            </div>
                            <div className='chapter-count'>
                                <div className='d-flex align-items-center'>
                                    <img src='/images/video-icon.svg' alt='' />
                                    <div className='mx-2'>
                                        <h6>{t('Videos')}</h6>
                                        <h5>{subjectDescription?.videos}</h5>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <img src='/images/article-icon.svg' alt='' />
                                    <div className='mx-2'>
                                        <h6>{t('Articles')}</h6>
                                        <h5>{subjectDescription?.articles}</h5>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <img src='/images/quiz-icon.svg' alt='' />
                                    <div className='mx-2'>
                                        <h6>{t('Quizzes')}</h6>
                                        <h5>{subjectDescription?.quizzes}</h5>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <img src='/images/document-icon.svg' alt='' />
                                    <div className='mx-2'>
                                        <h6>{t('Documents')}</h6>
                                        <h5>{subjectDescription?.documents}</h5>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='action-btn'>
                                <button>{t('Start learning')}</button>&nbsp;&nbsp;

                            </div> */}
                        </div>
                        <div className='chapter-img'>
                            <img src={subjectDescription?.image} alt='' />
                        </div>
                    </div>
                    <div className='course-content'>
                        <div className='course-desc'>
                            {
                                subjectDescription?.brief?.description?.map(el => {
                                    return (
                                        <div className='mt-3'>
                                            <h1>{el.title}</h1>
                                            <ul>
                                                {
                                                    el.content?.map(item => {
                                                        return (
                                                            <li>{item}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    )
                                })
                            }

                            {subjectDescription?.brief?.outcome?.some(el => el !== '') &&
                                <div className='course-learn'>
                                    <h2>{t('What will you learn?')}</h2>
                                    <ul>
                                        {
                                            subjectDescription?.brief?.outcome?.map(item => {
                                                return (
                                                    <li>{item}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>}
                            {subjectDescription?.brief?.skills?.some(el => el !== '') &&
                                <div className='mt-4'>
                                    <h2>{t('Skills you will gain')}</h2>
                                    <div className='skills-list'>
                                        {
                                            subjectDescription?.brief?.skills?.map(el => {
                                                return (
                                                    <div className='skill'>
                                                        {el}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>}
                            <div className='topics-covered'>
                                <div className='topic-header'>
                                    <h2>{t("Topics to be covered")}</h2>
                                </div>
                                <div className='topic-cards'>
                                    {
                                        subjectDescription?.chapters !== undefined && subjectDescription?.chapters.map((el) => {
                                            return (
                                                <div key={el._id} className="card" style={{ width: '25%', marginRight: 10 }} onClick={() => setIsOpen(false)} >

                                                    <img className="card-img-top" src={el.image} style={{ height: 114 }} alt="Card" />
                                                    {/* <div className='time'>
                                                        <img src='images/duration.svg' alt='' />&nbsp;
                                                        08 hr 12 mins
                                                    </div> */}
                                                    <div className="card-body p-0">
                                                        <div style={{ padding: 7, paddingTop: 5, paddingRight: 5, }}>
                                                            <div className='d-flex justify-content-center align-items-start flex-column'>
                                                                <h5 className='elipsis-2 w-96' >
                                                                    {el.name}
                                                                </h5>
                                                            </div>
                                                            <div className='mt-3'>
                                                                <ProgressBar bgcolor='#5855D6' fill='#D9D9D9' t={t} completed={el?.analytic || 0}
                                                                />
                                                            </div>
                                                            <div style={{ borderTop: '1px solid #ddd', marginTop: -10 }}></div>
                                                            <button>{t('Start learning')}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            {subjectDescription?.brief?.benefits?.some(el => el !== '') &&
                                <div className='mt-4'>
                                    <h1>{t('Benifits')}</h1>
                                    <ul>
                                        {
                                            subjectDescription?.brief?.benefits?.map(item => {
                                                return (
                                                    <li>{item}</li>
                                                )
                                            })
                                        }

                                    </ul>
                                </div>}
                            {subjectDescription?.brief?.hiring_companies?.some(el => el !== '') &&
                                <div className='mt-4'>
                                    <h1>{t('Companies hiring for')} {subjectDescription?.name} skills</h1>
                                    <ul>
                                        {
                                            subjectDescription?.brief?.hiring_companies?.map(item => {
                                                return (
                                                    <li>{item}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>}
                            <div className='start-btn'>
                                <button onClick={() => setIsOpen(false)}>
                                    {t('Start learning')}
                                </button>
                            </div>
                        </div>
                        <div className='course-job-desc'>
                            {subjectDescription?.brief?.opportunities?.some(el => el !== 0) &&
                                <div className='course-job'>
                                    <h2>{t('Career Opportunities')}</h2>
                                    <ul>
                                        {
                                            subjectDescription?.brief?.opportunities?.map(el => {
                                                return (
                                                    <li>{el}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                    {subjectDescription?.brief?.avg_salary &&
                                        <h2 className='mt-4'>{t('Average Salary')} :<br />
                                            {subjectDescription?.brief?.avg_salary} / yr in India</h2>}
                                </div>}
                        </div>
                    </div>


                </Modal.Body>
            </Modal>
        </div>
    )
}
