import { returnErrors } from './errorAction';
import { getApiCall, postApiCall, putApiCall } from '../../utils/request';
import {
    GET_REWARDS, GET_COUNTRIES, REWARD_STATUS, Select_One, GET_ONE_CHECKED,
    REFRESH_LIST, GET_REDEEM_LIST, GET_NEXT_REDEEM_LIST, VOUCHER_SENT, GET_PROVIDERS, PROVIDER_STATUS
} from '../Types/types';


//rewards

export const getRewards = () => async (dispatch) => {
    try {
        const response = await getApiCall(`master-admin/rewards/list`);
        dispatch({ type: GET_REWARDS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const checkOne = (id, checked) => async (dispatch) => {
    try {
        return dispatch({ type: Select_One, payload: { id, checked } })
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const getCheckedIds = (data) => async (dispatch) => {
    console.log(data)
    return dispatch({ type: GET_ONE_CHECKED, payload: data })

};

export const getCountries = () => async (dispatch) => {
    try {
        const response = await getApiCall(`master-admin/country/list`);
        dispatch({ type: GET_COUNTRIES, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const savedReward = (data) => async (dispatch) => {
    try {
        const response = await postApiCall(`master-admin/rewards/create`, data);
        if (response.data.success) {
            returnErrors(dispatch, 'Reward added', response?.status);
            dispatch(getRewards())
        }
        else {
            returnErrors(dispatch, response?.data.message, response?.status);
        }

    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status);
    }
}

export const updateReward = (data) => async (dispatch) => {
    try {
        const response = await putApiCall(`master-admin/rewards/update`, data);
        if (response.data.success) {
            returnErrors(dispatch, 'Reward updated', response?.status);
            dispatch(getRewards())
        }
        else {
            returnErrors(dispatch, response?.data.message, response?.status);
        }

    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status);
    }
}


export const handleStatus = (active, id) => async (dispatch) => {
    let obj = { _id: id }
    try {
        const response = await postApiCall(`master-admin/rewards/toggle`, obj);
        dispatch({ type: REWARD_STATUS, payload: { active, id } });
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status);
    }
}

export const campusVoucherSent = (obj, type) => async (dispatch) => {
    try {
        const response = await postApiCall(`master-admin/rewards/toggle/redeem/status`, obj);
        if (response.data.success) {
            dispatch({ type: VOUCHER_SENT, payload: { obj, type } });
            returnErrors(dispatch, 'Voucher code added', response.status)
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

//Redeem 
export const getRedeemList = (page, start, end) => async (dispatch) => {
    try {
        const response = await getApiCall(`master-admin/rewards/redeem/history?page=${page}&from=${start}&to=${end}`);
        if (response.data.success) {
            dispatch({ type: GET_REDEEM_LIST, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const getNextRedeemList = (page, start, end) => async (dispatch) => {
    try {
        const response = await getApiCall(`master-admin/rewards/redeem/history?page=${page}&from=${start}&to=${end}`);
        if (response.data.success) {
            dispatch({ type: GET_NEXT_REDEEM_LIST, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response?.data?.message, response?.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error.response?.status)
    }
}



export const refreshList = () => async (dispatch) => {
    return dispatch({ type: REFRESH_LIST })
}

//provider
export const getProviders = () => async (dispatch) => {
    try {
        const response = await getApiCall(`master-admin/provider/list`);
        dispatch({ type: GET_PROVIDERS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const savedProvider = (data) => async (dispatch) => {
    try {
        const response = await postApiCall(`master-admin/provider/create`, data);
        if (response.data.success) {
            returnErrors(dispatch, 'Provider added', response?.status);
            dispatch(getProviders())
        }
        else {
            returnErrors(dispatch, response?.data.message, response?.status);
        }

    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status);
    }
}

export const updateProvider = (data) => async (dispatch) => {
    try {
        const response = await putApiCall(`master-admin/provider/update`, data);
        if (response.data.success) {
            returnErrors(dispatch, 'Provider updated', response?.status);
            dispatch(getProviders())
        }
        else {
            returnErrors(dispatch, response?.data.message, response?.status);
        }

    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status);
    }
}

export const handleProviderStatus = (active, id) => async (dispatch) => {
    let obj = { _id: id }
    try {
        const response = await postApiCall(`master-admin/provider/toggle`, obj);
        dispatch({ type: PROVIDER_STATUS, payload: { active, id } });
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status);
    }
}






