/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import ReactPlayer from 'react-player';
import { Fragment } from 'react';
import $ from 'jquery';
import PdfReader from '../utils/pdfReader';
// import ReactAudioPlayer from 'react-audio-player';
import Drawer from '@mui/material/Drawer';


// ################################# Used in Feeds,manage-content,notes,my-content ############################################3
class Notespopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showAction: false,
            noteValue: "",
            needWords: 250,
            wordCount: '',
            limWords: null,
            isEdit: false,
            isEditNo: true,
            fs: false
        }
        this.imgRef = React.createRef();
    }



    handleZoomIn = () => {
        // Fetching current height and width
        const height = this.imgRef.current.clientHeight;
        const width = this.imgRef.current.clientWidth;
        // Increase dimension(Zooming)
        this.setState({
            height: height + 50,
            width: width + 50,
        })
    }


    handleReset = () => {
        // Assigning original height and width
        this.setState({
            height: this.imgRef.current.initialHeight,
            width: this.imgRef.current.initialWidth,
        })
    }

    openOriginal = async (e) => {
        await this.setState({
            target: e.target,
            showAction: !this.state.showAction,
        })
    }

    changeNotes = (event) => {
        const wordCount = event.target.value !== null && event.target.value === "" ? 0 : event.target.value.match(/\b[-?(\w+)?]+\b/gi).length;
        this.setState({
            saveError: false,
            saveErrorMsg: '',
            noteValue: event.target.value,
            wordCount: wordCount,
            limWords:
                this.state.needWords - wordCount < 0
                    ? this.state.noteValue.length
                    : null
        });
        this.props.setNote(event.target.value);
    }

    goToOriginal = (url) => {
        window.open(url, '_blank');
    }

    editNote = () => {
        $("#note").prop("disabled", false);
        this.setState({
            isEdit: true,
            isEditNo: true
        });
    }

    cancel = () => {
        if (this.state.noteValue === '' && this.props.activeFeedData.note === null) {
            this.setState({
                isEdit: true, isEditNo: false
            })
        }
        else {
            this.setState({
                isEdit: false, isEditNo: true
            })
        }

    }

    saveNote1 = (id, type, topic, subject, program, note) => {
        if (note === '') {
            this.setState({
                saveError: true,
                saveErrorMsg: 'Please enter notes'
            })
        }
        else {
            this.props.saveNote(id, type, topic, subject, program, note);
            this.setState({
                isEdit: false,
                isEditNo: true
            })
        }
    }

    onEnded = () => {
        this.props.onPause();
    }
    fullscreen = () => {
        this.setState({
            fs: true
        })
    }
    closeQuizpopup = () => {
        this.setState({
            fs: false
        })
    }

    snackClosed = () => {
        this.props.clearErrors()
    }
    render() {
        const imgStyle = { height: this.state.height, width: this.state.width }
        var result = this.state.needWords - this.state.wordCount;
        let tooManyChars;
        if (result < 0) {
            const tooManyCharStyle = {
                color: "red"
            };
            tooManyChars = (
                <p style={tooManyCharStyle}>
                    You exceeded the maximum number of words!!
                </p>
            );
        }
        const { activeFeedData } = this.props;
        const { isEdit, isEditNo } = this.state;
        if ((window.location.pathname.split('/')[1] == 'manage-content') || (this.props.activeFeedData !== undefined && this.props.activeFeedData?.type === 'Video')) {
            var startTime = this.props.activeFeedData?.time !== null && this.props.activeFeedData?.time?.split('-')[0]?.split(':').reverse().reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0);
            var endTime = this.props.activeFeedData?.time !== null && this.props.activeFeedData?.time?.split('-')[1]?.split(':').reverse().reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0);
        }
        return (
            <div className="modal-body">
                {activeFeedData !== undefined && <div className={activeFeedData?.type == 'Video' ? 'notes-details padd' : 'notes-details'}>
                    {
                        activeFeedData?.type === 'Article' || activeFeedData?.type === 'Document' ?
                            <Fragment>
                                <div className='playlist-header'>
                                    <h1 className={this.props.time ? 'topic-back mb-4' : 'topic-back'} ><small >{activeFeedData?.title}</small> &nbsp;&nbsp;&nbsp;
                                        {(activeFeedData?.type === 'Document') ?
                                            <div className='text-center' style={{ verticalAlign: 'middle', cursor: 'pointer', position: 'absolute', right: window.innerWidth > 767 ? '16%' : '10%', top: 0 }}>
                                                {this.props.time && <p style={{ fontSize: 20, float: 'right' }}>{this.props.time?.m}:{this.props.time?.s}</p>}

                                            </div>
                                            :
                                            <div className='text-center' style={{ verticalAlign: 'middle', cursor: 'pointer', position: 'absolute', right: window.innerWidth > 767 ? '16%' : '10%', top: 0 }}>
                                                <img src='/images/horizontal-ham.svg' onClick={(e) => this.openOriginal(e)} alt='' /><br />
                                                {this.props.time && <p style={{ fontSize: 20, float: 'right' }}>{this.props.time?.m}:{this.props.time?.s}</p>}

                                            </div>
                                        }
                                        <Overlay
                                            show={this.state.showAction}
                                            target={this.state.target}
                                            onHide={() => this.setState({ showAction: false })}
                                            placement="bottom-start"
                                            rootClose={true}
                                        >
                                            <Popover id="popover-contained2" style={{ width: '200px', borderRadius: 2, zIndex: 8, border: 'none', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)' }}>
                                                <ul className='popover-view'>
                                                    <li onClick={() => this.goToOriginal(activeFeedData.url)}><img src='/images/external-link.svg' alt='' /> &nbsp; View Original {activeFeedData.type}</li>
                                                    {/* <li><img src='/images/not-relvent.svg' alt='' width='14px' /> &nbsp; This {activeFeedData.type} is not relevent.</li> */}
                                                </ul>
                                            </Popover>
                                        </Overlay>
                                    </h1>
                                    {
                                        window.location.pathname.split('/')[1] === 'attachment' ? null :
                                            <div className="sticky-cross-holder"
                                                onClick={() => {
                                                    this.props.closeNotes()
                                                    this.setState({ showAction: false })
                                                }}>
                                                <img src="/images/close1.svg" alt='' />
                                            </div>
                                    }


                                </div>
                                {activeFeedData?.type === "Document" ?
                                    // <iframe title='doc' src='https://view.officeapps.live.com/op/embed.aspx?src=http://www.prepgeek.com/gate.pptx' width='70%' height='100%' frameborder='0'></iframe>
                                    <div className='content-middle1'>
                                        <PdfReader url={activeFeedData?.url} closedeatils1={this.props.closedeatils} />
                                    </div>
                                    :
                                    <div className='content-middle1'>
                                        {activeFeedData?.source_image ?
                                            (this.state.fs
                                                ?
                                                <div className='pdf-fullscreen'>
                                                    <div onClick={() => this.closeQuizpopup()} className='close-quiz'>
                                                        <img src='/images/close1.svg' alt='' />
                                                    </div>
                                                    <div className='fullscreen-container' style={{ left: '22%', right: '15%' }}>
                                                        <div className='tools'>
                                                            <button onClick={this.handleZoomIn}><img src='/images/zoom-out.png' alt='' /></button>
                                                            <button onClick={this.handleReset}><img src='/images/zoom-in.png' alt='' /></button>

                                                        </div>
                                                        <div className='mt-2'>
                                                            <img style={imgStyle} ref={this.imgRef} src={activeFeedData.source_image} onDoubleClick={this.handleZoomIn} onLoad={this.loadImage} alt='gfg' width='100%' height='auto' />
                                                        </div>
                                                    </div>
                                                </div>
                                                : <div>
                                                    <div className='tools'>
                                                        <button onClick={this.handleZoomIn}><img src='/images/zoom-out.png' alt='' /></button>
                                                        <button onClick={this.handleReset}><img src='/images/zoom-in.png' alt='' /></button>
                                                        {window.innerWidth > 767 ? <button id="fullscreenButton" onClick={this.fullscreen}><img src='/images/fullscreen.png' alt='' /></button> : null}
                                                    </div>
                                                    <div className='mt-2'>
                                                        <img style={imgStyle} ref={this.imgRef} src={activeFeedData.source_image} onDoubleClick={this.handleZoomIn} onLoad={this.loadImage} alt='gfg' width='100%' height='auto' />
                                                    </div>
                                                </div>)
                                            : activeFeedData?.description}
                                        <br />
                                        <div className='text-center mt-4'>
                                            <button className='view-btn' onClick={() => window.open(activeFeedData.url, '_target')}>View Original Article</button>
                                        </div>
                                    </div>
                                }

                            </Fragment>
                            :
                            activeFeedData !== undefined && (localStorage.getItem('role')?.includes('admin') || localStorage.getItem('role')?.includes('master') || localStorage.getItem('role') === 'faculty' || localStorage.getItem('role') === 'sme'
                                || localStorage.getItem('role') === 'program manager')
                                && activeFeedData?.type1 === 'Question' ?
                                <Fragment>
                                    <div className='playlist-header'>
                                        {
                                            window.location.pathname.split('/')[1] === 'attachment' ? null :
                                                <div className="sticky-cross-holder"
                                                    onClick={() => {
                                                        this.props.closeNotes()
                                                        this.setState({ showAction: false })
                                                    }}>
                                                    <img src="/images/close1.svg" alt='' />
                                                </div>
                                        }
                                    </div>
                                    <div className='quiz-holder text-center'>
                                        <small className='ques-title' dangerouslySetInnerHTML={{ __html: activeFeedData.description }} />
                                        {
                                            activeFeedData.type === 'Subjective' ?
                                                <ul className='options col-md-6 col-7 '>
                                                    <textarea className='form-control'
                                                        value={activeFeedData.answer}
                                                        rows='4'
                                                        // cols='4'
                                                        disabled
                                                    />
                                                    <div className='text-left mt-3 ' style={{ fontWeight: 'bold', fontSize: '15px' }}>
                                                        {activeFeedData.description !== null && <article className='d-flex'>Hints: &nbsp;{activeFeedData.hints}</article>}
                                                    </div>
                                                </ul>
                                                :
                                                <ul className='options'>
                                                    {
                                                        activeFeedData.choices !== undefined && activeFeedData.choices.map((ans, index) => {
                                                            return (
                                                                <li className='row' key={index} style={{ pointerEvents: 'none' }} >
                                                                    <div className='option1 col-md-7 col-7' dangerouslySetInnerHTML={{ __html: ans.title }}
                                                                    />
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        localStorage.getItem('role') === 'student' ? null :
                                                            <div className='text-left mt-3 w-30' style={{ fontWeight: 'bold', fontSize: '15px', paddingLeft: '22%' }}>
                                                                <article className='d-flex'>Correct Answer : &nbsp;<div dangerouslySetInnerHTML={{ __html: activeFeedData.answer !== undefined && activeFeedData.answer.title }} /></article>
                                                                {activeFeedData.description !== null && <p>Explanation : <div dangerouslySetInnerHTML={{ __html: activeFeedData?.description }} /></p>}
                                                            </div>
                                                    }
                                                </ul>
                                        }
                                    </div>
                                </Fragment>
                                :
                                activeFeedData !== undefined && localStorage.getItem('role') === 'student' && activeFeedData?.type1 === 'Question' ?
                                    <Fragment>
                                        <div className='playlist-header'>
                                            {
                                                window.location.pathname.split('/')[1] === 'attachment' ? null :
                                                    <div className="sticky-cross-holder"
                                                        onClick={() => {
                                                            this.props.closeNotes()
                                                            this.setState({ showAction: false })
                                                        }}>
                                                        <img src="/images/close1.svg" alt='' />
                                                    </div>
                                            }
                                        </div>
                                        <div className='quiz-holder text-center'>
                                            <small className='ques-title' dangerouslySetInnerHTML={{ __html: activeFeedData.title }} />
                                            {
                                                activeFeedData?.type === 'Subjective' ?
                                                    <ul className='options col-md-6 col-7 '>
                                                        <textarea className='form-control'
                                                            value={activeFeedData.answer}
                                                            rows='4'
                                                            // cols='4'
                                                            disabled
                                                        />
                                                        <div className='text-left mt-3 ' style={{ fontWeight: 'bold', fontSize: '15px' }}>
                                                            {activeFeedData?.description !== null && <article className='d-flex'>Hints: &nbsp;{activeFeedData.hints}</article>}
                                                        </div>
                                                    </ul>
                                                    :
                                                    <ul className='options'>
                                                        {
                                                            activeFeedData?.choices !== undefined && activeFeedData.choices.map((ans, index) => {
                                                                return (
                                                                    <li className='row' key={index} style={{ width: '100%' }} >
                                                                        <div className='col-md-1 col-1'>
                                                                            {activeFeedData.correct_id === ans._id && activeFeedData.correct ? <img src='/images/correct.svg' style={{ verticalAlign: 'middle' }} alt='' /> : activeFeedData.correct === false && activeFeedData.answered === ans._id ? <img src='/images/incorrect.svg' style={{ verticalAlign: 'middle' }} alt='' /> : activeFeedData.correct_id === ans._id ? <img src='/images/correct.svg' style={{ verticalAlign: 'middle' }} alt='' /> : null}
                                                                        </div>
                                                                        <div className={activeFeedData.correct_id === ans._id && activeFeedData.correct ? 'correct option col-md-7 col-7' : activeFeedData.correct === false && activeFeedData.answered === ans._id ? 'incorrect option col-md-7 col-7' : activeFeedData.correct_id === ans._id ? 'correct option col-md-7 col-7' : 'option1 col-md-7 col-7'}
                                                                            onClick={(e) => this.props.selectAnswer(activeFeedData._id, ans._id)
                                                                            } dangerouslySetInnerHTML={{ __html: ans.title }}
                                                                        />


                                                                        <div className='col-md-2 col-2' >
                                                                            {activeFeedData.description !== null ? window.innerWidth > 767 && activeFeedData.correct_id === ans._id ?
                                                                                this.state.showExplanation ?
                                                                                    <div className='explanation-popup' >
                                                                                        <h1>Explanation</h1>
                                                                                        <div className='close-explaination' onClick={() => this.setState({ showExplanation: false })}><img src='/images/close1.svg' alt='' width='10px' /></div>
                                                                                        <div style={{ textAlign: 'left', marginLeft: 5 }} dangerouslySetInnerHTML={{ __html: activeFeedData?.description }} />
                                                                                    </div> :
                                                                                    <img src='/images/info-circle.svg' style={{ verticalAlign: 'middle', cursor: 'pointer', pointerEvents: 'auto', marginTop: 5 }} alt='' onClick={(e) => this.setState({ showExplanation: true, target: e.target })} />
                                                                                : activeFeedData.correct_id === ans._id && <img src='/images/info-circle.svg' style={{ verticalAlign: 'middle', cursor: 'pointer', pointerEvents: 'auto', marginTop: 5 }} alt='' onClick={(e) => this.setState({ showExplanation: true })} />
                                                                                : null
                                                                            }
                                                                            {
                                                                                window.innerWidth < 767 && ['bottom'].map((anchor) => (
                                                                                    <React.Fragment key={anchor}>
                                                                                        <Drawer
                                                                                            anchor={anchor}
                                                                                            open={this.state.showExplanation}
                                                                                            className='mobile-drawer'
                                                                                        >

                                                                                            {
                                                                                                this.state.showExplanation ?
                                                                                                    <div style={{ padding: 16 }} >
                                                                                                        <h1>Explanation</h1>
                                                                                                        <div className='close-explaination' onClick={() => this.setState({ showExplanation: false })}><img src='/images/close1.svg' alt='' width='12px' /></div>
                                                                                                        <small>{activeFeedData.description}</small>
                                                                                                    </div>
                                                                                                    : null
                                                                                            }

                                                                                        </Drawer>
                                                                                    </React.Fragment>))
                                                                            }

                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                            }
                                        </div>
                                        {/* {
                                            window.location.pathname.split('/')[1] !== 'skill-pods' ? null :
                                                <div className='text-left mt-3 w-30' style={{ fontWeight: 'bold', fontSize: '15px', paddingLeft: '22%' }}>
                                                    <article className='d-flex'>Correct Answer : &nbsp;<div dangerouslySetInnerHTML={{ __html: activeFeedData.answer !== undefined && activeFeedData.answer.title }} /></article>
                                                    {activeFeedData.description !== null && <p>Explanation : {activeFeedData.description}</p>}
                                                </div>
                                        } */}

                                    </Fragment>

                                    : <div className={window.innerWidth > 767 ? "row m-0 videodiv" : "row videodiv"}>
                                        <div className="content-details w-100">
                                            {
                                                window.location.pathname.split('/')[1] === 'attachment' ? null :
                                                    <div className="sticky-cross-holder"
                                                        onClick={() => {
                                                            this.props.closeNotes()
                                                            this.setState({ showAction: false })
                                                        }}>
                                                        <img src="/images/close1.svg" alt='' />
                                                    </div>
                                            }
                                            <div className="content-middle">
                                                {/* <h2>{this.props.activeFeedData !== undefined && this.props.activeFeedData.title}</h2> */}
                                                <div className='playlist-header'>
                                                    <h1 className='topic-back' ><small >{activeFeedData?.title}</small> &nbsp;
                                                        <>
                                                            <img src='/images/horizontal-ham.svg' style={{ cursor: 'pointer', position: 'absolute', top: 10, right: window.innerWidth > 767 ? 0 : 30 }} onClick={(e) => this.openOriginal(e)} alt='' /><br />
                                                            {/* {this.props.time && window.innerWidth > 767 &&
                                                                <p style={{ fontSize: 20, float: 'right', marginRight: -25 }}>{this.props.time?.m}:{this.props.time?.s}</p>
                                                            } */}
                                                        </>
                                                        <Overlay
                                                            show={this.state.showAction}
                                                            target={this.state.target}

                                                            onHide={() => this.setState({ showAction: false })}
                                                            placement="bottom-start"
                                                            rootClose={true}
                                                        >
                                                            <Popover id="popover-contained2" style={{ width: '200px', borderRadius: 2, zIndex: 8, border: 'none', boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)' }}>
                                                                <ul className='popover-view'>
                                                                    <li onClick={() => this.goToOriginal(activeFeedData?.url)}><img src='/images/external-link.svg' alt='' /> &nbsp; View Original {activeFeedData?.type}</li>
                                                                    {/* <li><img src='/images/not-relvent.svg' alt='' width='14px' /> &nbsp; This {activeFeedData.type} is not relevent.</li> */}
                                                                </ul>
                                                            </Popover>
                                                        </Overlay>
                                                    </h1>


                                                    {(window.location.pathname.split('/')[1] === 'my-learning' || window.location.pathname.split('/')[1] === 'manage-content' || window.location.pathname.split('/')[1] == 'my-content' || window.location.pathname.split('/')[1] === 'skill-pods' || window.location.pathname.split('/')[1] === 'search' || window.location.pathname.split('/')[1] === 'attachment') ? null :
                                                        <nav aria-label="breadcrumb" style={{ marginLeft: window.innerWidth < 767 ? 0 : 2 }}>
                                                            <ol className="breadcrumb">
                                                                <li className="breadcrumb-item " >Notes &#62; {activeFeedData?.title}</li>

                                                            </ol>
                                                        </nav>
                                                    }

                                                </div>

                                                <p className={this.props.activeFeedData?.type == "Podcast" ? '' : 'elipsis'} dangerouslySetInnerHTML={{
                                                    __html: activeFeedData !== undefined && activeFeedData?.description
                                                }} />
                                                {this.props.time && window.innerWidth < 767 &&
                                                    <p style={{ fontSize: 20, float: 'right', marginRight: window.innerWidth > 767 ? -25 : 0, marginBottom: 10 }}>{this.props.time?.m}:{this.props.time?.s}</p>
                                                }
                                                <div className="leftimg py-3">

                                                    <span className="imgdiv">
                                                        {
                                                            this.props.activeFeedData?.type == "Podcast" && this.props.activeFeedData?.audio !== null && this.props.activeFeedData?.audio !== undefined ?

                                                                <div className="d-flex justify-content-center">
                                                                    {/* <ReactAudioPlayer
                                                                        //   src='"http://feeds.dabcc.com/~r/dabcc-radio/~5/4bTJ2oaaSgs/e293_datacore.mp3"'
                                                                        src={this.props.activeFeedData.audio !== null && this.props.activeFeedData.audio}
                                                                        autoPlay
                                                                        controls
                                                                        onError={() => this.onError(this.props.activeFeedData?.type, this.props.activeFeedData?.id)}
                                                                    /> */}


                                                                </div>
                                                                :
                                                                this.props.activeFeedData?.type === "Video" ?
                                                                    (activeFeedData.url?.includes('vimeo') || this.props.activeFeedData?.source === "Vimeo" || this.props.activeFeedData.source === "vimeo.com") ?
                                                                        <div className="embed-responsive embed-responsive-4by3">
                                                                            <iframe class="embed-responsive-item" src={`https://player.vimeo.com/video/${activeFeedData.video_id ? activeFeedData.video_id.split('/')[2] : activeFeedData.url.split('/')[3]}?autoplay=1&muted=0&autopause=0#t=${startTime}`} width="470" height="360" frameborder="0" allowfullscreen allow="autoplay; fullscreen" title={`${activeFeedData.title}`}></iframe>
                                                                        </div>
                                                                        : (activeFeedData.url?.includes('youtube') || activeFeedData.source === "Youtube" || activeFeedData.source === "YouTube" || activeFeedData.source === 'NTTF' || activeFeedData.source === "youtube.com" || activeFeedData.source === "youtu.be") ?
                                                                            window.location.pathname === '/my-learning' ?
                                                                                <ReactPlayer url={(activeFeedData.source === 'NTTF' || activeFeedData.source === localStorage.getItem('company')) ? `${this.props.activeFeedData.url}#t=${startTime},${endTime}` : activeFeedData.url}
                                                                                    config={{
                                                                                        youtube: {
                                                                                            playerVars: { start: startTime, end: endTime, disablekb: 1, rel: 0 },
                                                                                            embedOptions: {
                                                                                                host: 'https://www.youtube-nocookie.com',
                                                                                                // events: {
                                                                                                //     'onStateChange': this.onStateChange
                                                                                                // }
                                                                                            }

                                                                                        }
                                                                                    }}
                                                                                    controls playing={true} autoPlay width='65%' height={320}
                                                                                    onPlay={() => this.props.onPlayChange()}
                                                                                    onPause={() => this.props.onPause()}
                                                                                    onEnded={() => this.onEnded()}
                                                                                />
                                                                                :
                                                                                <ReactPlayer url={(activeFeedData?.source === 'NTTF' || activeFeedData.source === localStorage.getItem('company')) ? `${activeFeedData.url}#t=${startTime},${endTime}` : activeFeedData?.url}
                                                                                    config={{
                                                                                        youtube: {
                                                                                            playerVars: { start: startTime, end: endTime, disablekb: 1, rel: 0 },
                                                                                            embedOptions: {
                                                                                                host: activeFeedData?.url?.includes('youtube') ? 'https://www.youtube-nocookie.com' : '',
                                                                                                // events: {
                                                                                                //     'onStateChange': this.onStateChange
                                                                                                // }
                                                                                            }

                                                                                        }
                                                                                    }}
                                                                                    controls playing={true} autoPlay width='65%' height={320} />

                                                                            :
                                                                            (activeFeedData?.source !== "Vimeo" || activeFeedData.source !== "vimeo.com" || activeFeedData?.source !== "Youtube" || activeFeedData.source !== "youtube.com" || activeFeedData.source !== "YouTube" || activeFeedData.source !== "youtu.be") ?
                                                                                <ReactPlayer url={`${activeFeedData?.url}#t=${startTime},${endTime}`}
                                                                                    config={{
                                                                                        youtube: {
                                                                                            playerVars: { start: startTime, end: endTime, disablekb: 1, rel: 0 },
                                                                                            embedOptions: {
                                                                                                host: activeFeedData?.url?.includes('youtube') ? 'https://www.youtube-nocookie.com' : '',
                                                                                                // events: {
                                                                                                //     'onStateChange': this.onStateChange
                                                                                                // }
                                                                                            }

                                                                                        }
                                                                                    }}
                                                                                    controls playing={true} autoPlay width='65%' height={320}
                                                                                />
                                                                                : null :
                                                                    <img
                                                                        src={activeFeedData !== undefined && activeFeedData?.image ? activeFeedData?.source === "tunein" ? "/images/error-image.jpg" : activeFeedData?.image : "/images/error-image.jpg"} alt=''
                                                                        width='550px' height='320px'
                                                                        onError={(e) => {
                                                                            if (e.target.src !== "/images/error-image.jpg") {
                                                                                e.target.onerror = "/images/error-image.jpg";
                                                                                e.target.src = "/images/error-image.jpg";
                                                                            }
                                                                        }} />

                                                        }
                                                    </span>

                                                    {window.location.pathname.split('/')[1] !== 'manage-content' && window.location.pathname.split('/')[1] !== 'my-learning' && window.location.pathname.split('/')[1] !== 'my-content' && window.location.pathname.split('/')[1] !== 'skill-pods' &&
                                                        window.location.pathname.split('/')[1] !== "attachment" &&
                                                        <ul className='like-btn justify-content-center' >
                                                            <li className={activeFeedData?.liked ? "liked-active" : null}
                                                                onClick={() => this.props.likeClick(activeFeedData?.id || activeFeedData?._id, activeFeedData?.type, activeFeedData?.liked)}>
                                                                {
                                                                    activeFeedData?.liked ?
                                                                        <img src="/images/enable-like.svg" alt='' width='15px' /> :
                                                                        <img src="/images/disable-like.svg" alt='' width='15px' />
                                                                }&nbsp;
                                                                {
                                                                    activeFeedData?.liked ? "Liked" : "Like"
                                                                }
                                                            </li>
                                                            {/* <li>
                                                    <img src='/images/bookmark-disable.svg' width='10px' alt='' /> Save
                                                </li> */}
                                                            <li onClick={() => this.props.shareFeed(activeFeedData)}>
                                                                <img src='/images/share1.svg' alt='' width='15px' /> Share
                                                            </li>
                                                        </ul>}
                                                    {
                                                        window.location.pathname.split('/')[1] !== 'manage-content' && window.location.pathname.split('/')[1] !== 'my-content' && window.location.pathname.split('/')[1] !== 'search' && window.location.pathname.split('/')[1] !== 'skill-pods' &&
                                                        !window.location.href?.includes('_id') && <div className='save-notes mt-4 w-100' style={{ width: window.innerWidth > 767 ? '64%' : '97.5%' }}>
                                                            {
                                                                activeFeedData?.note !== null
                                                                    ? <Fragment>
                                                                        {activeFeedData?.note !== null ? <label>Your notes</label> : ''}
                                                                        {
                                                                            isEdit ?
                                                                                <textarea
                                                                                    rows="3" cols="200"
                                                                                    className={isEdit ? 'form-control' : 'form-control input-edit'}
                                                                                    value={activeFeedData?.note}
                                                                                    onChange={(e) => this.changeNotes(e)}
                                                                                    maxLength={this.state.limWords}
                                                                                    placeholder='Enter your notes here'
                                                                                    id='note'
                                                                                /> :
                                                                                <div>
                                                                                    {activeFeedData?.note}
                                                                                </div>
                                                                        }

                                                                        {this.state.saveError ? <span style={{ color: 'red' }}>{this.state.saveErrorMsg}</span> : null}
                                                                        <div className={isEdit ? 'removeWidth' : 'addWidth'}>

                                                                            {
                                                                                !isEdit ? <p onClick={() => this.editNote()} className="float-left p-1 cursor-pointer">Edit</p> :
                                                                                    <p>&nbsp;<button style={{ background: 'white', border: 'none', fontWeight: 700, color: '#F5821F' }} onClick={() => this.saveNote1(activeFeedData._id, activeFeedData.type, activeFeedData.topic, activeFeedData.subject, activeFeedData.program, this.state.noteValue)}>Save</button>
                                                                                        &nbsp;<button style={{ background: 'white', border: 'none', fontWeight: 700, color: '#F5821F' }} onClick={() => this.cancel()}>Cancel</button>
                                                                                    </p>

                                                                            }
                                                                            {isEdit ?
                                                                                <span className='float-right p-1'>{result}</span>
                                                                                : null
                                                                            }
                                                                            {tooManyChars}
                                                                        </div>

                                                                    </Fragment> :
                                                                    <Fragment>
                                                                        {
                                                                            isEditNo ?
                                                                                <textarea
                                                                                    rows="3" cols="200"
                                                                                    className={isEditNo ? 'form-control' : 'form-control input-edit'}
                                                                                    value={activeFeedData?.note}
                                                                                    onChange={(e) => this.changeNotes(e)}
                                                                                    maxLength={this.state.limWords}
                                                                                    placeholder='Enter your notes here'
                                                                                    id='note'
                                                                                />
                                                                                :
                                                                                <div>
                                                                                    {activeFeedData?.note}
                                                                                </div>
                                                                        }

                                                                        {this.state.saveError ? <span style={{ color: 'red' }}>{this.state.saveErrorMsg}</span> : null}
                                                                        <div className={isEditNo ? 'removeWidth' : 'addWidth'}>
                                                                            {!isEditNo ? <p onClick={() => this.editNote()} className="float-left p-1 cursor-pointer">  Edit</p> :
                                                                                <button style={{ background: 'white', border: 'none', color: '#F5821F', fontWeight: 700 }} onClick={() => this.saveNote1(activeFeedData._id, activeFeedData.type, activeFeedData.topic, activeFeedData.subject, activeFeedData.program, this.state.noteValue)}>Save</button>
                                                                            }
                                                                            {isEdit ?
                                                                                <span className='float-right p-1'>{result}</span>
                                                                                : null
                                                                            }
                                                                            {tooManyChars}
                                                                        </div>
                                                                    </Fragment>
                                                            }

                                                        </div>}

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                    }
                </div>
                }


            </div >

        )
    }
}



export default Notespopup;

