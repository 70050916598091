/* eslint-disable no-unused-vars */
import {
    RESET_TABS, GET_ACTIVE_BTN, GET_AFFLIATIONS, HIDE_SIDEBAR, SHOW_SIDEBAR, OPEN_ADD_CLASSROOM, BACK_TO_CLASSROOM, RESET_PROGRAM_LOADING,
    GET_COURSE_LIST, ADD_COURSE, UPDATE_COURSE, UPDATE_COURSE_STATUS, DELETE_CLASSROOM,
    OPEN_ADD_TO_COURSE, CLOSE_ADD_TO_COURSE, GET_SUBJECT_FROM_COURSE, GET_CHECKED, SELECT_ONE, ADD_SUB_TO_COURSE,
    OPEN_ADD_SUBJECT, CLOSE_ADD_SUBJECT, GET_SUBJECT_LIST, GET_NEXT_SUBJECT_LIST, ADD_SUBJECT, UPDATE_SUBJECT, UPDATE_SUBJECT_STATUS, DELETE_SUBJECT, SUBJECT_CLONE, GET_ALL_SUBJECT,
    GET_CHAPTERS_LIST, ADD_CHAPTER, UPDATE_CHAPTER, UPDATE_CHAPTER_STATUS, DELETE_CHAPTER, REORDER_CHAPTER, OPEN_ADD_INFO, BACK_TO_CHAPTER, ADD_SUBJECT_BRIEF,
    GET_TOPICS_LIST, ADD_TOPIC, UPDATE_TOPIC, UPDATE_TOPIC_STATUS, DELETE_TOPIC, REORDER_TOPIC,
    GET_TOPIC_DETAILS,
    ADD_PARAMS, GET_TOPICS_SUBTOPICS, RESET_RESOURCE_LOADING, GET_RESOURCE_LIST, UPDATE_RESOURCE_STATUS, REORDER_TOPIC_RESOURCES, ADD_RESOURCE, UPDATE_RESOURCE, RESET_PERCENTAGE, GET_DOCUMENT_STATE, GET_DOCUMENT_PERCENT,
    GET_PRACTICE_TESTS, SEARCH_TEST, ADD_PRACTICE_TEST, UPDATE_TEST_STATUS,
    GET_SUBTOPIC_QUIZ_LIST,
    GET_CLUBBED_QUESTION_LIST, GET_QUESTION_FROM_SUBJECT, UPDATE_QUESTION_STATUS, GET_FILTER_SUBJECT, GET_FILTER_QUESTION, GET_QUESTION_DETAILS, EDIT_QUESTION,
    SET_QUES_TITLE, SET_CHOICE1, SET_CHOICE2, SET_CHOICE3, SET_CHOICE4, SET_EXPLANTION, GET_SUBJECT_CHAPTER, ADD_QUESTION,
    ADD_QUESTION_IN_QUIZ, SELECT_OR_DELETE_QUESTION_QUIZ, Delete_All_Question_Quiz, ADD_NEW_QUIZ, GET_QUIZ_QUESTION,
    GET_PREVIEW, NULL_PREVIEW,
    DELETE_COURSE,
} from "../Types/types";
import { deleteApiCall, getApiCall, postApiCall, previewCall, putApiCall } from "../../utils/request";
import { returnErrors } from "./errorAction";
import axios from 'axios'


export const resetTabs = () => {
    return {
        type: RESET_TABS,
    };
};

export const getActiveBtn = (e) => async (dispatch) => {
    return dispatch({ type: GET_ACTIVE_BTN, payload: e });
};

export const getAffliations = () => async (dispatch) => {
    try {
        const response = localStorage.getItem('role') === 'master-admin' ? await getApiCall(`utility/affiliations`) : await getApiCall(`utility/school/affiliations`);
        // /school/affiliations
        return dispatch({ type: GET_AFFLIATIONS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};


// hide side bar
export const hideSideBar = () => async (dispatch) => {
    return dispatch({ type: HIDE_SIDEBAR });
};
export const showSideBar = () => async (dispatch) => {
    return dispatch({ type: SHOW_SIDEBAR });
};

// Programs

export const openAddProgram = () => {
    return {
        type: OPEN_ADD_CLASSROOM,
    };
};

export const backToProgram = () => {
    return {
        type: BACK_TO_CLASSROOM
    };
};

export const resetLoader = () => async (dispatch) => {
    return dispatch({ type: RESET_PROGRAM_LOADING });
};


export const getCourseList = (query) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/course?query=${query ? query : ''}`);
        dispatch({ type: GET_COURSE_LIST, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};


export const savedClassroom = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`admin/course`, obj);
        if (response.data.success) {
            returnErrors(dispatch, 'Course added', response?.status);
            dispatch({ type: ADD_COURSE, payload: response.data.data });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const updateClassroom = (obj) => async (dispatch) => {
    try {
        const response = await putApiCall(`admin/course`, obj);
        if (response.data.success) {
            returnErrors(dispatch, 'Course updated', response?.status);
            dispatch({ type: UPDATE_COURSE, payload: response.data.data });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const updateStatusClassroom = (obj) => async (dispatch) => {
    try {
        const response = await putApiCall(`admin/course/toggle`, obj);
        if (response.data.success) {
            returnErrors(dispatch, obj.active ? 'Course activated' : 'Course deactivated', response?.status);
            dispatch({ type: UPDATE_COURSE_STATUS, payload: obj });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const confirmDeleteClassroom = (id) => async (dispatch) => {
    let obj = { course_id: id }
    try {
        const response = await deleteApiCall(`admin/course`, obj);
        if (response.data.success) {
            returnErrors(dispatch, 'Course deleted', response?.status);
            dispatch({ type: DELETE_COURSE, payload: id });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const addToClassroom = () => async (dispatch) => {
    return dispatch({ type: OPEN_ADD_TO_COURSE });
};

export const backToSubject = () => async (dispatch) => {
    return dispatch({ type: CLOSE_ADD_TO_COURSE });
};


//Subjects


export const getSubjectFromClassroom = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/course/subject?course_id=${id}`);
        const data = response.data.data;
        dispatch({ type: GET_SUBJECT_FROM_COURSE, payload: { data, id } });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const getCheckedIds = (data) => async (dispatch) => {
    return dispatch({ type: GET_CHECKED, payload: data })

};


export const checkOne = (id, checked) => async (dispatch) => {
    try {
        return dispatch({ type: SELECT_ONE, payload: { id, checked } })
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const addSubjectToClassroom = (items) => async (dispatch) => {
    try {
        const response = await putApiCall(`admin/course/subject`, items);
        if (response.data.success) {
            returnErrors(dispatch, 'Subjects added to course', response?.status);
            dispatch({ type: ADD_SUB_TO_COURSE });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};


export const openAddSubject = (sub, val) => {
    return {
        type: OPEN_ADD_SUBJECT,
        payload: { sub, val },
    };
};

export const handleModalClose = () => {
    return {
        type: CLOSE_ADD_SUBJECT,
    };
};

export const getSubjectList = (page, query) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/resource/subject?page=${page}&query=${query}`);
        dispatch({ type: GET_SUBJECT_LIST, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const getNextSubjectList = (page, query) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/resource/subject?page=${page}&query=${query}`);
        dispatch({ type: GET_NEXT_SUBJECT_LIST, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const savedSubject = (obj) => async (dispatch) => {
    let data = new FormData();
    data.append("name", obj?.name);
    data.append("affiliation_id", obj.affiliation_id);
    data.append('class', obj.class)
    data.append("image", obj.image);
    data.append('description', obj.description);

    try {
        const response = await postApiCall(`admin/resource/subject`, data);
        if (response.data.success) {
            returnErrors(dispatch, 'Subject added', response?.status);
            dispatch({ type: ADD_SUBJECT, payload: response.data.data });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const updateSubject = (obj) => async (dispatch) => {
    let data = new FormData();
    data.append("subject_id", obj._id);
    obj.name !== undefined && data.append("name", obj.name);
    obj.image !== undefined && data.append("image", obj.image);
    data.append("affiliation_id", obj.affiliation_id);
    data.append('class', obj.class)
    data.append('active', obj.active);
    data.append('description', obj.description);

    try {
        const response = await putApiCall(`admin/resource/subject`, data);
        if (response.data.success) {
            returnErrors(dispatch, 'Subject updated', response?.status);
            dispatch({ type: UPDATE_SUBJECT, payload: response.data.data });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const updateStatusSubject = (obj) => async (dispatch) => {
    try {
        const response = await putApiCall(`admin/resource/subject/toggle`, obj);
        if (response.data.success) {
            returnErrors(dispatch, obj.active ? 'Subject activated' : 'Subject deactivated', response?.status);
            dispatch({ type: UPDATE_SUBJECT_STATUS, payload: obj });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const confirmDelete = (id) => async (dispatch) => {
    let obj = { subject_id: id }
    try {
        const response = await deleteApiCall(`admin/resource/subject`, obj);
        if (response.data.success) {
            returnErrors(dispatch, 'Subject deleted', response?.status);
            dispatch({ type: DELETE_SUBJECT, payload: id });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const getAllSubjects = (page, query) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/resource/list/subjects`);
        dispatch({ type: GET_ALL_SUBJECT, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const cloneSubject = (id) => async (dispatch) => {
    let obj = {
        subject_id: id
    }
    try {
        const response = await postApiCall(`admin/resource/subject/clone`, obj);
        if (response.data.success) {
            return dispatch({ type: SUBJECT_CLONE });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};


//Chapters

export const getChapters = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/resource/subject/chapter?subject_id=${id}`);
        const data = response.data.data;
        dispatch({ type: GET_CHAPTERS_LIST, payload: { data, id } });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const savedChapter = (obj) => async (dispatch) => {
    let data = new FormData();
    data.append("name", obj.name);
    data.append("subject_id", obj.subject_id);
    data.append("image", obj.image);
    data.append('description', obj.description);

    try {
        const response = await postApiCall(`admin/resource/subject/chapter`, data);
        if (response.data.success) {
            const data = response.data.data;
            const id = obj.subject_id;
            returnErrors(dispatch, 'Chapter added', response?.status);
            return dispatch({ type: ADD_CHAPTER, payload: { data, id } });
        } else {
            returnErrors(
                dispatch, response?.data?.message, response?.status
            );
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const updateChapter = (obj) => async (dispatch) => {
    let data = new FormData();
    data.append("chapter_id", obj._id);
    obj.name !== undefined && data.append("name", obj.name);
    obj.image !== undefined && data.append("image", obj.image);
    data.append('active', obj.active);
    data.append('description', obj.description);

    try {
        const response = await putApiCall(`admin/resource/subject/chapter`, data);
        if (response.data.success) {
            returnErrors(dispatch, 'Chapter updated', response?.status);
            return dispatch({ type: UPDATE_CHAPTER, payload: response.data.data });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const updateStatusChapter = (obj) => async (dispatch) => {
    try {
        const response = await putApiCall(`admin/resource/subject/chapter/toggle`, obj);
        if (response.data.success) {
            returnErrors(dispatch, obj.active ? 'Chapter activated' : 'Chapter deactivated', response?.status);
            dispatch({ type: UPDATE_CHAPTER_STATUS, payload: obj });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const confirmDeleteChapter = (id) => async (dispatch) => {
    let obj = { chapter_id: id }
    try {
        const response = await deleteApiCall(`admin/resource/subject/chapter`, obj);
        if (response.data.success) {
            returnErrors(dispatch, 'Chapter deleted', response?.status);
            dispatch({ type: DELETE_CHAPTER, payload: id });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const reorderChapter = (result) => async (dispatch) => {
    return dispatch({ type: REORDER_CHAPTER, payload: result });
};
export const reorderedChapter = (sortArr) => async (dispatch) => {
    try {
        const response = await putApiCall(`admin/resource/subject/chapter/reorder`, sortArr);
        if (response.data.success) {

        }
        else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const openAddInfo = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/resource/subject/brief?subject_id=${id}`);
        const data = response.data.data;
        return dispatch({ type: OPEN_ADD_INFO, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const savedSubjectBrief = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`admin/resource/subject/brief`, obj);
        if (response.data.success) {
            returnErrors(dispatch, 'Subject brief added', response?.status);
            return dispatch({ type: ADD_SUBJECT_BRIEF, payload: response.data.data });
        } else {
            returnErrors(
                dispatch, response?.data?.message, response?.status
            );
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const backToTopic = () => async (dispatch) => {
    return dispatch({ type: BACK_TO_CHAPTER });
};


//topics

export const getTopics = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/resource/subject/chapter/topic?chapter_id=${id}`);
        const data = response.data.data;
        dispatch({ type: GET_TOPICS_LIST, payload: { data, id } });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const savedTopic = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`admin/resource/subject/chapter/topic`, obj);
        if (response.data.success) {
            const data = response.data.data;
            const id = obj.subject_id;
            returnErrors(dispatch, 'Topic added', response?.status);
            return dispatch({ type: ADD_TOPIC, payload: { data, id } });
        } else {
            returnErrors(
                dispatch, response?.data?.message, response?.status
            );
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const updateTopic = (obj) => async (dispatch) => {
    try {
        const response = await putApiCall(`admin/resource/subject/chapter/topic`, obj);
        if (response.data.success) {
            returnErrors(dispatch, 'Topic updated', response?.status);
            return dispatch({ type: UPDATE_TOPIC, payload: response.data.data });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const updateStatusTopic = (obj) => async (dispatch) => {
    try {
        const response = await putApiCall(`admin/resource/subject/chapter/topic/toggle`, obj);
        if (response.data.success) {
            returnErrors(dispatch, obj.active ? 'Topic activated' : 'Topic deactivated', response?.status);
            dispatch({ type: UPDATE_TOPIC_STATUS, payload: obj });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const confirmDeleteTopic = (id) => async (dispatch) => {
    let obj = { topic_id: id }
    try {
        const response = await deleteApiCall(`admin/resource/subject/chapter/topic`, obj);
        if (response.data.success) {
            returnErrors(dispatch, 'Topic deleted', response?.status);
            dispatch({ type: DELETE_TOPIC, payload: id });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const reorderTopic = (result) => async (dispatch) => {
    return dispatch({ type: REORDER_TOPIC, payload: result });
};
export const reorderdTopic = (sortArr) => async (dispatch) => {
    try {
        const response = await putApiCall(`admin/resource/subject/chapter/topic/reorder`, sortArr);
        if (response.data.success) {

        }
        else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

//Subtopic resources

export const addParams = (chapterId, chapter, topic, topicId) => async (dispatch) => {
    dispatch({
        type: ADD_PARAMS,
        payload: { chapterId, chapter, topic, topicId }
    })
}

export const getTopicListOfSubject = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/resource/subject/chapter/topics?subject_id=${id}`);
        const data = response.data.data;
        dispatch({ type: GET_TOPICS_SUBTOPICS, payload: { data, id } });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const getResourcesList = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/resource/topic?topic_id=${id}`);
        if (response.data.success) {
            dispatch({ type: GET_RESOURCE_LIST, payload: response.data.data });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const updateResourceStatus = (obj) => async (dispatch) => {
    let data = {
        r_id: obj.r_id,
        topic_id: obj.topic_id
    }
    try {
        const response = await putApiCall(`admin/resource/toggle`, data);
        if (response.data.success) {
            returnErrors(dispatch, obj.checked ? 'Resource activated' : 'Resource deactivated', response?.status);
            dispatch({ type: UPDATE_RESOURCE_STATUS, payload: obj });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const reorderTopicsResources = (result) => async (dispatch) => {
    return dispatch({ type: REORDER_TOPIC_RESOURCES, payload: result })
};

export const reorderdTopicsResources = (topicid, sortArr) => async (dispatch) => {
    let obj = {
        topic_id: topicid,
        data: sortArr
    }
    try {
        await putApiCall(`admin/resource/reorder`, obj);
    }
    catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const saveArticle = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`admin/resource/topic/article`, obj);
        if (response.data.success) {
            returnErrors(dispatch, 'Article added', response?.status);
            return dispatch({ type: ADD_RESOURCE, payload: response.data.data });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const addVideoorDocument = (obj) => async (dispatch) => {
    try {
        let response
        if (obj.type === 'Video') {
            response = await postApiCall(`admin/resource/topic/video`, obj);
        } else {
            response = await postApiCall(`admin/resource/topic/document`, obj);
        }
        if (response.data.success) {
            returnErrors(dispatch, obj.type === 'Video' ? 'Video added' : 'Document added', response?.status);
            return dispatch({ type: ADD_RESOURCE, payload: response.data.data });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

//Upload document
export const uploadDocument = (obj) => async (dispatch) => {
    const AuthStr = "Bearer " + localStorage.getItem("loginToken");
    dispatch({ type: GET_DOCUMENT_STATE, payload: true });
    try {
        let percent = 0;
        const config = {
            headers: { Authorization: AuthStr },
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent
                percent = Math.round((loaded * 100) / total);
                if (percent < 98) {
                    return dispatch({ type: GET_DOCUMENT_PERCENT, payload: percent })
                }
                else {
                    return dispatch({ type: GET_DOCUMENT_PERCENT, payload: 98 })
                }
            }
        }
        await axios.post(process.env.REACT_APP_API_KEY + 'admin/resource/topic/document/upload', obj, config)
            .then((response) => {
                if (response.data.success) {
                    dispatch({ type: GET_DOCUMENT_PERCENT, payload: 100 })
                    dispatch({ type: GET_DOCUMENT_STATE, payload: false });
                    dispatch({ type: ADD_RESOURCE, payload: response.data.data });
                    returnErrors(dispatch, 'Document Uploaded', response?.status);
                } else {
                    dispatch({ type: GET_DOCUMENT_STATE, payload: false });
                    returnErrors(dispatch, response?.data?.message, response?.status);
                }
            });
    }
    catch (error) {
        dispatch({ type: GET_DOCUMENT_STATE, payload: false });
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }

};

export const resetPercentage = () => async (dispatch) => {
    dispatch({
        type: RESET_PERCENTAGE
    })
}

//Upload video
export const uploadVideo = (obj) => async (dispatch) => {
    const AuthStr = "Bearer " + localStorage.getItem("loginToken");
    dispatch({ type: GET_DOCUMENT_STATE, payload: true });
    try {
        let percent = 0;
        const config = {
            headers: { Authorization: AuthStr },
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent
                percent = Math.round((loaded * 100) / total);
                if (percent < 98) {
                    return dispatch({ type: GET_DOCUMENT_PERCENT, payload: percent })
                }
                else {
                    return dispatch({ type: GET_DOCUMENT_PERCENT, payload: 98 })
                }
            }
        }
        await axios.post(process.env.REACT_APP_API_KEY + 'admin/resource/topic/video/upload', obj, config)
            .then((response) => {
                if (response.data.success) {
                    dispatch({ type: GET_DOCUMENT_PERCENT, payload: 100 })
                    dispatch({ type: GET_DOCUMENT_STATE, payload: false });
                    dispatch({ type: ADD_RESOURCE, payload: response.data.data });
                    returnErrors(dispatch, 'Video Uploaded', response?.status);
                } else {
                    dispatch({ type: GET_DOCUMENT_STATE, payload: false });
                    returnErrors(dispatch, response?.data?.message, response?.status);
                }
            });
    }
    catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};




export const updateVideoorArticles = (obj) => async (dispatch) => {
    if (obj.get('type') === 'Article') {
        try {
            const response = await putApiCall(`admin/resource`, obj);
            if (response.data.success) {
                returnErrors(dispatch, 'Article updated', response?.status);
                dispatch({ type: UPDATE_RESOURCE, payload: response.data.data });
            } else {
                returnErrors(
                    dispatch, response?.data?.message, response?.status
                );
            }
        } catch (error) {
            returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
        }
    }
    else {
        dispatch({ type: GET_DOCUMENT_STATE, payload: true });
        const AuthStr = "Bearer " + localStorage.getItem("loginToken");
        try {
            let percent = 0;
            const config = {
                headers: { Authorization: AuthStr },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent
                    percent = Math.round((loaded * 100) / total);
                    if (percent < 98) {
                        return dispatch({ type: GET_DOCUMENT_PERCENT, payload: percent })
                    }
                    else {
                        return dispatch({ type: GET_DOCUMENT_PERCENT, payload: 98 })
                    }
                }
            }
            await axios.put(process.env.REACT_APP_API_KEY + 'admin/resource', obj, config)
                .then((response) => {
                    if (response.data.success) {
                        dispatch({ type: GET_DOCUMENT_PERCENT, payload: 100 })
                        dispatch({ type: GET_DOCUMENT_STATE, payload: false });
                        dispatch({ type: UPDATE_RESOURCE, payload: response.data.data });
                    } else {
                        dispatch({ type: GET_DOCUMENT_STATE, payload: false });
                        returnErrors(dispatch, response?.data?.message, response?.status);
                    }
                });
        }
        catch (error) {
            dispatch({ type: GET_DOCUMENT_STATE, payload: false });
            returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
        }
    }
};

// Question bank

export const getClubbedQuestionList = () => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/resource/subject/questions`);
        dispatch({ type: GET_CLUBBED_QUESTION_LIST, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const getQuestionFromSubject = (subjectId, type) => async (dispatch) => {
    try {
        if (subjectId) {
            const response = await getApiCall(`admin/resource/question?subject_id=${subjectId !== undefined ? subjectId : ''}&type=${type !== undefined ? type : ''}`);
            dispatch({ type: GET_QUESTION_FROM_SUBJECT, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}



export const changeQuestionStatus = (detail) => async (dispatch) => {
    const obj = {
        question_id: detail.question_id
    }
    try {
        const response = await putApiCall(`admin/resource/question/toggle`, obj);
        if (response.data.success) {
            returnErrors(dispatch, obj.active ? 'Question activated' : 'Question deactivated', response?.status);
            dispatch({ type: UPDATE_QUESTION_STATUS, payload: detail });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const setTitle = (val) => async (dispatch) => {
    dispatch({
        type: SET_QUES_TITLE,
        payload: val
    })
}

export const setExplanation = (val) => async (dispatch) => {
    dispatch({
        type: SET_EXPLANTION,
        payload: val
    })
}

export const setChoice1 = (val) => async (dispatch) => {
    return dispatch({
        type: SET_CHOICE1,
        payload: val
    })
}

export const setChoice2 = (val) => async (dispatch) => {
    return dispatch({
        type: SET_CHOICE2,
        payload: val
    })
}

export const setChoice3 = (val) => async (dispatch) => {
    return dispatch({
        type: SET_CHOICE3,
        payload: val
    })
}

export const setChoice4 = (val) => async (dispatch) => {
    return dispatch({
        type: SET_CHOICE4,
        payload: val
    })
}

export const getChapterOfSub = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/learning/course/subjects/chapters?subject_id=${id}`);
        dispatch({ type: GET_SUBJECT_CHAPTER, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const savedQuestions = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`admin/resource/question`, obj);
        if (response.data.success) {
            returnErrors(dispatch, 'Question added', response?.status);
            if (obj.isAddQuiz === true) {
                return dispatch({ type: ADD_QUESTION_IN_QUIZ, payload: response.data.data });
            } else {
                return dispatch({ type: ADD_QUESTION, payload: response.data.data });
            }
        } else {
            returnErrors(
                dispatch, response?.data?.message, response?.status
            );
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const getQuestionForEdit = (type, el) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/resource/question/details?question_id=${el._id}`);
        console.log(response.data.data)
        const obj = { ...response.data.data[0], ...el, type: response.data.data[0]?.type }
        return dispatch({ type: GET_QUESTION_DETAILS, payload: obj });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const editedQuestion = (obj) => async (dispatch) => {
    try {
        const response = await putApiCall(`admin/resource/question`, obj);
        if (response.data.success) {
            // dispatch(getQuestionList())
            dispatch({ type: EDIT_QUESTION, payload: response.data.data });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

//Quizzes and Practice tests

export const getPracticeTestList = (type, id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/resource/quiz?type=${type}&subject_id=${id}`);
        dispatch({ type: GET_PRACTICE_TESTS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const getFilterTest = (val) => async (dispatch) => {
    return dispatch({
        type: SEARCH_TEST,
        payload: val
    })
}


export const changeTestStatus = (detail) => async (dispatch) => {
    const obj = {
        quiz_id: detail?.quiz_id
    }
    try {
        const response = await putApiCall(`admin/resource/quiz/toggle`, obj);
        if (response.data.success) {
            returnErrors(dispatch, obj.active ? 'Practice test activated' : 'Practice test deactivated', response?.status);
            dispatch({ type: UPDATE_TEST_STATUS, payload: detail });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const getSubtopicQuizList = (subjectId, type) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/resource/quiz?subject_id=${subjectId}&type=${type}`);
        dispatch({ type: GET_SUBTOPIC_QUIZ_LIST, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};




export const addQuizQuestions = (data) => async (dispatch) => {
    dispatch({ type: ADD_QUESTION_IN_QUIZ, payload: data });
};

export const selectOrRemoveQuestions = (data) => async (dispatch) => {
    dispatch({ type: SELECT_OR_DELETE_QUESTION_QUIZ, payload: data });
};

export const deleteQuizQuestions = (data) => async (dispatch) => {
    dispatch({ type: Delete_All_Question_Quiz, payload: data });
};

export const savedNewQuiz = (obj, type) => async (dispatch) => {
    try {
        const response = await postApiCall(`admin/resource/quiz`, obj);
        if (response.data.success) {
            if (type === 'quiz') {
                returnErrors(dispatch, 'Quiz added', response?.status);
                dispatch({ type: ADD_NEW_QUIZ, payload: response.data.data });
            } else {
                returnErrors(dispatch, 'Practice test added', response?.status);
                dispatch({ type: ADD_PRACTICE_TEST, payload: response.data.data });
            }
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};



export const savedNewQuizByID = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`admin/resource/topic/quiz`, obj);
        if (response.data.success) {
            dispatch({ type: ADD_RESOURCE, payload: response.data.data });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const getQuizQuestion = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/resource/quiz/questions?_id=${id}`);
        dispatch({ type: GET_QUIZ_QUESTION, payload: response.data.data[0] });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const editedQuiz = (obj, type) => async (dispatch) => {
    try {
        const response = await putApiCall(`admin/resource/quiz`, obj);
        if (response.data.success) {
            returnErrors(dispatch, type === 'quiz' ? 'Quiz updated' : 'Practice test updated', response?.status);
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};













//Link Preview

export const googleLinkPreview = (obj) => async (dispatch) => {
    try {
        const response = await previewCall(`https://api.linkpreview.net`, {
            key: process.env.REACT_APP_LINK_PREVIEW,
            fields: 'site_name',
            q: obj,
        });
        if (response) {
            return dispatch({ type: GET_PREVIEW, payload: response.data });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}
export const googleLinkPreviewSetNull = (data) => async (dispatch) => {
    dispatch({ type: NULL_PREVIEW, payload: "" });
};



export const getSubTopics = () => async (dispatch) => {
    try {
        // const response = await putApiCall(`admin/course`, obj);
        // if (response.data.success) {
        //     returnErrors(dispatch, 'Course updated', response?.status);
        //     dispatch({ type: UPDATE_COURSE, payload: response.data.data });
        // } else {
        //     returnErrors(dispatch, response?.data?.message, response?.status);
        // }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const getTopicsOfSub = () => async (dispatch) => {
    try {
        // const response = await putApiCall(`admin/course`, obj);
        // if (response.data.success) {
        //     returnErrors(dispatch, 'Course updated', response?.status);
        //     dispatch({ type: UPDATE_COURSE, payload: response.data.data });
        // } else {
        //     returnErrors(dispatch, response?.data?.message, response?.status);
        // }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};




















































export const setDate = (date, id) => async (dispatch) => {
    return dispatch({ type: 'Set_Date', payload: { date, id } })
}
export const savedDate = (result) => async (dispatch) => {
    const obj = {
        data: result
    }
    try {
        const response = await postApiCall(`admin/manage-content/subject/add-duedate`, obj);
        if (response.data.success) {
            dispatch({ type: "Save_Schedule_Date", });
        }
        else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    }
    catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

//subtopics


export const getTopicObject = (id, topicId) => async (dispatch) => {
    try {
        const response = await getApiCall(
            `admin/manage-content/subject/get-topics?subject_id=${id}`
        );
        const data = response.data.data;
        dispatch({ type: GET_TOPIC_DETAILS, payload: { data, topicId } });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};



//Question Bank
export const getQuestionList = () => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/my-content/question/list`);
        dispatch({ type: "Get_Question_List", payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};









//GET Quiz


//reorder SubTopics

export const reorederSubTopicsProps = (data) => async (dispatch) => {
    dispatch({ type: "Reorder_Table_Data", payload: data });
};



//reorder SubTopics Resources



export const resetResourceLoading = () => async (dispatch) => {
    return dispatch({ type: RESET_RESOURCE_LOADING })
}



//toggle status SUbTopic resources
export const changeResourceStatus = (topicId, id, checked) => async (dispatch) => {
    let obj = {
        topic_id: topicId,
        _id: id
    }
    try {
        const response = await postApiCall(
            `admin/my-content/toggle-resource`,
            obj
        );
        if (response.data.success) {
            dispatch({ type: "Change_SubTopic_Resource_Status", payload: { id, checked } });
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

//Add Practice
export const addPracticeTest = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(
            `admin/my-content/quiz/add-practice`,
            obj
        );
        if (response.data.success) {
            dispatch(getPracticeTestList());
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};

export const editPracticeTest = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`admin/my-content/quiz/edit-practice`, obj);
        if (response.data.success) {
            dispatch(getPracticeTestList());
        } else {
            returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
};
export const deleteDocumentData = (data) => async (dispatch) => {
    dispatch({ type: "Null_Documents", payload: "" });
};

















export const getResourcesForType = (type, topicId, subjectId) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/my-content/staff-resources?type=${type}&subject_id=${subjectId}&topic_id=${topicId}`);
        const data = response.data.data
        dispatch({ type: "Get_Resources_List_For_Type", payload: { data, type } });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const addToResource = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`admin/my-content/add-resource`, obj);
        dispatch({ type: "Add_Resources_To_List", payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getImages = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`admin/my-content/getUnitImages?subject_id=${id}`);
        dispatch({ type: "Get_Gallery_Images", payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getFilterSubject = (val) => async (dispatch) => {
    return dispatch({ type: GET_FILTER_SUBJECT, payload: val });
}


export const getSearchQuestion = (val) => async (dispatch) => {
    return dispatch({ type: GET_FILTER_QUESTION, payload: val });
}

