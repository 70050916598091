import { GET_CONTESTS, CONTEST_STATUS, GET_SCHOOLS } from '../Types/types';

let intialState = {
    contests: []
}

export default function adminContestReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_CONTESTS:
            stateCopy.contests = action.payload;
            return stateCopy;
        case GET_SCHOOLS:
            stateCopy.companies = action.payload.map(el => [{ label: el.name, value: el._id }][0]);
            return stateCopy;
        case CONTEST_STATUS:
            stateCopy.contests.forEach(element => {
                if (element._id === action.payload.id) {
                    element.active = action.payload.active ? false : true
                }
            });
            return stateCopy;
        default:
            return stateCopy;
    }
}
