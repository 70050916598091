import React from 'react';

export default function PrivacyPolicy() {
    return (
        <div className='privacy-policy-holder'>
            <h3>Privacy Policy</h3>
            <div className='policy-desc'>
                <div className='container'>
                    <div className='mt-4 mb-4'>
                        <h4>Introduction</h4>
                        <p>This is a product of EDSANTA EDUCATION PRIVATE LIMITED (EDSANTA INC.), herewith referred to as ‘EdSanta’, respects your rights regarding your privacy and information, and We provide this Privacy Policy ("Privacy Policy") to help you understand how we collect, use, share, store and disclose information we obtain from or about you when you use the Services. This Legal Policy outlines the terms and conditions under which the company’s products and services are provided, ensuring compliance with applicable laws and regulations as per the respective governmental authorities.
                        </p>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>Scope</h4>
                        <p>
                            This policy applies to all employees, vendors, and clients of Edsanta Education Private Limited. It covers all aspects of operations, including data collection, storage, processing, and security measures. This policy does not apply to the practices of third parties that we do not own or control.
                        </p>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>Data Protection and Privacy</h4>
                        <ol>
                            <h5><li>Data Collection</li></h5>
                            <p>Personal and business information necessary for providing Edsanta products and services is collected. This may include, but is not limited to, name, contact details and IP addresses.
                            </p>
                            <h5><li>Data Usage</li></h5>
                            <p>
                                The collected data is used solely for the purposes of delivering, maintaining, and improving the products/services provided. The personal information thus collected is not sold, leased, or otherwise disclosed to third parties without explicit consent, except as required by law.
                            </p>
                            <h5><li>Data Security</li></h5>
                            <p>
                                Robust security measures, including encryption, access controls, and regular security audits are implemented to protect client data from unauthorized access, disclosure, alteration, and destruction.
                            </p>
                            <h5><li>Data Retention</li></h5>
                            <p>
                                Personal data is retained only as long as necessary to fulfill the purposes for which it was collected, or as required by law. The compliances, responsibilities and obligations relating to the storage and destruction of personal data are as per the principles set out in applicable relevant legislation.
                            </p>
                        </ol>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>Contest and Sweepstakes Rules</h4>
                        <ol>
                            <h5><li>Official rules</li></h5>
                            <p>All contests and sweepstakes featured in this app are governed by official rules, which are clearly outlined and accessible to all participants. By entering, users agree to comply with these rules and any decisions made by the contest organizers.
                            </p>
                            <h5><li>Apple’s Disclaimer</li></h5>
                            <p>
                                This contest or sweepstakes is not sponsored, endorsed, or administered by, or associated with, Apple Inc. Any questions, comments, or complaints regarding the contest should be directed to the contest organizer, not Apple.
                            </p>
                        </ol>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>
                            Compliance with Laws and Regulations
                        </h4>
                        <ol>
                            <h5><li>General Compliance</li></h5>
                            <p>
                                Edsanta follows best practices to comply with applicable laws and regulations regarding data protection and cybersecurity.
                            </p>
                            <h5><li>Breach Notification</li></h5>
                            <p>
                                In the event of a data breach, Edsanta will notify affected clients, and the relevant authorities as required by law. Details about the nature of the breach, the data affected, and the measures taken to mitigate the impact will be shared.
                            </p>
                        </ol>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>
                            Intellectual Property
                        </h4>
                        <ol>
                            <h5><li>Ownership</li></h5>
                            <p>
                                All intellectual property rights in the products/services provided, including but not limited to software, documentation, and methodologies, are owned by Edsanta.
                            </p>
                        </ol>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>
                            Confidentiality
                        </h4>
                        <ol>
                            <h5><li>Client Information</li></h5>
                            <p>
                                All client information is treated as confidential. Employees and contractors are required to sign confidentiality agreements and are trained on the importance of maintaining client confidentiality.
                            </p>
                            <h5><li>Non-Disclosure</li></h5>
                            <p>
                                Client information will not be disclosed to third parties except as necessary to provide any services needed, or as required by law.
                            </p>
                        </ol>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>
                            Dispute Resolution
                        </h4>
                        <ol>
                            <h5><li>Governing Law</li></h5>
                            <p>
                                This policy and any disputes arising from it are governed by the laws of India.
                            </p>
                            <h5><li>Arbitration</li></h5>
                            <p>
                                Any disputes arising under this policy will be resolved through binding arbitration in accordance with the rules of India.
                            </p>
                        </ol>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>Amendments</h4>
                        <p>
                            We reserve the right to amend this Legal Policy from time to time. Clients will be notified of any significant changes via email or on our website.
                        </p>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>Contact Information</h4>
                        <p>
                            For any grievances regarding this Legal Policy, please contact the Grievance office.

                        </p>
                        <p><strong> Bhavin Majithia, SVP & Head Operations – India</strong></p>
                        <p><strong>Email id: info@edsanta.com</strong></p>
                        <p>
                            By engaging Edsanta’s products/services, clients acknowledge and agree to the terms outlined in this Privacy Policy.
                        </p>
                        <h5>LAST UPDATED: AUGUST 2024</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}
