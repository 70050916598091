import React, { Component, Fragment, useEffect, useState } from 'react';
import './adminRewards.scss';
import Table from 'react-bootstrap/Table';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from "moment";
// import DateRangePicker from 'rsuite/DateRangePicker';
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery';
import { refreshList, getRedeemList, getNextRedeemList, campusVoucherSent } from '../../Redux/ActionCreators/adminRewardsAction';
import { TopicLoader } from '../../Loader/loader';
import { useDispatch, useSelector } from 'react-redux';
import DateRangePickerWrapper from '../../utils/dateRangePicker';

// const {
//     allowedMaxDays
// } = DateRangePicker;

function convertToDate(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
}


export default function RedeemRewards() {
    const [page, setPage] = useState(1);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [valueDate, setValueDate] = useState(new Date());
    const [search, setSearch] = useState('');
    const [successMsg, setSuccessMsg] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('');
    const [isAddVoucher, setIsAddVoucher] = useState(false);
    const [voucher, setVoucher] = useState('');
    const [isEditNo, setIsEditNo] = useState(true)
    const [rewardDetails, setRewardDetails] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const dispatch = useDispatch();
    const { isLoading, redeemList } = useSelector(state => state.adminReward);

    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        const today = moment();
        const dates = moment.range(today.clone().subtract(30, "days"), today.clone());
        console.log(dates)

        setStartDate(dates?.start.format('DD-MM-YYYY'));
        setEndDate(dates?.end.format('DD-MM-YYYY'));
        setValueDate(moment.range(today.clone().subtract(30, "days"), today.clone()));
        dispatch(refreshList())
        dispatch(getRedeemList(page, startDate, endDate));
    }, [])

    const fetchRedeemList = () => {
        setPage(page + 1);
        dispatch(getNextRedeemList(page + 1, startDate, endDate));
    }

    const onSelect = async (value) => {
        setValueDate(value);
        setStartDate(convertToDate(new Date(value.start)));
        setEndDate(convertToDate(new Date(value.start)));
        setPage(1);
        if (value?.length >= 2) {
            dispatch(getRedeemList(1, convertToDate(value[0], convertToDate(value[1]))))
        }
    }

    const snackClosed = () => {
        setSuccessMsg(false);
        setSuccessMessageText('');
    }


    const openAddModal = (rewards) => {
        setIsAddVoucher(true);
        setRewardDetails(rewards);
        setVoucher('');
        setErrorMessage('');
        $("#mob").prop("disabled", false);
    }

    const handleCloseModal = () => {
        setIsAddVoucher(false);
        setIsEditNo(true);
        setErrorMessage('');
    }


    const changeVoucher = (val) => {
        setVoucher(val);
        setErrorMessage('');
    }


    // ############### save mobile number #################
    const saveMobile = () => {
        if (voucher === '') {
            setErrorMessage('Please enter voucher code');
        }
        else {
            setIsEditNo(false);
            $("#mob").prop("disabled", true);
        }
    }

    const isEditMobile = () => {
        $("#mob").prop("disabled", false);
        setIsEditNo(true);
    }

    // ############### Submit voucher code #################
    const sentVoucher = async (id) => {
        let code = voucher
        if (rewardDetails?.provider === 'Paytm') {
            if (code === '') {
                setErrorMessage('Please enter voucher code')

            }
            else {
                var obj = {
                    _id: id,
                    voucher: voucher
                }
                await dispatch(campusVoucherSent(obj));
                await setIsAddVoucher(false);
                await setIsEditNo(true);
            }
        }
        else {
            let obj = {
                _id: id,
                voucher: voucher
            }
            await dispatch(campusVoucherSent(obj));
            await setIsAddVoucher(false);
            await setIsEditNo(true);
        }
    }


    return (
        <Fragment>
            <div className="user-module">
                <div className="engaged-users">
                    <div className="users-heading">
                        <h3>Redeem List</h3><br />
                        <div className="reward-filters form-group">

                        </div>
                        <div className='filter-date'>
                            <DateRangePickerWrapper
                                onSelect={onSelect}
                                valueDate={valueDate}
                            />
                            {/* <DateRangePicker
                        disabledDate={allowedMaxDays(31)}
                        showOneCalendar={false}
                        showWeekNumbers={false}
                        value={valueDate}
                        placement='bottomEnd'
                        onChange={(value) => onDatesChangeDateValue(value)}
                    /> */}
                        </div>
                    </div>
                    <div className="table-admin">
                        <InfiniteScroll
                            dataLength={redeemList && redeemList?.length || 0} //This is important field to render the next data
                            next={fetchRedeemList}
                            hasMore={true}
                        >
                            {
                                isLoading ?
                                    <TopicLoader /> :
                                    <Table responsive>
                                        <thead>
                                            <tr className="header-table">
                                                <th>Username</th>
                                                <th >Email</th>
                                                <th>Mobile No</th>
                                                <th>Request Date</th>
                                                {/* <th className='text-center'>Company</th> */}
                                                <th className='text-center'>Provider</th>
                                                <th>Value</th>
                                                <th className='text-center'>Status</th>
                                            </tr>
                                        </thead>

                                        <tbody className="table-body">

                                            {
                                                (redeemList !== undefined && redeemList?.length) ?
                                                    redeemList.map((rewards, i5) => {
                                                        return (
                                                            <tr key={i5}>
                                                                <td>{rewards.user_name}</td>
                                                                <td>{rewards.email}</td>
                                                                <td >{rewards.mobile}</td>
                                                                <td >{convertToDate(rewards.createdAt)}</td>
                                                                {/* <td className="center-content">{rewards.company_name}</td> */}
                                                                <td className="center-content">{rewards.provider}</td>
                                                                <td >{rewards.value}</td>
                                                                <td className="cursor-pointer">
                                                                    {
                                                                        rewards.complete === true ?
                                                                            <div onClick={() => { openAddModal(rewards) }} className="d-flex justify-content-center align-items-center"  >
                                                                                <img src='/images/added.svg' className='img-width' alt='' />
                                                                            </div>
                                                                            :
                                                                            <div className="d-flex justify-content-center align-items-center" onClick={() => { openAddModal(rewards) }}>

                                                                                <img src='/images/edit2.svg' alt='' />
                                                                            </div>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan={7}>
                                                            <div className='empty-list'>
                                                                <img src='/images/empty.png' alt='' />
                                                                <p>You have not added any rewards yet.</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                            }

                                        </tbody>
                                    </Table>
                            }
                        </InfiniteScroll>

                    </div>
                </div>
                <Modal
                    show={isAddVoucher}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={handleCloseModal}
                    dialogClassName={"modal-60w"}
                    style={{ backdropFilter: 'none' }}
                >
                    <Modal.Body style={{ padding: '15px 10px 15px 10px' }}>
                        {
                            rewardDetails !== undefined &&
                            <div className="">
                                <div className="row mt-2" style={{ marginLeft: -2 }} >
                                    <div className="col-md-5 col-5" >
                                        <div className='card-details' style={{ width: '82%' }}>
                                            <div className='d-flex justify-content-center align-items-center rounded' style={{ width: '165px', height: '175px', background: rewardDetails?.bgcolor }}>

                                                <img src={rewardDetails?.image} width='100%' className="coins" alt='' />
                                            </div>
                                            <div className="mt-1" style={{ width: '165px' }}>
                                                <p className="value1 float-left">Value: INR {rewardDetails?.value}</p>
                                                {/* <p className=" value2 float-right">Validity: {rewardDetails.validity}</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-7" style={{ marginLeft: -50 }}>
                                        <div className="d-flex justify-content-between  reward-title">
                                            <h2>{rewardDetails !== undefined && rewardDetails?.provider}</h2>
                                        </div>
                                        <p className="details">User Details</p>
                                        <div className="description">
                                            <ul>
                                                <li className='text-capitalize'>Name : {rewardDetails?.user_name}</li>
                                                <li>Email : {rewardDetails?.email}</li>
                                                <li>Mobile No : {rewardDetails?.mobile}</li>
                                                {/* <li>Company : {rewardDetails.company_name}</li> */}
                                                {rewardDetails?.voucher !== undefined && rewardDetails?.voucher !== null ?
                                                    <li>Voucher : {rewardDetails.voucher}</li> : null
                                                }
                                            </ul>
                                        </div>

                                        <Fragment>
                                            {
                                                rewardDetails?.voucher === null ?
                                                    <div className="pt-4 pb-3">
                                                        <div className="pl-2">
                                                            <p style={{ fontSize: 14, fontFamily: 'Roboto', color: '#000000', fontWeight: 600, paddingBottom: 5 }}>Enter Voucher Code</p>
                                                            {
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    <div>
                                                                        <input id="mob" className={isEditNo ? 'form-control' : 'form-control input-edit '} placeholder='Enter voucher code' onChange={(e) => changeVoucher(e.target.value)} value={voucher} />
                                                                    </div>&nbsp;
                                                                    <div>{isEditNo ?
                                                                        <button style={{ background: 'white', border: 'none', color: '#8b7df2' }} onClick={saveMobile}>Save</button>
                                                                        : <div onClick={isEditMobile} className="pl-1 cursor-pointer"> <img src='/images/edit2.svg' alt='' /></div>
                                                                    }
                                                                    </div>
                                                                </div>
                                                            }
                                                            {<span style={{ color: 'red', fontSize: 13, fontFamily: 'Roboto', paddingBottom: 2 }}>{errorMessage}</span>}
                                                        </div>
                                                    </div> : null
                                            }


                                            {
                                                rewardDetails?.voucher === null ?
                                                    <div className="pt-1 pl-1">
                                                        <button onClick={() => { sentVoucher(rewardDetails?._id) }} className="redeem-btn">Send Voucher</button>
                                                    </div> :
                                                    <div className="pt-1 pl-1">
                                                        {
                                                            !rewardDetails?.complete ?
                                                                <button onClick={() => { sentVoucher(rewardDetails?._id) }} className="redeem-btn">Voucher sent</button>
                                                                : null
                                                        }
                                                    </div>


                                            }

                                        </Fragment>


                                    </div>

                                </div>
                            </div>
                        }

                    </Modal.Body>
                </Modal>
            </div>
        </Fragment>
    )
}



