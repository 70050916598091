import { deleteApiCall, getApiCall, postApiCall, putApiCall } from '../../utils/request';
import { returnErrors } from './errorAction';
import {
    GET_COUN_QUESTION, GET_ONE_QUESTION, SELECT_COUN_ANSWER, ASSESMENT_SUBMIT, GET_INTERESTS, SUBMIT_ANSWER,
    GET_REPORT_LIST, GET_REPORT, GET_ONE_REPORT, RESET_REPORT,
    RESET_LIST_LOADER, GET_SLOT_LIST, ADD_SLOTS, EDIT_SLOTS, CHANGE_SLOT_STATUS, DELETE_SLOT, GET_ALL_BOOKINGS, GET_BOOKINGS, GET_BOOKING_SLOTS, BOOK_SLOTS,
    RESET_ABILITY_QUESTIONS, GET_ABILITY_DETAILS, GET_ABILITY_LIST, GET_ABILITY_QUESTIONS, CHANGE_VALUE, SAVE_TEST, GET_TEST_GRAPH
} from '../Types/types';

export const getQuestions = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/counselling/questions?type_id=${id}`);
        return dispatch({ type: GET_COUN_QUESTION, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getQuestion = (ques) => async (dispatch) => {
    try {

        return dispatch({ type: GET_ONE_QUESTION, payload: ques });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const selectAnswer = (id, qid) => async (dispatch) => {
    try {

        return dispatch({ type: SELECT_COUN_ANSWER, payload: { id, qid } });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const assessmentSubmit = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall('student/counselling/submit', obj);
        return dispatch({ type: ASSESMENT_SUBMIT, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getInterests = (id, edu) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/counselling/interests?report_id=${id}&education=${edu}`);
        return dispatch({ type: GET_INTERESTS, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const submitAnswer = (id, edu, interest) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/counselling/job?report_id=${id}&education=${edu}&interest=${interest}`);
        return dispatch({ type: SUBMIT_ANSWER, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

//career assessment

export const getReportList = () => async (dispatch) => {
    try {
        const response = await getApiCall('student/counselling/report/list');
        return dispatch({ type: GET_REPORT_LIST, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getReport = () => async (dispatch) => {
    try {
        const response = await getApiCall('student/counselling/report');

        return dispatch({ type: GET_REPORT, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getOneReport = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_ONE_REPORT, payload: id });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const resetReport = () => async (dispatch) => {
    try {
        dispatch({ type: RESET_REPORT });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getAbilityDetails = (id, type) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/counselling/test/ability/details?type_id=${id}`);
        const data = response.data
        return dispatch({ type: GET_ABILITY_DETAILS, payload: data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const getTestGraph = (id, type) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/counselling/test/ability/graph?type_id=${id}`);
        const data = response.data
        return dispatch({ type: GET_TEST_GRAPH, payload: { data, type } });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

//Counselling slots
export const resetListLoading = () => async (dispatch) => {
    try {
        return dispatch({ type: RESET_LIST_LOADER });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getSlotList = () => async (dispatch) => {
    try {
        const response = await getApiCall('admin/counselling/slot');
        return dispatch({ type: GET_SLOT_LIST, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const addSlots = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall('admin/counselling/slot', obj);
        if (response.data.success) {
            returnErrors(dispatch, response.data.message, 200);
            return dispatch({ type: ADD_SLOTS, payload: response.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const editSlots = (obj) => async (dispatch) => {
    try {
        const response = await putApiCall('admin/counselling/slot/session', obj);
        if (response.data.success) {
            returnErrors(dispatch, response.data.message, 200);
            return dispatch({ type: EDIT_SLOTS, payload: response.data });
        } else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const changeSlotStatus = (data) => async (dispatch) => {
    let obj = {
        slot_id: data?._id
    }
    try {
        const response = await putApiCall('admin/counselling/slot/toggle', obj);
        if (response.data.success) {
            returnErrors(dispatch, data.active ? 'Slot has been deactivated' : 'Slot has been activated', 200);
            return dispatch({ type: CHANGE_SLOT_STATUS, payload: data });
        } else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const confirmDeleteSlot = (obj) => async (dispatch) => {

    try {
        const response = await deleteApiCall('admin/counselling/slot', obj);
        if (response.data.success) {
            returnErrors(dispatch, 'Slot has been deleted', 200);
            return dispatch({ type: DELETE_SLOT, payload: obj?.slot_id });
        } else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getAllBookings = () => async (dispatch) => {
    try {
        const response = await getApiCall('admin/counselling/slot/booked');
        return dispatch({ type: GET_ALL_BOOKINGS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

//students

export const getBookings = () => async (dispatch) => {
    try {
        const response = await getApiCall('student/counselling/slot/booked');
        return dispatch({ type: GET_BOOKINGS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getBookingSlots = (date, month, year) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/counselling/slot/availability?date=${date}&month=${month}&year=${year}`);
        return dispatch({ type: GET_BOOKING_SLOTS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const bookSlots = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall('student/counselling/slot/book', obj);
        if (response.data.success) {
            returnErrors(dispatch, response.data.message, 200);
            return dispatch({ type: BOOK_SLOTS, payload: response.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}



export const getAbilities = (type) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/counselling/test/abilities?type_id=${type}`);
        return dispatch({ type: GET_ABILITY_LIST, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getAbilityQuestions = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/counselling/test/questions?ability_id=${id}`);
        const data = response.data.data
        return dispatch({ type: GET_ABILITY_QUESTIONS, payload: { data, type: "question" } });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const changeValue = (ansId, id) => async (dispatch) => {
    try {
        dispatch({ type: CHANGE_VALUE, payload: { ansId, id } });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const submitTest = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`student/counselling/test/save`, obj);
        if (response.data.success) {
            returnErrors(dispatch, 'Ability Test submitted', 200)
            return dispatch({ type: SAVE_TEST, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getAbilityResponses = (id, testId) => async (dispatch) => {
    try {
        if (testId !== null) {
            const response = await getApiCall(`student/counselling/test/details?ability_id=${id}&test_id=${testId}`);
            const data = response.data.data
            return dispatch({ type: GET_ABILITY_QUESTIONS, payload: { data, type: "response" } });
        } else {
            return dispatch({ type: GET_ABILITY_QUESTIONS, payload: { type: "response" } });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const resetAbilityQuestions = () => async (dispatch) => {
    try {
        return dispatch({ type: RESET_ABILITY_QUESTIONS });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}