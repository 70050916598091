import { returnErrors } from './errorAction';
import { getApiCall, postApiCall, putApiCall } from '../../utils/request';
import { GET_CONTESTS, CONTEST_STATUS, GET_SCHOOLS } from '../Types/types';


export const getSchools = () => async (dispatch) => {
    try {
        const response = await getApiCall(`company/fetch/companies?type=campus`);
        dispatch({ type: GET_SCHOOLS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const getContests = () => async (dispatch) => {
    try {
        const response = await getApiCall(`master-admin/contest/list`);
        dispatch({ type: GET_CONTESTS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const addContest = (data) => async (dispatch) => {
    try {
        const response = await postApiCall(`master-admin/contest/create`, data);
        if (response.data.success) {
            returnErrors(dispatch, 'Contest added', response?.status);
            dispatch(getContests())
        }
        else {
            returnErrors(dispatch, response?.data.message, response?.status);
        }

    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status);
    }
}

export const handleStatus = (active, id) => async (dispatch) => {
    let obj = { _id: id }
    try {
        const response = await postApiCall(`master-admin/contest/toggle`, obj);
        dispatch({ type: CONTEST_STATUS, payload: { active, id } });
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status);
    }
}

export const updateContest = (data) => async (dispatch) => {
    try {
        const response = await putApiCall(`master-admin/contest/update`, data);
        if (response.data.success) {
            returnErrors(dispatch, 'Contest updated', response?.status);
            dispatch(getContests())
        }
        else {
            returnErrors(dispatch, response?.data.message, response?.status);
        }

    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status);
    }
}

