import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import './careerPath.scss';
import careersafter12th from './after12th.json';
import careersafter10th from './after10th.json';
import courseafter12th from './courseafter12th.json';
import courseafter10th from './courseafter10th.json';
import EllipsisText from 'react-ellipsis-text/lib/components/EllipsisText';
import FullScreenPath from './fullScreenPath';

export default function CareerAfter() {
    const [active, setActive] = useState('job');
    const [isPath, setIsPath] = useState(false);
    const [detail, setDetail] = useState(null);
    const [fs, setFs] = useState(false);
    const [height, setHeight] = useState('');
    const [width, setWidth] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        if (location.state.path) {
            setIsPath(true);
            setDetail(location.state.detail);
        } else {
            setIsPath(false);

        }
    }, [])

    const changeTab = (e) => {
        setActive(e)
    }




    const openPath = (detail) => {
        setIsPath(true);
        setDetail(detail);
    }

    const imgRef = React.useRef();

    const handleZoomIn = () => {
        // Fetching current height and width
        const height = imgRef.current.clientHeight;
        const width = imgRef.current.clientWidth;
        // Increase dimension(Zooming)
        setHeight(height + 50);
        setWidth(width + 50);
    }


    const handleReset = () => {
        // Assigning original height and width
        setHeight(imgRef.current.initialHeight);
        setWidth(imgRef.current.initialWidth);
    }
    const imgStyle = { height: height, width: width }
    return (
        <div className='career-path-holder'>
            {!isPath ?
                <Fragment>
                    <div className='d-flex'>
                        <img src='/images/back.svg' className='cursor-pointer' onClick={() => navigate(-1)} alt='' /> &nbsp;&nbsp;
                        <h1>{location.state.class === '10' ? 'Courses or programs after 10th.' : 'Courses or programs after 12th.'}</h1>
                    </div>
                    <div className='categories-holder mt-2'>
                        <h6>Categories</h6>
                        <div className='d-flex'>
                            <div className='category' style={{ background: active === 'job' ? '#C0BEFF' : '' }} onClick={() => changeTab('job')}>
                                <h3 style={{ color: active === 'job' ? '#413c69' : '' }}>Jobs</h3>
                                <div style={{ position: 'absolute', top: 0, right: 0 }}>
                                    <img src='/images/career-path/briefcase.svg' alt='' />
                                </div>
                            </div>
                            <div className='category' style={{ background: active === 'diploma' ? '#C0BEFF' : '' }} onClick={() => changeTab('diploma')}>
                                <h3 style={{ color: active === 'diploma' ? '#413c69' : '' }}>Courses</h3>
                                <div style={{ position: 'absolute', top: 0, right: 0 }}>
                                    <img src='/images/career-path/diploma.svg' alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        active === 'job' ?
                            <div className='career-card mt-3' style={{ paddingRight: 0 }}>
                                {
                                    window.innerWidth > 767 ?

                                        <div className={'row mt-1'} style={{ marginLeft: 10 }}>
                                            {location.state.class === '10' ?
                                                careersafter10th !== undefined && careersafter10th?.map((el) => {
                                                    return (
                                                        <div key={el.id} className="card" style={{ width: '17%', marginRight: 10, }} >
                                                            <img src={el.image} className="card-img-top" alt='' width={'100%'} height={140} />
                                                            <div className="card-body p-0">
                                                                <div style={{ padding: ' 7px 7px 0px 7px' }}>
                                                                    <div className='d-flex justify-content-center align-items-start flex-column'>
                                                                        <h5 className='elipsis-2 w-96' style={{ height: 40 }} title={el.jobtitle}>
                                                                            {el.jobtitle}
                                                                        </h5>

                                                                    </div>

                                                                    <div style={{ borderTop: '1px solid #ddd', marginTop: 8 }}></div>
                                                                    <button onClick={() => openPath(el)}>{t('View Path')}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }) :
                                                careersafter12th !== undefined && careersafter12th?.map((el) => {
                                                    return (
                                                        <div key={el.id} className="card" style={{ width: '17%', marginRight: 10, }} >
                                                            <img src={el.image} className="card-img-top" alt='' width={'100%'} height={140} />
                                                            <div className="card-body p-0">
                                                                <div style={{ padding: ' 7px 7px 0px 7px' }}>
                                                                    <div className='d-flex justify-content-center align-items-start flex-column'>
                                                                        <h5 className='elipsis-2 w-96' style={{ height: 40, width: 150 }} title={el.jobtitle}>
                                                                            {el.jobtitle}
                                                                        </h5>

                                                                    </div>

                                                                    <div style={{ borderTop: '1px solid #ddd', marginTop: 8 }}></div>
                                                                    <button onClick={() => openPath(el)}>{t('View Path')}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <div className={'row flex-nowrap overflow-auto'}>
                                            {location.state.class === '10' ?
                                                careersafter10th !== undefined && careersafter10th?.map((el) => {
                                                    return (
                                                        <div key={el.id} className="card" style={{ width: 230, marginRight: 10 }} >

                                                            <img className="card-img-top" src={el.image} style={{ height: 170 }} alt="Card" />

                                                            <div className="card-body p-0">
                                                                <div style={{ padding: 7, paddingTop: 5, paddingRight: 15, }}>
                                                                    <div className='d-flex justify-content-center align-items-start flex-column'>
                                                                        <h5 className='elipsis-2 w-96' style={{ height: 40 }} title={el.jobtitle}>
                                                                            {el.jobtitle}
                                                                        </h5>
                                                                    </div>
                                                                    <div style={{ borderTop: '1px solid #ddd', marginTop: 8 }}></div>
                                                                    <button onClick={() => openPath(el)}>{t('View Path')}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }) :
                                                careersafter12th !== undefined && careersafter12th?.map((el) => {
                                                    return (
                                                        <div key={el.id} className="card" style={{ width: 230, marginRight: 10 }} >

                                                            <img className="card-img-top" src={el.image} style={{ height: 170 }} alt="Card" />

                                                            <div className="card-body p-0">
                                                                <div style={{ padding: 7, paddingTop: 5, paddingRight: 15, }}>
                                                                    <div className='d-flex justify-content-center align-items-start flex-column'>
                                                                        <h5 className='elipsis-2 w-96' style={{ height: 40 }} title={el.jobtitle}>
                                                                            {el.jobtitle}
                                                                        </h5>
                                                                    </div>
                                                                    <div style={{ borderTop: '1px solid #ddd', marginTop: 8 }}></div>
                                                                    <button onClick={() => openPath(el)}>{t('View Path')}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                }
                            </div> :
                            <div className='career-card mt-3' style={{ paddingRight: 0 }}>
                                {
                                    window.innerWidth > 767 ?

                                        <div className={'row mt-1'} style={{ marginLeft: 10 }}>
                                            {location.state.class === '10' ?
                                                courseafter10th !== undefined && courseafter10th?.map((el) => {
                                                    return (
                                                        <div key={el.id} className="card" style={{ width: '17%', marginRight: 10, }} >
                                                            <img src={el.image} className="card-img-top" alt='' width={'100%'} height={140} />
                                                            <div className="card-body p-0">
                                                                <div style={{ padding: ' 7px 7px 0px 7px' }}>
                                                                    <div className='d-flex justify-content-center align-items-start flex-column'>
                                                                        <h5 className='w-96' style={{ height: 40 }} title={el.course}>
                                                                            <EllipsisText text={el.course} length={30} />
                                                                        </h5>

                                                                    </div>

                                                                    <div style={{ borderTop: '1px solid #ddd', marginTop: 8 }}></div>
                                                                    <button onClick={() => openPath(el)}>{t('View Path')}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }) :
                                                courseafter12th !== undefined && courseafter12th?.map((el) => {
                                                    return (
                                                        <div key={el.id} className="card" style={{ width: '17%', marginRight: 10, }} >
                                                            <img src={el.image} className="card-img-top" alt='' width={'100%'} height={140} />
                                                            <div className="card-body p-0">
                                                                <div style={{ padding: ' 7px 7px 0px 7px' }}>
                                                                    <div className='d-flex justify-content-center align-items-start flex-column'>
                                                                        <h5 className='w-96' style={{ height: 40 }} title={el.course}>
                                                                            <EllipsisText text={el.course} length={30} />
                                                                        </h5>

                                                                    </div>

                                                                    <div style={{ borderTop: '1px solid #ddd', marginTop: 8 }}></div>
                                                                    <button onClick={() => openPath(el)}>{t('View Path')}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <div className={'row flex-nowrap overflow-auto'}>
                                            {location.state.class === '10' ?
                                                courseafter10th !== undefined && courseafter10th?.map((el) => {
                                                    return (
                                                        <div key={el.id} className="card" style={{ width: 230, marginRight: 10, }} >
                                                            <img src={el.image} className="card-img-top" alt='' width={'100%'} height={140} />
                                                            <div className="card-body p-0">
                                                                <div style={{ padding: ' 7px 7px 0px 7px' }}>
                                                                    <div className='d-flex justify-content-center align-items-start flex-column'>
                                                                        <h5 className='w-96' style={{ height: 40 }} title={el.course}>
                                                                            <EllipsisText text={el.course} length={30} />
                                                                        </h5>

                                                                    </div>

                                                                    <div style={{ borderTop: '1px solid #ddd', marginTop: 8 }}></div>
                                                                    <button onClick={() => openPath(el)}>{t('View Path')}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }) :
                                                courseafter12th !== undefined && courseafter12th?.map((el) => {
                                                    return (
                                                        <div key={el.id} className="card" style={{ width: 230, marginRight: 10, }} >
                                                            <img src={el.image} className="card-img-top" alt='' width={'100%'} height={140} />
                                                            <div className="card-body p-0">
                                                                <div style={{ padding: ' 7px 7px 0px 7px' }}>
                                                                    <div className='d-flex justify-content-center align-items-start flex-column'>
                                                                        <h5 className='w-96' style={{ height: 40 }} title={el.course}>
                                                                            <EllipsisText text={el.course} length={30} />
                                                                        </h5>

                                                                    </div>

                                                                    <div style={{ borderTop: '1px solid #ddd', marginTop: 8 }}></div>
                                                                    <button onClick={() => openPath(el)}>{t('View Path')}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                }
                            </div>

                    }
                </Fragment> :
                <div className='path-description'>
                    <div className='path-desc' >
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <img src='/images/back.svg' className='cursor-pointer' onClick={() => location.state.path ? navigate(-1) : setIsPath(false)} alt='' /> &nbsp;&nbsp;
                                <h1>{active === 'diploma' ? detail?.course : detail?.jobtitle}</h1>
                            </div>
                            {/* <div>
                                <button>Download PDF</button>
                                <button>Share</button>
                            </div> */}
                        </div>
                        <hr style={{ marginTop: 10, marginRight: 0 }} />
                        <p dangerouslySetInnerHTML={{ __html: detail?.description }} />
                    </div>
                    {
                        active === 'diploma' ?
                            (location.state.class === '10' &&
                                <Fragment>
                                    <h1 className='mt-3'>Eligibility Criteria</h1>
                                    <div className='path-chart'>
                                        <ul>
                                            <li>Applicant must be appearing or passed in 10th with a minimum score of 45% with any recognized Board.</li>
                                            <li>Applicant can qualify the AME CET exam to get admission in one of the top and reputed colleges in India.</li>
                                        </ul>
                                    </div>
                                </Fragment>) :
                            <Fragment>
                                <h1 className='mt-3'>Career Path</h1>
                                <div className='path-chart'>
                                    {fs
                                        ?
                                        <div className='pdf-fullscreen'>
                                            <div onClick={() => setFs(false)} className='close-quiz'>
                                                <img src='/images/close.svg' alt='' />
                                            </div>
                                            <div className='fullscreen-container'>
                                                <div className='tools'>
                                                    <button onClick={handleZoomIn}><img src='/images/zoom-out.png' alt='' /></button>&nbsp;
                                                    <button onClick={handleReset}><img src='/images/zoom-in.png' alt='' /></button>
                                                </div>
                                                <div className='mt-2'>
                                                    <img style={imgStyle} id="fullscreenimg" ref={imgRef} src={detail?.path} onDoubleClick={handleZoomIn} alt='' width='100%' height='auto' />
                                                </div>
                                            </div>
                                        </div>
                                        : <div >
                                            <div className='tools'>
                                                <button id="fullscreenButton" className='float-right' onClick={() => setFs(true)}><img src='/images/fullscreen.png' alt='' width={20} /></button>
                                            </div>
                                            <div className='mt-2'>
                                                <img style={imgStyle} ref={imgRef} src={detail?.path} onDoubleClick={handleZoomIn} alt='' width='100%' height='auto' />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Fragment>
                    }
                </div>
            }
        </div >
    )
}
