import React, { useState } from 'react';
import { Fragment } from 'react';
import { TopicLoader } from '../Loader/loader';
import EllipsisText from 'react-ellipsis-text';
import { secondsToTime } from '../utils/miscelleneous';

export default function TopicDetails(props) {
    const [isHover, setIsHover] = useState(false);
    const [itemid, setItemId] = useState('');


    const { location, subjectChapters, chapter, topic, rCount, topicLoading, resources, resourceId } = props

    const mouseover = (e, id) => {
        setIsHover(true);
        setItemId(id)
    }

    const mouseout = (e) => {
        setIsHover(false);
    }


    return (
        <div className='d-flex w-100 mt-2'>
            <div className='chapter-topics'>
                <div className="acc-overflow">
                    <div id="accordian" >
                        <Fragment>
                            {subjectChapters !== undefined &&
                                subjectChapters?.map((el) => {
                                    return (
                                        <div key={el._id} className="card mb-0" style={{ background: chapter === (el.title || el.name) ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),linear-gradient(0deg, #E5E7FE, #E5E7FE)' : '#fff' }}
                                            onClick={() => {
                                                props.selectChapter(el);
                                            }}
                                        >
                                            <div >
                                                <div style={{ marginRight: 0, }} className="d-flex justify-content-between align-items-center cursor-pointer">
                                                    <div className="col-md-12 col-12 pr-0 recent-title1">
                                                        <div className="d-flex justify-content-between align-items-center cursor-pointer">
                                                            <h1 style={{ fontSize: 14 }}>
                                                                {(
                                                                    <EllipsisText text={el.name || el.title} length={"80"} />
                                                                )}
                                                            </h1>
                                                            <div className="d-flex float-right" style={{ marginRight: -10 }}>
                                                                <div className="d-flex align-items-center" style={{ width: 30, }}>
                                                                    <img src="/images/list-color.svg" alt="" width={16} />
                                                                    &nbsp;
                                                                    <h5 >
                                                                        {
                                                                            el.resources ? el.resources : (el.articles + el.videos + el.documents + el.quizzes || 0)
                                                                        }
                                                                    </h5>
                                                                </div>{" "}
                                                                &nbsp;&nbsp;&nbsp;
                                                                <div className="d-flex align-items-center" style={{ width: 52, }}>
                                                                    <img src="/images/pie-icon.svg" alt="" width={16} />
                                                                    &nbsp;
                                                                    <h5     >
                                                                        {Math.round(el.analytic) > 95 ? 100 : Math.round(el.analytic) || 0}%
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    );
                                })}
                        </Fragment>
                    </div>
                </div>
            </div>
            <div className="chapter-content">
                {
                    topicLoading ?
                        <TopicLoader /> :
                        <Fragment>
                            {
                                window.innerWidth > 767 ?
                                    <div className='chapter-header'>
                                        <h1> {chapter}&nbsp; <aside>({rCount} Items)</aside></h1>
                                    </div> :
                                    <div className='chapter-header-mobile'>
                                        <h1> {chapter}</h1>
                                        <p className='ms-4'>{location.state.subject}</p>
                                        <div className='d-flex align-items-center mt-2 ms-4'>
                                            <div>
                                                <img src='/images/list-color.svg' alt='' />&nbsp; {rCount}
                                            </div>&nbsp;&nbsp;
                                            <div>
                                                <img src='/images/pie-icon.svg' alt='' />&nbsp; {location.state?.detail?.analytic}%
                                            </div>
                                        </div>
                                    </div>
                            }

                            <div id="accordion" className="accordian">
                                {
                                    resources !== undefined && resources?.length > 0 ?
                                        resources.map((item, index) => {
                                            return (
                                                <div key={item?._id} className="card mb-0" onClick={() => item[0]?.topic_name ? props.selectSubtopic(item[0]?.topic_name, item[0].topic_id) : props.selectSubtopic((item[0]?.subtopic))}>
                                                    <div className={(topic) === (item[0]?.topic_name || item[0]?.subtopic) ? "card-header" : "card-header collapsed"} data-toggle="collapse" aria-expanded="true" href={`#${index}`} >
                                                        <div className="card-title" >
                                                            <div style={{ marginLeft: -10 }} className=''>
                                                                <p style={{ marginBottom: 3 }}> {item[0]?.topic_name || item[0]?.subtopic} </p>
                                                                <span >{item?.length} Items</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id={index} className={(topic) === (item[0]?.topic_name || item[0]?.subtopic) ? 'card-body collapse show' : 'card-body collapse'} data-parent="#accordion" style={{ padding: 10 }} >
                                                        <div className="skill-pod-topics">
                                                            <div id="accordion" className="accordion">
                                                                {(

                                                                    <div id='resource-list' className="row" style={{ background: '#fff' }}>
                                                                        {
                                                                            item.map((items, index) => {
                                                                                if (items.type === 'Video') {
                                                                                    let startTime = items.time && items.time !== undefined && items.time.split("-")[0].split(":").reverse().reduce((prev, curr, i) =>
                                                                                        prev + curr * Math.pow(60, i),
                                                                                        0
                                                                                    );
                                                                                    let endTime = items.time && items.time !== undefined && items.time.split("-")[1].split(":").reverse().reduce((prev, curr, i) =>
                                                                                        prev + curr * Math.pow(60, i),
                                                                                        0
                                                                                    );
                                                                                    var time1 = secondsToTime(endTime - startTime);
                                                                                }
                                                                                return (
                                                                                    <div key={index} className="col-md-3 col-12 position-relative cursor-pointer" >
                                                                                        {
                                                                                            window.innerWidth > 767 ?
                                                                                                <div className="row mt-2">
                                                                                                    <div className="col-md-12 col-6" onMouseOver={(e) => { mouseover(e, items?._id) }} onMouseOut={(e) => { mouseout(e) }} onClick={() => items.type === 'Quiz' ? props.showQuiz(items) : props.showfeeds(items)} >

                                                                                                        <img src={items.type === 'Quiz' ? '/images/quiz-image.png' : items.image} width='100%' height={120} alt="" loading="lazy" style={{ borderRadius: 8, border: '1px solid rgb(233, 232, 242)' }} onError={(e) => {
                                                                                                            if (e.target.src !== "/images/empty-image.png") {
                                                                                                                e.target.onerror = null;
                                                                                                                e.target.src = "/images/empty-image.png";
                                                                                                            }
                                                                                                        }
                                                                                                        } />
                                                                                                        <div className="content-tags"
                                                                                                            style={{ background: isHover && (itemid === items?._id) ? '#5855d6' : '#E7C15F' }}
                                                                                                        >
                                                                                                            {
                                                                                                                isHover && (itemid === items?._id) ?
                                                                                                                    <p className="d-flex align-items-center" style={{ color: '#fff' }}>
                                                                                                                        {items.type === "Podcast" ? (
                                                                                                                            <img src="/images/podcast-icon-white.svg" width="15px" alt="" />
                                                                                                                        ) : items.type === "Video" ? (
                                                                                                                            <img src="/images/video-icon-white.svg" width="15px" alt="" />
                                                                                                                        ) : items.type === "Document" ? (
                                                                                                                            <img src="/images/document-icon-white.svg" width="14px" alt="" />
                                                                                                                        ) : items.type === "Course" ? (
                                                                                                                            <img src="/images/course-icon-white.svg" width="15px" alt="" />
                                                                                                                        ) : items.type === "Article" ? (
                                                                                                                            <img src="/images/article-icon-white.svg" width="15px" alt="" />
                                                                                                                        ) : items.type === "Quiz" ? (
                                                                                                                            <img src="/images/quiz-icon-white.svg" width="15px" height={13} alt="" />
                                                                                                                        ) : null}{" "}
                                                                                                                        &nbsp;
                                                                                                                        {items.type === "Video" ? 'Watch Video' : items.type === "Document" ? 'Read Document' : items.type === "Article" ? (items?.source === 'Baamboozle' ? 'Play Game' : 'Read Article') : items.type === "Podcast" ? 'Listen Podcast' : items.type === "Course" ? 'Watch Course' : items.type === "Quiz" ? 'Take Quiz' : null}
                                                                                                                    </p> :
                                                                                                                    <p className="d-flex align-items-center">
                                                                                                                        {items.type === "Podcast" ? (
                                                                                                                            <img src="/images/podcast-icon.svg" width="15px" alt="" />
                                                                                                                        ) : items.type === "Video" ? (
                                                                                                                            <img src="/images/video-icon.svg" width="15px" alt="" />
                                                                                                                        ) : items.type === "Document" ? (
                                                                                                                            <img src="/images/document-icon.svg" width="12px" alt="" />
                                                                                                                        ) : items.type === "Course" ? (
                                                                                                                            <img src="/images/course-icon.svg" width="15px" alt="" />
                                                                                                                        ) : items.type === "Article" ? (
                                                                                                                            <img src="/images/article-icon.svg" width="15px" alt="" />
                                                                                                                        ) : items.type === "Quiz" ? (
                                                                                                                            <img src="/images/quiz-icon.svg" width="15px" height='13px' alt="" />
                                                                                                                        ) : null}{" "}
                                                                                                                        &nbsp; {items?.source === 'Baamboozle' ? 'Game' : items.type}
                                                                                                                    </p>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {
                                                                                                        <div onClick={() => items.type === 'Quiz' ? props.showQuiz(items) : props.showfeeds(items)} className=" col-md-12 col-4 mt-1 d-flex justify-content-between align-items-start text-capitalize">
                                                                                                            <p className="content-title">{items.title}</p>
                                                                                                            {/* {((window.location.pathname === '/my-learning/topics') || (window.location.pathname === '/my-subjects/topics')) && */}
                                                                                                            {(items.viewed ? (
                                                                                                                <img src="/images/green-check-fill.svg" alt="" width="22px" />
                                                                                                            ) : (
                                                                                                                <img src="/images/grey-check-fill.svg" alt="" width="22px" />
                                                                                                            ))}
                                                                                                        </div>
                                                                                                    }
                                                                                                    <p className="caption-text col-md-12 pr-2" style={{ fontSize: 12, marginTop: 3, display: 'flex', justifyContent: 'space-between' }}>
                                                                                                        {items.type === 'Quiz' ? <p style={{ color: '#8b7df2', fontWeight: 600 }} onClick={() => props.showQuiz(items)}>Start Quiz</p> : items.type === "Video" ? time1 !== "00:00" ? `${time1}` : null : items?.source !== undefined && `${items.source}`}
                                                                                                        <span>{items.type === 'Quiz' ?
                                                                                                            null
                                                                                                            //  <img src='/images/info-icon.svg' alt='' style={{ marginTop: 3, cursor: 'pointer', marginRight: 9 }} width={18}
                                                                                                            // // onClick={() => this.openTestDetail(items._id)} 
                                                                                                            // /> 
                                                                                                            : (items.type === "Article" && '02:00')}</span>
                                                                                                    </p>
                                                                                                </div> :
                                                                                                <div className="row mt-2">
                                                                                                    <h1 className='d-flex align-items-center' onClick={() => items.type === 'Quiz' ? props.showQuiz(items) : props.showfeeds(items)} style={{ fontSize: 14, lineHeight: 1.5, marginLeft: 10, height: 45, color: items._id === resourceId ? '#413c69' : '#272727', fontWeight: items._id === resourceId ? '700' : '500' }}>
                                                                                                        {
                                                                                                            items.type === 'Video' ?
                                                                                                                <img src='/images/video-box-icon.svg' alt='' width={20} /> :
                                                                                                                items.type === 'Article' ?
                                                                                                                    <img src='/images/article-box-icon.svg' alt='' width={20} /> :
                                                                                                                    items.type === 'Quiz' ?
                                                                                                                        <img src='/images/quiz.svg' alt='' width={20} /> :
                                                                                                                        null
                                                                                                        }&nbsp;&nbsp;
                                                                                                        {items.title !== undefined && (
                                                                                                            <EllipsisText text={items.title} length={"60"} />
                                                                                                        )}
                                                                                                    </h1>
                                                                                                </div>

                                                                                        }

                                                                                    </div>
                                                                                );
                                                                            })}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>


                                            )
                                        }) :
                                        <div className='empty-list'>
                                            <img src='/images/empty.png' alt='' />
                                        </div>
                                }
                            </div>
                        </Fragment>
                }

            </div>

        </div>
    )
}
